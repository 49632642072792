import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const info = new BaseStoreModule({ component: null, sort: null });

const myState = {
  version: '',
};

const myGetters = {
  getVersion: state => state.version,
};

const myActions = {
  setVersion({ commit }, version) {
    commit(types.SET_VERSION, 'v'.concat(version));
  },
};

const myMutations = {
  [types.SET_VERSION](state, version) {
    state.version = version;
  },
};

info.state = Object.assign(info.state, myState);
info.getters = Object.assign(info.getters, myGetters);
info.actions = Object.assign(info.actions, myActions);
info.mutations = Object.assign(info.mutations, myMutations);

export default info;
