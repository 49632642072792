import BaseStoreModule from '@/store/BaseStoreModule';

const tenants = new BaseStoreModule({ component: 'Tenants', sort: { key: 'name', descending: false } });

const myactions = {
  async fetchRoleTenants({ dispatch, rootGetters }, { service, key }) {
    dispatch('setStoreKey', key);

    const topRole = rootGetters['app/getTopRole'];
    const gmiRoles = rootGetters['app/getGmiRoles'];

    if (topRole.roleCode === 'ROLE_TENANT_ADMIN') {
      const fewTenants = await service.tenantsByRoles(gmiRoles);
      dispatch('tenants/setAll', { results: fewTenants }, { root: true });
    } else {
      const allTenants = await service.fetchAll({ service, key: 'code' });
      dispatch('tenants/setAll', { results: allTenants.data }, { root: true });
    }
  },
};

tenants.actions = Object.assign(tenants.actions, myactions);

export default tenants;
