import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const tabs = new BaseStoreModule({ component: null, sort: null });

const myState = {
  selected: '',
  workspace: '',
};

const myGetters = {
  getSelected: state => state.selected,
  getWorkspace: state => state.workspace,
};

const myActions = {
  setSelected({ commit }, selected) {
    commit(types.SET_SELECTED_TAB, selected);
  },
  setWorkspace({ commit }, workspace) {
    commit(types.SET_SELECTED_TAB_WORKSPACE, workspace);
  },
};

const myMutations = {
  [types.SET_SELECTED_TAB](state, selected) {
    state.selected = selected;
  },
  [types.SET_SELECTED_TAB_WORKSPACE](state, workspace) {
    state.workspace = workspace;
  },
};

tabs.state = Object.assign(tabs.state, myState);
tabs.getters = Object.assign(tabs.getters, myGetters);
tabs.actions = Object.assign(tabs.actions, myActions);
tabs.mutations = Object.assign(tabs.mutations, myMutations);

export default tabs;
