class TemplateBiometricIncluded {
  constructor(biometric) {
    const transformed = {};
    transformed.algCode = biometric.code;
    transformed.code = biometric.code;
    transformed.captureType = biometric.captureType;
    return transformed;
  }
}

export default TemplateBiometricIncluded;
