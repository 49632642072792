import Vue from 'vue';
import Vuex from 'vuex';

/* Global state store authentication and things that are global to the app */
import AppStoreModule from '@/store/AppStoreModule';

/* Base module for non customized modules */
import BaseStoreModule from '@/store/BaseStoreModule';

/* Storing Message Alerts (Browser noticitaions) */
import AlertMessageStore from '@/store/AlertMessageStore';

/* Storing Authentication */
import AuthenticationStore from '@/store/AuthenticationStore';

/* Storing Login State */
import LoginStore from '@/store/LoginStore';

/* Storing Timeouts */
import TimeoutStore from '@/store/TimeoutStore';

/* Performance Data */
import PerformanceStore from '@/store/PerformanceStore';


/* Custom Fetch/Save/Delete Vuex */
import serverGroupAlgorithms from '@/store/modules/serverGroupAlgorithms';
import tenantApplications from '@/store/modules/tenantApplications';
import tenantBiometrics from '@/store/modules/tenantBiometrics';
import userCredentials from '@/store/modules/userCredentials';
import clientCredentials from '@/store/modules/clientCredentials';
import info from '@/store/modules/info';
import userAlerts from '@/store/modules/userAlerts';
import alertMessageDialog from '@/store/modules/alertMessageDialog';
import userEnrollments from '@/store/modules/userEnrollments';
import tenants from '@/store/modules/tenants';
import tabs from '@/store/modules/tabs';
import tenantBiometricLiveness from '@/store/modules/tenantBiometricLiveness';
import biometricLiveness from '@/store/modules/biometricLiveness';
import systemInfo from '@/store/modules/systemInfo';
import userDevices from '@/store/modules/userDevices';
import users from '@/store/modules/users';
import tenantTemplateBiometrics from '@/store/modules/tenantTemplateBiometrics';
import applicationTemplates from '@/store/modules/applicationTemplates';

const alertMessage = new AlertMessageStore();
const authentication = new AuthenticationStore();
const login = new LoginStore();
const timeout = new TimeoutStore();
const app = new AppStoreModule();
const perf = new PerformanceStore();

/* Generic Fetch/Save/Delete Vuex */
const applications = new BaseStoreModule({ component: 'Application', sort: { key: 'code', descending: false } });
const biometrics = new BaseStoreModule({ component: 'Biometric', sort: { key: 'code', descending: false } });
const serverGroups = new BaseStoreModule({ component: 'Biometric Group', sort: { key: 'code', descending: false } });
const liveness = new BaseStoreModule({ component: 'Liveness', sort: { key: 'code', descending: false } });

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    perf,
    tenants,
    applications,
    biometrics,
    serverGroups,
    serverGroupAlgorithms,
    tenantApplications,
    applicationTemplates,
    systemInfo,
    tenantBiometrics,
    users,
    userEnrollments,
    userAlerts,
    userDevices,
    alertMessage,
    userCredentials,
    clientCredentials,
    info,
    alertMessageDialog,
    authentication,
    login,
    timeout,
    tabs,
    liveness,
    tenantBiometricLiveness,
    biometricLiveness,
    tenantTemplateBiometrics,
  },
  strict: debug,
});
