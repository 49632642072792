import moment from 'moment';
import Alert from '@/models/Alert';

class AlertToPlain {
  constructor(a) {
    const alert = new Alert(a);

    if (alert.createdDate) {
      alert.createdDate = moment.utc(alert.createdDate).format('MM/DD/YYYY HH:mm:ss');
    }

    if (alert.template) {
      const { template } = alert;
      alert.template = alert.template.substring(alert.template.lastIndexOf('/') + 1);
      const appSlice = template.slice(template.indexOf('/app/') + 5);
      alert.application = appSlice.substring(0, appSlice.indexOf('/'));
    }

    /* Cast succeeded to 3 different types */
    const status = (typeof a.succeeded === 'undefined') ? 2 : (+a.succeeded);
    alert.icon = AlertToPlain.getIcon(status);
    alert.iconColor = AlertToPlain.getIconColor(status);
    alert.statusFriendly = AlertToPlain.getFriendlyStatus(status);

    return alert;
  }

  static getIcon(status) {
    const icons = {
      0: () => 'clear',
      1: () => 'beenhere',
      2: () => 'email',
    };
    return icons[status] ? (icons[status])() : '';
  }

  static getIconColor(status) {
    const icons = {
      0: () => 'error',
      1: () => 'accent',
    };
    return icons[status] ? (icons[status])() : '';
  }

  static getFriendlyStatus(status) {
    const icons = {
      0: () => 'Failed',
      1: () => 'Success',
      2: () => 'Pending',
    };
    return icons[status] ? (icons[status])() : '';
  }
}

export default AlertToPlain;
