const buttons = {
  uploadUsersButton: {
    text: 'Upload Users',
  },
  downloadUsersButton: {
    text: 'Download Users',
  },
  alertButton: {
    text: 'View Alerts',
  },
  deviceButton: {
    text: 'Devices',
  },
  enrollmentButton: {
    text: 'Enrollment Files',
  },
  alertAppButton: {
    text: 'View Alerts',
  },
  saveButton: {
    text: 'Save',
  },
  editButton: {
    text: 'Edit',
  },
  cancelButton: {
    text: 'Cancel',
  },
  deleteButton: {
    text: 'Delete',
  },
};

export default buttons;
