class Validation {
  static isUrl(url) {
    if (!url) {
      return true;
    }
    return /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/.test(url);
  }

  static isNumber(x) {
    // Returning true here to handle the case where no value is valid, where we
    // the field is not required, but we do want to check any entered value.
    if (!x) {
      return true;
    }
    return /^\d+\.?\d*$/.test(x);
  }

  static minLength(val, len) {
    // We only want to check a value if one is entered. There is a separate
    // check for a field to be required, so in this check, we don't care if
    // there isn't a value.
    if (!val) {
      return true;
    }
    return val.toString().length >= len;
  }

  static maxLength(val, len) {
    if (!val) {
      return true;
    }
    return val.toString().length <= len;
  }

  static isHex(val) {
    if (!val || val === 'NULL') {
      return false;
    }
    return /^#[0-9A-Fa-f]{6}$/.test(val);
  }

  static isPng(val) {
    if (!val) {
      return false;
    }
    return /(.*).png$/.test(val);
  }
}

export default Validation;
