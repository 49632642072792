<template>
  <div>
    <div>
      <WorkspaceActionDial
        v-if="roleAtLeastTm"
        showCancel
        showSave
        @dialCancel="cancelClick"
        @dialSave="saveClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.code" v-model="tenantBiometricModel.code" disabled />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.threshold"
                  v-model="tenantBiometricModel.threshold"
                  :disabled="!hasBiometric"
                  clearable
                  :placeholder="thresholdPlaceholder"
                  hint="Clear custom threshold value to return to server default"
                  clear-icon="history"
              />
              </v-flex>
            </v-layout>
            <v-layout row v-if="roleAtLeastTa">
              <v-flex xs6>
                <BaseSelectChip
                  label="Liveness Options"
                  :value="tenantBiometricLiveness"
                  :available="getAvailableLiveness"
                  :disabled="!hasBiometric"
                  itemValue="code"
                  itemText="name"
                  @input="livenessUpdated"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.biometricGroup"
                  v-model="tenantBiometricModel.serverGroup" disabled />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <TenantBiometricTable
          :tenantBiometrics="tenantBiometrics"
          @rowClick="setBiometric"
          @refresh="refresh"
        />
      </div>
    </Section>

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/tenantBiometrics';
import serverGroupAlgorithmService from '@/services/ServerGroupAlgorithmService';
import serverGroupService from '@/services/ServerGroupService';
import tenantService from '@/services/TenantService';
import State from '@/models/State';
import TenantBiometric from '@/models/TenantBiometric';
import TenantBiometricLiveness from '@/models/TenantBiometricLiveness';
import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';
import _ from 'lodash';

export default {
  name: 'TenantBiometricEdit',
  data: () => ({
    tenantBiometricModel: new TenantBiometric({}),
    tenantBiometricModelBase: new TenantBiometric({}),
    tenantBiometricState: new State({}),
    selectedServerGroupCode: null,
    fields,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'roleAtLeastTm', 'roleAtLeastTa']),
    ...mapGetters('serverGroups', {
      serverGroups: 'getAll',
    }),
    ...mapGetters('serverGroupAlgorithms', {
      serverGroupAlgorithms: 'getAlgorithms',
    }),
    ...mapGetters('tenantBiometrics', {
      tenantBiometrics: 'getAll',
    }),
    ...mapGetters('tenantBiometricLiveness', {
      tenantBiometricLiveness: 'getAll',
      getAvailableLiveness: 'getAvailableLiveness',
      dbTenantBiometricLiveness: 'getDbItems',
    }),
    ...mapGetters('liveness', {
      allLiveness: 'getAll',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
      firstBiometric: 'getFirst',
    }),
    hasBiometric() {
      return !!this.tenantBiometricModel.code && this.roleAtLeastTm;
    },
    canDelete() {
      return !!(this.tenantBiometricModel.code);
    },
    thresholdPlaceholder() {
      let placeholderString = '';
      if (typeof this.tenantBiometricModel.threshold !== 'number') {
        placeholderString = `Using server default of ${this.tenantBiometricModel.scoreThreshold}`;
      } else {
        placeholderString = '';
      }
      return placeholderString;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    TenantBiometricEdit: () => import('@/components/workspace/tenants/TenantBiometricEdit'),
    TenantBiometricTable: () => import('@/components/workspace/tenants/TenantBiometricTable'),
  },
  methods: {
    ...mapActions('serverGroupAlgorithms', {
      fetchServerGroupsAlgorithms: 'fetchAllBy',
    }),
    ...mapActions('tenantBiometrics', {
      fetchBiometrics: 'fetchBiometrics',
      associateTenantBiometric: 'associateTenantBiometric',
      disAssociateTenantBiometric: 'disAssociateTenantBiometric',
    }),
    ...mapActions('serverGroups', {
      fetchServerGroups: 'fetchAll',
    }),
    ...mapActions('tenantBiometricLiveness', {
      fetchAllTenantBiometricLiveness: 'fetchAllTenantBiometricLiveness',
      fetchAvailableLiveness: 'fetchAvailableLiveness',
      associateBiometricLiveness: 'associateBiometricLiveness',
      disAssociateBiometricLiveness: 'disAssociateBiometricLiveness',
      clearLiveness: 'clearAll',
      addTenantBiometricLiveness: 'addOneSorted',
      deleteTenantBiometricLiveness: 'deleteFromState',
    }),
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
    }),
    cancelClick() {
      this.setBiometric(this.tenantBiometricState.original);
    },
    async setBiometric(biometric) {
      this.tenantBiometricModel = new TenantBiometric(biometric);
      this.tenantBiometricModelBase = new TenantBiometric(biometric);
      this.tenantBiometricState = new State({
        original: biometric,
      });
      this.setUnsaved(false);
      if (biometric.code && this.roleAtLeastTa) {
        /* get available livenesses for this biometric */
        await this.fetchAvailableLiveness({
          algorithmCode: biometric.code,
        });
        /* get all livenesses for this biometric */
        await this.fetchAllTenantBiometricLiveness({
          tenantCode: this.getStickyTenant,
          svrgrpCode: this.tenantBiometricModel.serverGroup,
          algorithmCode: biometric.code,
        });
      }
    },
    async livenessUpdated(livenesses) {
      if (livenesses.length > this.tenantBiometricLiveness.length) {
        const liveness = this.findMissing(livenesses, this.tenantBiometricLiveness, 'code');
        this.addTenantBiometricLiveness(liveness);
      } else if (livenesses.length < this.tenantBiometricLiveness.length) {
        const liveness = this.findMissing(this.tenantBiometricLiveness, livenesses, 'code');
        this.deleteTenantBiometricLiveness(liveness);
      }
      this.setUnsaved(true);
    },
    async saveLiveness() {
      // We have to separately save the liveness from the
      // Liveness Options field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;

      // We compare what's in tenantBiometricLiveness against what is
      // in the db, and then add or delete as necessary.
      // First, we get anything in state that isn't in the db so we
      // can add it.
      const addDiff = this.tenantBiometricLiveness
        .filter(x => !this.dbTenantBiometricLiveness.includes(x));
      // Then, we find anything that is in the db that isn't in state
      // so we can delete it.
      const delDiff = this.dbTenantBiometricLiveness
        .filter(x => !this.tenantBiometricLiveness.includes(x));
      // Define the main object that is saved.
      const tbl = new TenantBiometricLiveness(
        this.getStickyTenant,
        this.tenantBiometricModel.serverGroup,
        this.tenantBiometricModel.code,
        null,
      );
      // Delete the liveness that need to be deleted.
      delDiff.forEach((liveness, index) => {
        tbl.livenessCode = liveness.code;
        vm.disAssociateBiometricLiveness(tbl);
      });
      // Add the currently selected list of liveness.
      addDiff.forEach((liveness, index) => {
        tbl.livenessCode = liveness.code;
        vm.associateBiometricLiveness(tbl);
      });
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.associateTenantBiometric({
          service: tenantService,
          tenantCode: this.getStickyTenant,
          serverGroupCode: this.tenantBiometricModel.serverGroup,
          biometricCode: this.tenantBiometricModel.code,
          threshold: this.tenantBiometricModel.threshold,
        });
        this.saveLiveness();
        // The threshold value comes from the form field as a string, so we need
        // to convert it to a float.
        this.tenantBiometricModel.threshold = this.tenantBiometricModel.threshold !== ''
          ? parseFloat(this.tenantBiometricModel.threshold) : '';
        await this.setComponent(this.tenantBiometricModel);
        this.setUnsaved(false);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async setComponent(biometric = false) {
      this.setTabs('Tenants', 'tab-biometrics');

      await this.clearLiveness();

      if (this.getStickyTenant && this.roleAtLeastTa) {
        await this.fetchServerGroups({ service: serverGroupService, key: 'code' });
        await this.fetchBiometrics({
          service: tenantService,
          key: 'code',
          tenantCode: this.getStickyTenant,
          Transform: TenantBiometricToPlain,
        });
        // This method can be called either with or w/o passing
        // an existing biometric model, so we use it if is, and
        // just display the first one in state if not.
        if (biometric) {
          this.setBiometric(biometric);
        } else if (this.tenantBiometrics.length > 0) {
          this.setBiometric(this.tenantBiometrics[0]);
          await this.fetchServerGroupsAlgorithms({
            service: serverGroupAlgorithmService,
            key: 'code',
            fetchBy: this.tenantBiometricModel.serverGroup,
          });
        }
      }
    },
  },
  watch: {
    tenantBiometricModel: {
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.tenantBiometricModelBase)) {
          this.setUnsaved(true);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
