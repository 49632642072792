const fields = {
  username: {
    label: 'Username',
  },
  messageUuid: {
    label: 'Message Id',
  },
  transactionUuid: {
    label: 'Transaction Id',
  },
  transactionType: {
    label: 'Transaction Type',
  },
  tenant: {
    label: 'Tenant',
  },
  application: {
    label: 'Application',
  },
  template: {
    label: 'Template',
  },
  createdDate: {
    label: 'Created',
  },
  beResponse: {
    label: 'Biometric response',
  },
  status: {
    label: 'Status',
  },
  postBackUrl: {
    label: 'Postback Server URL',
  },
  score: {
    label: 'Score',
  },
  threshold: {
    label: 'Threshold',
  },
  rejectionInfo: {
    label: 'Rejection Reason',
  },
};

export default fields;
