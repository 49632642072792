class UserDocument {
  constructor(ue) {
    this.bioType = ue.bioType || '';
    this.captureType = ue.captureType || '';
    this.type = ue.type || '';
    this.friendly = ue.friendly || '';
    this.icon = ue.icon || '';
    this.status = ue.status || '';
    this.created = ue.created || '';
    this.transactionUuid = ue.transactionUuid || '';
    this.guid = ue.guid || [];
    this.files = ue.files || [];
    this.hasFiles = ue.hasFiles || false;
  }
}

export default UserDocument;
