import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  uuid: {
    label: 'UUID',
  },
  userId: {
    label: 'Name',
    rules: new RuleGenerator('Email')
      .setRequired()
      .setMin(1)
      .setMax(255)
      // eslint-disable-next-line no-useless-escape
      .setRegEx(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .getRules(),
  },
  password: {
    label: 'Password',
    rules: new RuleGenerator('Password')
      .setRequired()
      .setMin(8)
      .setMax(20)
      .setRegEx(/^[a-zA-Z0-9'{}|!#$%&^*/=?`~@._+-]{1,255}$/)
      .getRules(),
    counter: '20',
  },
  passwordConfirm: {
    label: 'Confirm Password',
    rules: new RuleGenerator('Password confirmation')
      .setRequired()
      .setMin(8)
      .setMax(20)
      .setRegEx(/^[a-zA-Z0-9'{}|!#$%&^*/=?`~@._+-]{1,255}$/)
      .getRules(),
    counter: '20',
  },
  apRole: {
    label: 'Admin Portal Role',
  },
  umRole: {
    label: 'User Mananger Role',
  },
  loginSecurity: {
    label: 'AP Sign-in Security',
  },
  tenant: {
    label: 'Tenant',
  },
  app: {
    label: 'Application',
  },
  template: {
    label: 'Template',
  },
  customMessage: {
    label: 'Custom Message',
    rules: new RuleGenerator('Custom Message')
      .setMin(0)
      .setMax(1024)
      .getRules(),
    counter: '1024',
  },
  responseAttempts: {
    label: 'Response Attempts',
    hint: 'Enter a numeric value, or leave blank for unlimited',
    rules: new RuleGenerator('Response Attempts')
      .setNumber()
      .getRules(),
  },
  timeout: {
    label: 'Set Timeout (in seconds)',
    rules: new RuleGenerator('Timeout')
      .setNumber()
      .getRules(),
  },
};

export default fields;
