import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  captureType: {
    label: 'Capture Type',
    rules: new RuleGenerator('Capture Type')
      .setRequired()
      .getRules(),
  },
  enrollType: {
    label: 'Enroll Type',
  },
  enrollAction: {
    label: 'Existing Enrollments',
  },
  code: {
    label: 'Biometric Name',
    rules: new RuleGenerator('Biometric Name')
      .setRequired()
      .setMin(1)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
  bioType: {
    label: 'BioType ID',
    rules: new RuleGenerator('BioType ID')
      .setRequired()
      .setMin(1)
      .setMax(45)
      .setRegEx(/^[\d]+$/)
      .getRules(),
    hint: 'Must be a number',
    counter: '45',
  },
  templateType: {
    label: 'Template Type',
    rules: new RuleGenerator('Template Type')
      .setRequired()
      .setMin(1)
      .setMax(45)
      .setRegEx(/^[\d]+$/)
      .getRules(),
    hint: 'Must be a number',
    counter: '45',
  },
  scoreThreshold: {
    label: 'Threshold',
    rules: new RuleGenerator('Threshold')
      .setRequired()
      .setMin(1)
      .setMax(45)
      .getRules(),
  },
  zscoreThreshold: {
    label: 'ZScore Threshold',
    rules: new RuleGenerator('ZScore Threshold')
      .setMin(1)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
  fusionWeight: {
    label: 'Fusion weight',
    rules: new RuleGenerator('Fusion weight')
      .setMin(0)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
  enrollSamples: {
    label: 'Enroll Count',
    rules: new RuleGenerator('Enroll Count')
      .setNumber()
      .setRequired()
      .getRules(),
  },
  verifySamples: {
    label: 'Verify Count',
    rules: new RuleGenerator('Verify Count')
      .setNumber()
      .setRequired()
      .getRules(),
  },
  vendor: {
    label: 'Vendor',
    rules: new RuleGenerator('Vendor')
      .setMin(1)
      .setMax(100)
      .getRules(),
    counter: '100',
  },
  version: {
    label: 'Version',
    rules: new RuleGenerator('Version')
      .setMin(1)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
};

export default fields;
