import { mapGetters, mapActions } from 'vuex';
import TenantUser from '@/models/TenantUser';

const baseEditMixin = {
  data: () => ({
    showValidateFailModal: false,
    validateFailTitle: 'Errors Detected',
    validateFailText: 'One or more required fields have missing or invalid characters. You must fix the errors before you can save your changes.',
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getUnsaved']),
  },
  methods: {
    ...mapActions('tabs', {
      setCurrentWorkspace: 'setWorkspace',
      setSelectedTab: 'setSelected',
    }),
    ...mapActions('users', {
      setCurrentUser: 'setCurrent',
    }),
    ...mapActions('app', {
      setStickyTenantDisabled: 'setStickyTenantDisabled',
      setUnsaved: 'setUnsaved',
    }),
    setTabs(workspace, tab) {
      this.setCurrentWorkspace(workspace);
      this.setSelectedTab(tab);
    },
    findMissing(target, search, index) {
      let ret = null;
      target.forEach((item) => {
        const missing = search.find(t => item[index] === t[index]);
        if (typeof missing === 'undefined') {
          ret = item;
        }
      });
      return ret;
    },
    refresh() {
      this.setComponent();
    },
    saveHotKey(event) {
      if (event.ctrlKey && event.altKey) {
        this.executeHotKey(event.code);
      }
    },
    executeHotKey(code) {
      // These methods should exist in each editMixin file
      const availableCodes = {
        KeyS: () => this.saveClick(),
        KeyN: () => this.newClick(),
        KeyC: () => this.cancelClick(),
        KeyD: () => this.deleteClick(),
        KeyP: () => this.chevy(),
      };
      if (availableCodes[code]) {
        return (availableCodes[code])();
      }
      return false;
    },
    chevy() {
      if (this.ee) {
        this.ee();
      }
    },
    validateFailModalConfirm(confirm) {
      // Just need to remove the modal.
      this.showValidateFailModal = false;
    },
  },
  watch: {
    async getStickyTenant() {
      this.setCurrentUser(new TenantUser({}));
      if (this.setComponent) {
        await this.setComponent();
      }
    },
  },
  mounted() {
    this.setStickyTenantDisabled(false);
  },
  created() {
    window.addEventListener('keydown', this.saveHotKey);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.saveHotKey);
  },
};

export default baseEditMixin;
