import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';
import Tenant from '@/models/Tenant';

/* eslint-disable no-return-await */

class TenantService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant')));
  }

  static async fetch(tenantCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode)));
  }

  static async save(tenant) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenant.code), tenant));
  }

  static async delete(tenant) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenant.code)));
  }

  static async getAlgorithms(tenantCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode).concat('/alg')));
  }

  static async getApplications(tenantCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode).concat('/app')));
  }

  static async associateTenantBiometricAlgorithm(
    tenantCode,
    serverGroupCode,
    algorithmCode,
    threshold,
  ) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/svrgrp/')
      .concat(serverGroupCode)
      .concat('/alg/')
      .concat(algorithmCode), { threshold }));
  }

  static async disAssociateTenantBiometricAlgorithm(tenantCode, serverGroupCode, algorithmCode) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/svrgrp/')
      .concat(serverGroupCode)
      .concat('/alg/')
      .concat(algorithmCode)));
  }

  static async associateApplication(tenantCode, applicationCode) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(applicationCode)));
  }

  static async disAssociateApplication(tenantCode, applicationCode) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(applicationCode)));
  }

  static async getConfig(tenantCode) {
    return await axios({
      url: ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/config'),
      method: 'GET',
      responseType: 'blob',
    });
  }

  static async saveConfig(tenantCode, tenantConfig) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/config'),
      tenantConfig,
    ));
  }

  static async tenantsByRoles(roles) {
    const promises = [];
    roles.forEach((role) => {
      promises.push(this.fetch(role.tenantCode));
    });
    const tenants = await Promise.all(promises);
    // Filter out any that returned an error.
    const filteredTenants = tenants.filter(tenant => !tenant.data.errors);
    return filteredTenants.map(tenant => new Tenant(tenant.data));
  }
}

export default TenantService;
