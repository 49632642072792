<template>
  <div>
    <div>
      <WorkspaceActionDial
        :showSendEnrollment="userDocument.status === 'Enrolled'"
        @dialSendEnrollment="openSendEnrollment"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">

        <v-container grid-list-lg>
          <v-layout row>
              <v-flex xs6>
                <v-text-field
                  label="Username"
                  v-model="getCurrent.userId"
                  disabled
                  />
              </v-flex>
          </v-layout>
        </v-container>

        <BaseImageCapture
          v-if="userDocument.type === 'face'"
          :document="userDocument"
          :accessToken="getAuthToken"
        />

        <BaseVoiceCapture
          v-if="userDocument.type === 'voice'"
          :document="userDocument"
          :accessToken="getAuthToken"
        />

        <BasePinCapture
          v-if="userDocument.type === 'palm'"
          :document="userDocument"
          :enrollments="enrollments"
          :accessToken="getAuthToken"
        />

        <BasePinCapture
          v-if="userDocument.type === 'pin'"
          :document="userDocument"
          :enrollments="enrollments"
          :accessToken="getAuthToken"
        />

        <SendEnrollmentDialog
          :open="enrollDialog"
          @send="sendEnrollment"
          :biometrics="transformedBiometrics"
          @cancel="enrollDialog = !enrollDialog"
        />
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <UserEnrollmentTable
          @rowClick="setEnrollment"
          @refresh="refresh"
          :enrollments="allCurrentEnrollments"
          :tenantBiometrics="tenantBiometrics"
          :enrollSuccess="getEnrollSuccess"
        />
      </div>
    </Section>
  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';

import BiometricMap from '@/utils/BiometricMap';

import userEnrollmentService from '@/services/UserEnrollmentService';
import tenantService from '@/services/TenantService';
import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';
import BasicEnrollmentToPlain from '@/transform/BasicEnrollmentToPlain';

import UserDocument from '@/models/UserDocument';
import BasicEnrollment from '@/models/BasicEnrollment';

export default {
  name: 'UserEnrollmentEdit',
  data: () => ({
    userDocument: new UserDocument({}),
    enrollmentModel: new BasicEnrollment({}),
    enrollDialog: false,
    transformedBiometrics: [],
    baseEnrollments: [],
    allEnrollmentsType: [],
  }),
  computed: {
    ...mapGetters('users', ['getCurrent']),
    ...mapGetters('userEnrollments', {
      enrollments: 'getEnrollments',
      pendingEnrollments: 'getPendingEnrollments',
    }),
    ...mapGetters('userAlerts', {
      getEnrollSuccess: 'getEnrollSuccess',
      getAllEnrollments: 'getAllEnrollments',
    }),
    ...mapGetters('tenantBiometrics', {
      tenantBiometrics: 'getAll',
    }),
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('authentication', ['getAuthToken']),
    ...mapGetters('tenantApplications', {
      firstTenantApp: 'getFirst',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    allCurrentEnrollments() {
      const enrollmentsFiles = this.combineEnrollmentFiles(this.enrollments);
      // The timestamp value for pending enrollments is the default created value from
      // the SQL table since it hasn't been created yet, but the UserEnrollmentTable header
      // looks for enrollTimestamp, so we add that value here.
      const pendingDateAdded = this.pendingEnrollments.map((item) => {
        const newItem = {};
        Object.assign(newItem, item);
        newItem.enrollTimestamp = item.created;
        return newItem;
      });
      // Combine enrollments with pending enrollments and filter out any yesno and
      // device enrollments there shouldn't be any there, but you never know...
      const combinedEnrollments = enrollmentsFiles.concat(pendingDateAdded)
        .filter(enroll => enroll.captureType !== 'yesno' && enroll.captureType !== 'device');

      return combinedEnrollments;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseVoiceCapture: () => import('@/components/base/BaseVoiceCapture'),
    BaseImageCapture: () => import('@/components/base/BaseImageCapture'),
    BasePinCapture: () => import('@/components/base/BasePinCapture'),
    BaseButton: () => import('@/components/base/BaseButton'),
    SendEnrollmentDialog: () => import('@/components/dialogs/SendEnrollmentDialog'),
    UserEnrollmentEdit: () => import('@/components/workspace/users/UserEnrollmentEdit'),
    UserEnrollmentTable: () => import('@/components/workspace/users/UserEnrollmentTable'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
  },
  methods: {
    ...mapActions('userAlerts', {
      fetchDocuments: 'fetchDocuments',
      fetchAllEnrollments: 'fetchResponses',
    }),
    ...mapActions('userEnrollments', {
      fetchEnrollments: 'fetchEnrollments',
      fetchPendingEnrollments: 'fetchPendingEnrollments',
      clearEnrollmentStore: 'clearStoreCustom',
    }),
    ...mapActions('tenantBiometrics', [
      'fetchBiometrics',
    ]),
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    ...mapActions('tenantApplications', {
      fetchApplications: 'fetchTenantApps',
    }),
    setDocument(document) {
      this.userDocument = document;
    },
    setEnrollment(enrollment) {
      if (enrollment) {
        const newEnrollment = Object.assign({}, enrollment);
        newEnrollment.captureType = enrollment.captureType === 'passphrase' ? 'voice' : enrollment.captureType;

        this.enrollmentModel = newEnrollment;
        if (newEnrollment.status === 'Enrolled') {
          if (newEnrollment.captureType === 'pin' || newEnrollment.captureType === 'palm') {
            const doc = new UserDocument(newEnrollment);
            doc.enrollTimestamp = newEnrollment.enrollTimestamp;
            this.setDocument(doc);
          } else {
            this.setDocument(newEnrollment);
          }
        } else {
          const pendingDoc = new UserDocument(newEnrollment);
          this.setDocument(pendingDoc);
        }
      }
    },
    combineEnrollmentFiles(enrollments) {
      // Any actual files for the enrollments are available in getEnrollSuccess
      // generated in userAlerts. We are matching by transactionUuid.
      const successFiles = this.getEnrollSuccess.filter(
        success => Array.isArray(success.files) && success.files.length > 0,
      );

      const enrollmentsFiles = enrollments.map((enrollment) => {
        const newEnrollment = enrollment;
        const filesMatch = successFiles.filter(sf => sf.bioType === newEnrollment.bioType);
        if (filesMatch.length > 0) {
          newEnrollment.files = [];
          newEnrollment.filesByDate = {};
          filesMatch.forEach((fm) => {
            newEnrollment.files.push(...fm.files);
            newEnrollment.filesByDate[fm.created] = [...fm.files];
          });
        }
        return newEnrollment;
      });
      return enrollmentsFiles;
    },
    setEnrollResponse(resp, type) {
      if (resp.status === 201) {
        this.setAlertMessage({
          type: 'success',
          message: 'Enrollment sent',
        });
      } else if (resp.status === 409) {
        this.setAlertMessage({
          type: 'info',
          message: 'User already has an enrollment pending',
        });
      } else {
        this.setAlertMessage({ type: 'unknown' });
      }
    },
    newClick() {},
    cancelClick() {},
    saveClick() {},
    deleteClick() {},
    clearData() {
      this.clearEnrollmentStore();
    },
    async openSendEnrollment() {
      this.enrollDialog = !this.enrollDialog;
    },
    async sendEnrollment(type) {
      const sendObj = {
        tenantCode: this.getStickyTenant,
        id: this.getCurrent.id,
        type,
      };

      this.enrollDialog = !this.enrollDialog;

      if (type.toLowerCase() === 'all') {
        this.enrollments.forEach(async (enrollment) => {
          sendObj.type = enrollment.captureType;
          const resp = await userEnrollmentService.reEnroll(sendObj);
          this.setEnrollResponse(resp, sendObj.type);
        });
        return;
      }

      const resp = await userEnrollmentService.reEnroll(sendObj);
      this.setEnrollResponse(resp, type);
    },
    async getBiometrics() {
      const vm = this;
      await this.fetchBiometrics({
        service: tenantService,
        key: 'code',
        tenantCode: vm.getStickyTenant,
        Transform: TenantBiometricToPlain,
      });
    },
    async setComponent() {
      this.setTabs('Users', 'tab-enrollments');
      if (this.getStickyTenant && this.getCurrent.id) {
        await this.fetchDocuments({
          tenantCode: this.getStickyTenant,
          personId: this.getCurrent.id,
        });

        await this.fetchApplications({
          service: tenantService,
          key: 'code',
          tenantCode: this.getStickyTenant,
        });

        await this.fetchEnrollments({
          tenantCode: this.getStickyTenant,
          personId: this.getCurrent.id,
          Transform: BasicEnrollmentToPlain,
        });

        await this.getBiometrics();

        await this.fetchAllEnrollments({
          tenantCode: this.getStickyTenant,
          id: this.getCurrent.id,
          Transform: BasicEnrollmentToPlain,
        });

        await this.fetchPendingEnrollments({
          appCode: this.firstTenantApp.code,
          personId: this.getCurrent.id,
          Transform: BasicEnrollmentToPlain,
        });

        if (this.allCurrentEnrollments.length > 0) {
          this.setEnrollment(this.allCurrentEnrollments[0]);
        }
      } else {
        this.$router.push({ name: 'UserEdit' });
      }
    },
  },
  watch: {
    tenantBiometrics(biometrics) {
      /* Hack for only showing a single palm */
      if (biometrics) {
        let seenPalm = false;
        // Filter out yesno and device biometrics here, since we don't want
        // them displayed in or in SendEnrollmentDialog.vue.
        const filteredBiometrics = biometrics.filter(b => b.captureType !== 'device' && b.captureType !== 'yesno');

        filteredBiometrics.forEach((biometric) => {
          Object.assign(biometric, BiometricMap.get(biometric.captureType));
          const { friendly } = biometric;
          if (seenPalm && friendly === 'Palm') {
            return;
          }
          if (friendly === 'Palm') {
            seenPalm = true;
          }

          this.transformedBiometrics.push({
            captureType: biometric.captureType,
            friendly,
          });
        });

        this.transformedBiometrics.push({
          type: 'all',
          friendly: 'All',
        });
      }
    },
  },
  beforeMount() {
    this.clearData();
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
