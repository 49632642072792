const headers = [
  {
    text: 'Template Name',
    value: 'name',
  },
  {
    text: 'Template Type',
    value: 'type',
    format: (template, index, createElement) => {
      const templateType = template.type === 'native' ? 'dynamic' : template.type;
      return createElement('div', templateType);
    },
  },
  {
    text: 'Included Biometrics',
    value: 'algorithms',
    /* eslint consistent-return: "off" */
    format: (template, index, createElement) => {
      if (template.algorithms && template.algorithms.length > 0) {
        const algList = [];
        template.algorithms.forEach((algorithm) => {
          algList.push(algorithm.algCode);
        });
        return createElement('div', algList.join(', '));
      }
    },
  },
];

export default headers;
