import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import biometricService from '@/services/BiometricService';
import biometricLivenessService from '@/services/BiometricLivenessService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const biometricLiveness = new BaseStoreModule({ component: 'Biometric Liveness', sort: { key: 'code', descending: false } });

const myState = {
  associated: [],
  unAssociated: [],
};

const myGetters = {
  getAssociatedAlgorithms: state => state.associated,
  getUnAssociatedAlgorithms: state => state.unAssociated,
};

const myActions = {
  async fetchBiometricLiveness({ commit, dispatch }, {
    livenessCode,
  }) {
    dispatch('setStoreKey', 'code');
    let found = false;
    const unAssociated = [];
    const associated = [];

    const resp = await biometricService.fetchAll();
    dispatch('setAll', { results: resp.data });

    resp.data.forEach((biometric) => {
      biometric.livenessChecks.forEach((liveness) => {
        if (liveness.code === livenessCode) {
          associated.push(biometric);
          found = true;
        }
      });
      if (!found) {
        unAssociated.push(biometric);
      }
      found = false;
    });

    commit(types.SET_BIOMETRIC_LIVENESS_ASSOC, associated);
    commit(types.SET_BIOMETRIC_LIVENESS_UN_ASSOC, unAssociated);
  },
  async associateBiometric({ commit, dispatch, getters }, {
    livenessCode,
    algorithmCode,
  }) {
    const resp = await biometricLivenessService.save(algorithmCode, livenessCode);
    if (resp.status === 200 || resp.status === 201) {
      commit(types.ADD, resp.data);
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Biometric added to Liveness',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
    return resp;
  },
  async disAssociateBiometric({
    state,
    dispatch,
    getters,
    commit,
  }, {
    livenessCode,
    algorithmCode,
  }) {
    const resp = await biometricLivenessService.delete(algorithmCode, livenessCode);
    if (resp.status === 204) {
      const index = state.all.findIndex(item => item.code === algorithmCode);
      commit(types.DELETE, index);
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Biometric removed from Liveness',
      }, { root: true });
    } else if (resp.status === 409) {
      dispatch('alertMessage/setAlertMessage', {
        type: 'error',
        message: 'A tenant is using '
          .concat(algorithmCode)
          .concat('/')
          .concat(livenessCode)
          .concat(' Please remove its association before removing this biomtric.'),
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
    return resp;
  },
  setAssocAlgorithms({ commit }, algs) {
    commit(types.SET_BIOMETRIC_LIVENESS_ASSOC, algs);
  },
  setUnAssocAlgorithms({ commit }, algs) {
    commit(types.SET_BIOMETRIC_LIVENESS_UN_ASSOC, algs);
  },
};

const myMutations = {
  [types.SET_BIOMETRIC_LIVENESS_ASSOC](state, algs) {
    state.associated = algs;
  },
  [types.SET_BIOMETRIC_LIVENESS_UN_ASSOC](state, algs) {
    state.unAssociated = algs;
  },
};

biometricLiveness.state = Object.assign(biometricLiveness.state, myState);
biometricLiveness.getters = Object.assign(biometricLiveness.getters, myGetters);
biometricLiveness.actions = Object.assign(biometricLiveness.actions, myActions);
biometricLiveness.mutations = Object.assign(biometricLiveness.mutations, myMutations);

export default biometricLiveness;
