<template>
  <div>
    <!-- Tabs -->
    <TenantTabs
      class="ap-tabs container fluid"
    />

    <router-view name="tenants" />

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WorkspaceTenants',
  components: {
    TenantTabs: () => import('@/components/workspace/tenants/TenantTabs'),
  },
  methods: {
    ...mapActions('tenants', {
      setCurrentTenant: 'setCurrent',
    }),
    ...mapActions('tabs', {
      setSelectedTab: 'setSelected',
    }),
  },
  mounted() {
    this.setCurrentTenant({});
  },
};
</script>
