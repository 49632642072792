const headers = [
  {
    text: 'Capture Type',
    value: 'captureType',
    // Hack to convert 'passphrase' to'voice' so that the displayed value
    // in the Capture Type column matches the value in the Capture Type field
    // in the form.
    format: (template, index, createElement) => {
      const captureType = template.captureType === 'passphrase' ? 'voice' : template.captureType;
      return createElement('div', captureType);
    },
  },
  {
    text: 'Biometric Name',
    value: 'code',
  },
  {
    text: 'BioType ID',
    value: 'bioType',
  },
  {
    text: 'Threshold',
    value: 'scoreThreshold',
  },
];

export default headers;
