import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: 'Group Code',
    rules: new RuleGenerator('Group Code')
      .setRequired()
      .getRules(),
  },
  dsid: {
    label: 'Private DSID',
    rules: new RuleGenerator('Private DSID')
      .setRequired()
      .getRules(),
  },
  enrollServerAddr: {
    label: 'Enroll Server Address',
    rules: new RuleGenerator('Enroll Server Address')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .getRules(),
    counter: '255',
  },
  verifyServerAddr: {
    label: 'Verify Server Address',
    rules: new RuleGenerator('Verify Server Address')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .getRules(),
    counter: '255',
  },
};

export default fields;
