class ServerGroup {
  constructor(serverGroup) {
    this.code = serverGroup.code || '';
    this.dsid = serverGroup.dsid || '';
    this.enrollServerAddr = serverGroup.enrollServerAddr || '';
    this.verifyServerAddr = serverGroup.verifyServerAddr || '';
    this.storeEnrollSuccess = serverGroup.storeEnrollSuccess || false;
    this.storeEnrollFailure = serverGroup.storeEnrollFailure || false;
    this.storeVerifySuccess = serverGroup.storeVerifySuccess || false;
    this.storeVerifyFailure = serverGroup.storeVerifyFailure || false;
  }
}

export default ServerGroup;
