import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import tenantUserService from '@/services/TenantUserService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/
const users = new BaseStoreModule({ component: 'User', sort: { key: 'userId', descending: false } });

const myState = {
  uploadProgress: 0,
};

const myGetters = {
  uploadProgress: state => state.uploadProgress,
};

const myActions = {
  async deleteUser({
    commit,
    dispatch,
    getters,
    state,
  }, { user, tenantCode }) {
    const resp = await tenantUserService.delete({ user, tenantCode });

    if (resp.status === 204) {
      const index = getters.getIndexByKey(user[state.key]);
      commit(types.DELETE, index);
    }
    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });
    return resp;
  },
  async bulkSave({
    dispatch,
    getters,
  }, item) {
    let saveCount = 0;
    const usersToUpload = item.users.length;
    let uploadPercentage = 0;
    let resp = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const user of item.users) {
      // eslint-disable-next-line no-await-in-loop
      resp = await tenantUserService.save({
        user,
        tenantCode: item.tenantCode,
        skipUserCheck: true,
      });
      const { status, data } = resp;
      if (resp.status === 201) {
        saveCount += 1;
        // Calculate the percentage of users that have been uploaded
        // Used for progress bar display.
        uploadPercentage = Math.round((saveCount / usersToUpload) * 100);
        dispatch('setUploadProgress', uploadPercentage);
        dispatch('setSaveResponse', { status, data });
      }
    }

    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });
    return resp;
  },
  async setUploadProgress({ commit }, count) {
    commit(types.SET_UPLOAD_PROGRESS, count);
  },
};

const myMutations = {
  [types.SET_UPLOAD_PROGRESS](state, count) {
    state.uploadProgress = count;
  },
};

users.actions = Object.assign(users.actions, myActions);
users.mutations = Object.assign(users.mutations, myMutations);
users.getters = Object.assign(users.getters, myGetters);
users.state = Object.assign(users.state, myState);

export default users;
