<template>
  <div>
    <div>
      <WorkspaceActionDial :showDelete="canDelete" @dialDelete="deleteClick"/>
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container>
            <v-layout row>
              <v-flex xs6>
                <v-text-field label="Username" v-model="getCurrent.userId" disabled/>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.deviceId" v-model="deviceModel.deviceId" disabled/>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.platform" v-model="deviceModel.platform" disabled/>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.platformVersion"
                  v-model="deviceModel.platformVersion"
                  disabled
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <BaseDeleteModal
          :open="deleteDeviceModal"
          componentName="Device"
          @deleteModalConfirm="deleteDeviceModalConfirm"
        />
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <UserDeviceTable @rowClick="setDevice" @refresh="refresh" :devices="devices"/>
      </div>
    </Section>
  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';

import { mapGetters, mapActions } from 'vuex';

import { fields } from '@/components/config/userDevices';

import userDeviceService from '@/services/UserDeviceService';

import Device from '@/models/Device';
import State from '@/models/State';

export default {
  name: 'UserDeviceEdit',
  data: () => ({
    fields,
    deviceModel: new Device({}),
    deviceModelState: new State({}),
    deleteDeviceModal: false,
  }),
  computed: {
    ...mapGetters('userDevices', {
      devices: 'getAll',
    }),
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('users', ['getCurrent']),
    canDelete() {
      return !!this.deviceModel.deviceId;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    UserDeviceEdit: () => import('@/components/workspace/users/UserDeviceEdit'),
    UserDeviceTable: () => import('@/components/workspace/users/UserDeviceTable'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
  },
  methods: {
    ...mapActions('userDevices', {
      fetchDevices: 'fetchAllBy',
      deleteDevice: 'deleteDevice',
    }),
    deviceModelCancel() {
      this.setDeviceModel(this.deviceModelState.original);
    },
    setDevice(device) {
      this.deviceModel = new Device(device);
      this.deviceModelState = new State({
        original: this.deviceModel,
      });
    },
    newClick() {},
    cancelClick() {},
    saveClick() {},
    deleteClick() {
      if (this.canDelete) {
        this.deleteDeviceModal = !this.deleteDeviceModal;
      }
    },
    async deleteDeviceModalConfirm(confirm) {
      if (confirm) {
        await this.deleteDevice({
          device: this.deviceModel,
          personId: this.getCurrent.id,
        });
        this.setDevice(new Device({}));
      }
      this.deleteDeviceModal = false;
    },
    async setComponent() {
      this.setTabs('Users', 'tab-devices');

      if (this.getStickyTenant && this.getCurrent.id) {
        await this.fetchDevices({
          service: userDeviceService,
          key: 'deviceId',
          fetchBy: {
            id: this.getCurrent.id,
          },
        });
      } else {
        this.$router.push({ name: 'UserEdit' });
      }
    },
  },
  watch: {
    devices(devices) {
      if (devices[0]) {
        this.setDevice(devices[0]);
      }
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
