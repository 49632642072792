const buttons = {
  downloadButton: {
    text: 'Download',
  },
  clearButton: {
    text: 'Clear',
  },
};

export default buttons;
