import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class ApplicationTemplateService extends BaseService {
  static async fetchAll({ tenantCode, appCode }) {
    return super.perform(await axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/templates?hideDefaultTemplate=true')));
  }

  static async save({ tenantCode, appCode, template }) {
    return super.perform(await axios.put(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/template/')
        .concat(template.name),
      template,
    ));
  }

  static async delete({ tenantCode, appCode, template }) {
    return super.perform(await axios.delete(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/template/')
        .concat(template.name),
      template,
    ));
  }

  static async saveDefaultTemplate(
    tenantCode,
    appCode,
    template,
  ) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/template/default'), template));
  }

  static async getDefaultTemplate(
    tenantCode,
    appCode,
  ) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/template/default')));
  }

  static async getAllTemplatesWithDefault(
    tenantCode,
    appCode,
  ) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/templates?hideDefaultTemplate=false')));
  }
}

export default ApplicationTemplateService;
