import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import credUserService from '@/services/CredUserService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const userCredentials = new BaseStoreModule({ component: 'Oauth User', sort: { key: 'userId', descending: false } });

const myState = {
  /* Roles for selected user */
  currentRoles: [],
};

const myActions = {
  async fetchRoles({ commit }, { userId }) {
    const resp = await credUserService.fetchRoles({ userId });
    if (resp.status === 200) {
      const results = resp.data;
      commit(types.SET_ROLES, results);
    }
  },
  async saveUser({ dispatch, getters }, { item }) {
    const resp = await credUserService.updateUser(item);
    if (resp.status === 200) {
      dispatch('update', resp.data);
    } else if (resp.status === 201) {
      dispatch('addOneSorted', resp.data);
    } else if (resp.status === 409) {
      dispatch('addOneSorted', resp.data);
    }
    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });
    return resp;
  },
  async newUser({ dispatch, getters }, { item }) {
    let resp = await credUserService.newUser(item);
    if (resp.status === 200) {
      dispatch('update', resp.data);
    } else if (resp.status === 201) {
      dispatch('addOneSorted', resp.data);
    } else if (resp.status === 409) {
      /* User already exists save it */
      const existsResp = await credUserService.getByEmail(item.userId);
      if (existsResp.status === 200 || existsResp.status === 201) {
        /* Keep/Save Password */
        const { password } = item;
        const updated = { ...item, ...existsResp.data };
        updated.password = password;

        /* Save User */
        dispatch('saveUser', { item: updated });
        resp = existsResp;
      }
    }

    if (resp.status !== 409) {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }

    return resp;
  },
};

const myGetters = {
  getRoles: state => state.currentRoles,
  rolesByTenant: state => tenantCode => (
    state.currentRoles.filter(role => role.tenantCode === tenantCode)
  ),
  rolesByType: state => type => (
    state.currentRoles.find(role => role.securedResourceCode === type)
  ),
  gmiRoles: state => (
    state.currentRoles.find(role => role.securedResourceCode === 'gmiserver')
  ),
  userRoles: state => (
    state.currentRoles.find(role => role.securedResourceCode === 'usermanager')
  ),
};

const myMutations = {
  [types.SET_ROLES](state, roles) {
    state.currentRoles = roles;
  },
};

userCredentials.state = Object.assign(userCredentials.state, myState);
userCredentials.actions = Object.assign(userCredentials.actions, myActions);
userCredentials.getters = Object.assign(userCredentials.getters, myGetters);
userCredentials.mutations = Object.assign(userCredentials.mutations, myMutations);

export default userCredentials;
