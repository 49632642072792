import credUserService from '@/services/CredUserService';
import tenantService from '@/services/TenantService';
import * as types from '@/store/mutation-types';
import RoleUtil from '@/utils/RoleUtil';
import RoleModel from '@/models/Role';
import CredUser from '@/models/CredUser';
import cookie from 'vue-cookies';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

class AppStoreModule {
  constructor() {
    this.namespaced = true;

    this.state = {
      username: null,
      topRole: new RoleModel({}),
      gmiRoles: [],
      umRole: null,
      tenantsReady: false,
      stickyTenant: null,
      stickyTenantDisabled: false,
      currentUser: new CredUser({}),
      selectedTemplateApp: null,
      unsaved: false,
    };

    this.getters = {
      getTopRole: state => state.topRole,
      getGmiRoles: state => state.gmiRoles,
      getUmRole: state => state.umRole,
      roleAtLeastTa: state => (
        RoleUtil.roleValue(state.topRole.roleCode) >= RoleUtil.roleValue('ROLE_TENANT_ADMIN')
      ),
      roleAtLeastTm: state => (
        RoleUtil.roleValue(state.topRole.roleCode) >= RoleUtil.roleValue('ROLE_TENANT_MAINT')
      ),
      roleAtLeastSuper: state => (
        RoleUtil.roleValue(state.topRole.roleCode) >= RoleUtil.roleValue('ROLE_SUPER_ADMIN')
      ),
      hasMinimumRole: state => role => (
        RoleUtil.roleValue(state.topRole.roleCode) >= RoleUtil.roleValue(role)
      ),
      getUsername: state => state.username,
      getUser: state => state.currentUser,
      tenantsReady: state => state.tenantsReady,
      getStickyTenant: state => state.stickyTenant,
      getStickyTenantDisabled: state => state.stickyTenantDisabled,
      getSelectedTemplateApp: state => state.selectedTemplateApp,
      getUnsaved: state => state.unsaved,
    };

    this.actions = {
      async fetchUserDetails({ commit, dispatch }) {
        const userResp = await credUserService.getByEmail(this.getters['app/getUsername']);

        if (userResp.status === 200) {
          commit(types.SET_CURRENT_USER, userResp.data);

          const umRoles = await credUserService.fetchRoles({ userId: userResp.data.uuid });
          const tenantRoles = await tenantService.fetchAll();

          if (umRoles.status === 200 && tenantRoles.status === 200) {
            /* Get gmiserver role */
            const gmiRoles = umRoles.data.filter(role => role.securedResourceCode === 'gmiserver');
            // Filter out roles from usermanager using tenantRI just taoles, since usermananger
            // returns records for tenants that no longer exist in gmi.
            const filteredGmiRoles = gmiRoles
              .filter(g => tenantRoles.data.some(t => g.tenantCode === t.code));

            commit(types.SET_GMI_ROLES, filteredGmiRoles);

            // Set the usermanager role for the user.
            const umrole = umRoles.data.filter(r => r.securedResourceCode === 'usermanager');
            commit(types.SET_UM_ROLE, umrole[0]);

            /* Get and set highest role for this user */
            const topRole = RoleUtil.highestRole(filteredGmiRoles);
            commit(types.SET_TOP_ROLE, topRole);

            await dispatch('tenants/setStoreKey', 'code', { root: true });
            await dispatch('tenants/fetchRoleTenants', {
              service: tenantService,
              key: 'code',
            },
            { root: true });

            commit(types.SET_TENANTS_READY, true);
          }
        }
      },
      setUsername({ commit }, username) {
        commit(types.SET_USERNAME, username);
      },
      setTenantsReady({ commit }, val) {
        commit(types.SET_TENANTS_READY, val);
      },
      setStickyTenant({ commit, rootGetters }, val) {
        // We also set a cookie to use when the user first logs in.
        const currUser = rootGetters['app/getUsername'];
        const cookieName = `ap_tenant_${currUser}`;
        commit(types.SET_STICKY_TENANT, { code: val, cookieName });
      },
      setStickyTenantDisabled({ commit }, val) {
        commit(types.SET_STICKY_TENANT_DISABLED, val);
      },
      clearStore({ commit, dispatch }) {
        commit(types.SET_TOP_ROLE, new RoleModel({}));
        commit(types.SET_GMI_ROLES, []);
        commit(types.SET_CURRENT_USER, new CredUser({}));
        dispatch('setUsername', null);
        dispatch('setTenantsReady', false);
        dispatch('setStickyTenant', null);
        dispatch('setSelectedTemplateApp', null);
      },
      setSelectedTemplateApp({ commit }, app) {
        commit(types.SET_SELECTED_APP, app);
      },
      setUnsaved({ commit }, unsavedStatus) {
        commit(types.SET_UNSAVED, unsavedStatus);
      },
    };

    this.mutations = {
      [types.SET_USERNAME](state, username) {
        state.username = username;
        sessionStorage.setItem('apusername', username);
      },
      [types.SET_CURRENT_USER](state, currentUser) {
        state.currentUser = new CredUser(currentUser);
      },
      [types.SET_TOP_ROLE](state, topRole) {
        state.topRole = topRole;
      },
      [types.SET_UM_ROLE](state, role) {
        state.umRole = role;
      },
      [types.SET_GMI_ROLES](state, gmiRoles) {
        state.gmiRoles = gmiRoles;
      },
      [types.SET_TENANTS_READY](state, bool) {
        state.tenantsReady = bool;
      },
      [types.SET_STICKY_TENANT_DISABLED](state, bool) {
        state.stickyTenantDisabled = bool;
      },
      [types.SET_STICKY_TENANT](state, tenantData) {
        if (!tenantData.code) {
          sessionStorage.removeItem('stickyTenant');
          state.stickyTenant = null;
        } else {
          sessionStorage.setItem('stickyTenant', tenantData.code);
          cookie.set(tenantData.cookieName, tenantData.code);
          state.stickyTenant = tenantData.code;
        }
      },
      [types.SET_SELECTED_APP](state, app) {
        state.selectedTemplateApp = app;
      },
      [types.SET_UNSAVED](state, unsavedStatus) {
        state.unsaved = unsavedStatus;
      },
    };
  }
}

export default AppStoreModule;
