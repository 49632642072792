const buttons = {
  configFile: {
    text: 'Config File',
  },
  changeBiometricGroup: {
    text: 'Edit Apps/Biometrics',
  },
};

export default buttons;
