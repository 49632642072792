class Biometric {
  constructor(biometric) {
    this.code = biometric.code || '';
    this.captureType = biometric.captureType || '';
    this.bioType = biometric.bioType || '';
    this.version = biometric.version || '';
    this.verifySamples = biometric.verifySamples || '';
    this.scoreThreshold = typeof biometric.scoreThreshold === 'number' ? biometric.scoreThreshold : null;
    this.zscoreThreshold = typeof biometric.zscoreThreshold === 'number' ? biometric.zscoreThreshold : null;
    this.fusionWeight = biometric.fusionWeight || '';
    this.enrollType = biometric.enrollType || '';
    this.enrollAction = biometric.enrollAction || '';
    this.vendor = biometric.vendor || '';
    this.templateType = biometric.templateType || '';
    this.enrollSamples = biometric.enrollSamples || '';
    this.livenessChecks = biometric.livenessChecks || [];
    this.enrollSamplesCount = biometric.enrollSamplesCount || 0;
    this.verifySamplesCount = biometric.verifySamplesCount || 0;
  }
}

export default Biometric;
