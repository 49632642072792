<template>
  <div>
    <div>
      <WorkspaceActionDial
        showSend
        :showDelete="canDelete"
        @dialSend="toggleSendMessageModal(false)"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.username"
                    v-model="getCurrent.userId" disabled />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.application"
                    v-model="alertModel.application" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.createdDate"
                    v-model="alertModel.createdDate" disabled />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.messageUuid"
                    v-model="alertModel.messageUuid" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.transactionType"
                    v-model="alertModel.transactionType" disabled />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.transactionUuid"
                    v-model="alertModel.transactionUuid" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.template"
                    v-model="alertModel.template" disabled />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.tenant"
                    v-model="getStickyTenant" disabled />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.status"
                    v-model="alertModel.statusFriendly" disabled />
                </v-flex>
              <v-flex xs6>
                <v-text-field v-if="alertModel.rejectionInfo"
                  v-bind="fields.rejectionInfo"
                  v-model="alertModel.rejectionInfo"
                  disabled
                  hint="Reason sent from a rejection response"
                />
              </v-flex>
            </v-layout>
              <v-divider v-if="bioTypeScores.length > 0"></v-divider>
              <v-layout row v-for="score in bioTypeScores" :key="score.index">
                <v-flex xs3>
                <v-text-field
                  :value="score.type"
                  :label="score.bioTypeLabel"
                  disabled
                />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    :value="score.threshold"
                    :label="score.thresholdLabel"
                    disabled
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    :value="score.score"
                    :label="score.scoreLabel"
                    disabled
                  />
                </v-flex>
              </v-layout>
          </v-container>
        </v-form>

        <div>
          <BaseButton
            v-bind="biometricsButton"
            @clicked="biometricsClick"
            :disabled="!biometricsExist"
          />
          <BaseButton
            v-bind="detailsButton"
            @clicked="open = true"
          />
        </div>

        <AlertDetailsDialog
          :open="open"
          :model="alertModel"
          :user="getCurrent"
          @clicked="open = false"
        />

        <BiometricImageCompareDialog
          isAlert
          :open="imageDialog"
          :documents="alertDocuments"
          :enrollments="imageEnrollments"
          :alertDetails="alertModel"
          :authToken="getAuthToken"
          :disableEnrollment="!imageEnrollments"
          @closed="imageDialog = false"
        />

        <BiometricVoiceDialog
          isAlert
          :open="voiceDialog"
          :documents="alertDocuments"
          :enrollments="voiceEnrollments"
          :alertDetails="alertModel"
          :authToken="getAuthToken"
          :disableEnrollment="!voiceEnrollments"
          @closed="voiceDialog = false"
        />
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <UserAlertTable
          @rowClick="setAlert"
          @refresh="refresh"
          :alerts="alertsWithId"
        />
      </div>
    </Section>

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';

import { fields, buttons } from '@/components/config/userAlerts';

import tenantAlertService from '@/services/TenantAlertService';

import Alert from '@/models/Alert';

import AlertToPlain from '@/transform/AlertToPlain';
import BasicEnrollmentToPlain from '@/transform/BasicEnrollmentToPlain';

export default {

  name: 'UserAlertEdit',
  data: () => ({
    alertModel: new Alert({}),
    open: false,
    imageDialog: false,
    voiceDialog: false,
    fields,
    ...buttons,
  }),
  computed: {
    ...mapGetters('users', ['getCurrent']),
    ...mapGetters('userAlerts', {
      alerts: 'getAll',
      getEnrollSuccess: 'getEnrollSuccess',
      getEnrollFailure: 'getEnrollFailure',
      getVerifySuccess: 'getVerifySuccess',
      getVerifyFailure: 'getVerifyFailure',
      getDocumentsByTransaction: 'getDocumentsByTransaction',
    }),
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('authentication', ['getAuthToken']),
    ...mapGetters('userEnrollments', {
      enrollments: 'getEnrollments',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    voiceEnrollments() {
      return this.getEnrollSuccess.filter(enrollment => enrollment.type === 'voice');
    },
    imageEnrollments() {
      return this.getEnrollSuccess.filter(enrollment => enrollment.type === 'face');
    },
    alertDocuments() {
      if (!this.alertModel
        || !this.getDocumentsByTransaction[this.alertModel.transactionUuid]) {
        return [];
      }
      return this.getDocumentsByTransaction[this.alertModel.transactionUuid];
    },
    biometricsExist() {
      return (typeof this.alertDocuments !== 'undefined')
        && this.alertDocuments.length > 0
        && (this.alertDocuments[0].type === 'face'
        || this.alertDocuments[0].type === 'voice');
    },
    bioTypeScores() {
      let bioScores = [];
      const vm = this;
      if (typeof this.alertModel.bioTypeScores !== 'undefined') {
        // first filter out any scores for device and yesno biomeetrics.
        const filteredBioScores = this.alertModel.bioTypeScores
          .filter(score => score.bioType < 9000);
        bioScores = filteredBioScores.map((type, index) => {
          const newType = type;
          newType.score = type.score === null ? '' : type.score;
          newType.threshold = type.threshold === null ? '' : type.threshold;
          // We only want to display labels for the first item, so we add an item that has the
          // label if it's the first one, and is blank if not.
          if (index === 0) {
            newType.scoreLabel = 'Score';
            newType.thresholdLabel = 'Threshold';
            newType.bioTypeLabel = 'BioType';
          }

          // Get captureType for biometric.
          if (vm.allBiometrics.length > 0) {
            const matchedBiometric = vm.allBiometrics.filter(
              biometric => biometric.bioType === type.bioType,
            );
            const captureType = matchedBiometric[0].captureType === 'passphrase' ? 'voice' : matchedBiometric[0].captureType;
            newType.type = captureType[0].toUpperCase() + captureType.slice(1);
          }

          return newType;
        });
      }
      return bioScores;
    },
    canDelete() {
      return this.alertModel.statusFriendly === 'Pending';
    },
    alertsWithId() {
      // We have to create a unique rowId value for each alert for use in row highlighting.
      // This is done by 1) using a transactionUuid if it exists, and if not, 2) falling back to
      // messageUuid.
      const newAlerts = this.alerts.map((alert) => {
        const newAlert = alert;
        newAlert.rowId = alert.transactionUuid ? alert.transactionUuid : alert.messageUuid;
        return newAlert;
      });
      return newAlerts;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    AlertDetailsDialog: () => import('@/components/dialogs/AlertDetailsDialog'),
    BiometricImageCompareDialog: () => import('@/components/dialogs/BiometricImageCompareDialog'),
    BiometricVoiceDialog: () => import('@/components/dialogs/BiometricVoiceDialog'),
    UserAlertEdit: () => import('@/components/workspace/users/UserAlertEdit'),
    UserAlertTable: () => import('@/components/workspace/users/UserAlertTable'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
  },
  methods: {
    ...mapActions('userAlerts', {
      fetchAlerts: 'fetchAllBy',
      fetchDocuments: 'fetchDocuments',
      clearAlertStore: 'clearStoreCustom',
      deleteAlert: 'delete',
    }),
    ...mapActions('userEnrollments', {
      fetchEnrollments: 'fetchEnrollments',
    }),
    ...mapActions('alertMessageDialog', ['toggleSendMessageModal']),
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
    }),
    biometricsClick() {
      if (this.alertDocuments[0].type === 'face') {
        this.imageDialog = !this.imageDialog;
      } else {
        this.voiceDialog = !this.voiceDialog;
      }
    },
    clearData() {
      this.clearAlertStore();
    },
    newClick() {},
    cancelClick() {},
    saveClick() {},
    async deleteClick() {
      await this.deleteAlert({
        service: tenantAlertService,
        item: {
          personId: this.getCurrent.id,
          appCode: this.alertModel.application,
          messageUuid: this.alertModel.messageUuid,
        },
      });
    },
    async setAlert(alert) {
      this.alertModel = Object.assign({}, alert);
    },
    async setComponent() {
      this.setTabs('Users', 'tab-alerts');

      if (this.getStickyTenant && this.getCurrent.id) {
        await this.fetchAlerts({
          service: tenantAlertService,
          key: 'messageUuid',
          fetchBy: {
            tenantCode: this.getStickyTenant,
            id: this.getCurrent.id,
          },
          Transform: AlertToPlain,
        });

        await this.fetchEnrollments({
          Transform: BasicEnrollmentToPlain,
          tenantCode: this.getStickyTenant,
          personId: this.getCurrent.id,
        });

        await this.fetchDocuments({
          tenantCode: this.getStickyTenant,
          personId: this.getCurrent.id,
        });
        // Display the first alert in the form.
        this.setAlert(this.alerts[0]);
      } else {
        this.$router.push({ name: 'UserEdit' });
      }
    },
  },
  beforeMount() {
    this.clearData();
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>

<style scoped>
  .success{
    font-color: #009688 !important;
  }

</style>
