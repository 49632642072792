import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import DocumentMap from '@/utils/DocumentMap';
import userEnrollmentService from '@/services/UserEnrollmentService';
import BasicEnrollment from '../../models/BasicEnrollment';
import BiometricMap from '@/utils/BiometricMap';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const userEnrollments = new BaseStoreModule({ component: 'Enrollment', sort: { key: 'bioType', descending: false } });

const myState = {
  enrollments: [],
  pending_enrollments: [],
  documents: [],
};

const myGetters = {
  getDocuments: state => DocumentMap.map(state.documents),
  getEnrollments: state => state.enrollments,
  getPendingEnrollments: state => state.pending_enrollments,
};

const myActions = {
  async fetchEnrollments({ commit, dispatch }, {
    tenantCode,
    personId,
    Transform,
  }) {
    dispatch('setStoreKey', 'bioType');
    const resp = await userEnrollmentService.fetchAllEnrollments({ tenantCode, personId });
    if (resp.status === 200) {
      let results = [];
      if (typeof resp.data.data === 'object' && resp.data.data !== null) {
        results = resp.data.data.biometricMetadata.enrollmentData;

        // Filter out enrollments returned from server that have incomplete data,
        // e.g. with captureType === null;
        results = results.filter(result => (result.captureType));

        if (Transform) {
          results = results.map(res => new Transform(res));
        }
      }
      commit(types.SET_ENROLLMENTS, results);
    }
  },
  async fetchPendingEnrollments({ commit }, {
    appCode,
    personId,
    Transform,
  }) {
    const resp = await userEnrollmentService.fetchPendingEnrollments({
      appCode,
      id: personId,
    });
    if (resp.status === 200) {
      let results = [];
      let enrollResults = [];
      if (resp.data) {
        results = resp.data;
      }

      // We have to get out all the data from metadata.enrollData in each result.
      const enrollData = results.map(result => result.metadata.enrollData);

      if (Transform) {
        enrollResults = enrollData.map((res) => {
          // We can't use the BasicEnrollmentToPlain transform that is used in
          // fetchEnrollments (above) because it adds some items we don't want
          // for pending enrollments, so we do the basics here.
          const enrollment = new BasicEnrollment(res);
          // This gets the friendly, type, and icon key values.
          Object.assign(enrollment, BiometricMap.get(enrollment.captureType));
          enrollment.status = 'Pending';

          return enrollment;
        });
      }

      commit(types.SET_PENDING_ENROLLMENTS, enrollResults);
    }
  /*  const key = application.code.concat('->').concat(personId);
    const r = new Response('enrollment', 'Fetch Pending Enrollment', 'get', resp.duration, key); */
  },
  async fetchDocuments({ commit, dispatch }, {
    tenantCode,
    userId,
    Transform,
  }) {
    dispatch('setStoreKey', 'bioType');
    const resp = await userEnrollmentService.fetchAllDocuments({ tenantCode, userId });
    if (resp.status === 200) {
      let results = resp.data;

      if (Transform) {
        results = results.map(res => new Transform(res));
      }

      commit(types.SET_DOCUMENTS, results);
    }
  },
  clearStoreCustom({ commit }) {
    commit(types.SET_DOCUMENTS, []);
    commit(types.SET_ENROLLMENTS, []);
    commit(types.SET_PENDING_ENROLLMENTS, []);
  },
};

const myMutations = {
  [types.SET_ENROLLMENTS](state, enrollments) {
    state.enrollments = enrollments;
  },
  [types.SET_PENDING_ENROLLMENTS](state, enrollments) {
    state.pending_enrollments = enrollments;
  },
  [types.SET_DOCUMENTS](state, documents) {
    state.documents = documents;
  },
};


userEnrollments.state = Object.assign(userEnrollments.state, myState);
userEnrollments.getters = Object.assign(userEnrollments.getters, myGetters);
userEnrollments.actions = Object.assign(userEnrollments.actions, myActions);
userEnrollments.mutations = Object.assign(userEnrollments.mutations, myMutations);

export default userEnrollments;
