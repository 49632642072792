<template>
  <div>
    <ServerTabs
      class="ap-tabs container fluid"
    />
    <router-view name="server" />

  </div>
</template>

<script>
export default {
  name: 'WorkspaceServer',
  components: {
    Section: () => import('@/components/layout/Section'),
    ServerTabs: () => import('@/components/workspace/server/ServerTabs'),
  },
};
</script>
