class RandomUtil {
  static randomPassword() {
    const uppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowers = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specials = '\'{}|!#$%&^*/=?`~;@._+-"';

    let password = '';
    password += RandomUtil.getRandom(uppers, 5);
    password += RandomUtil.getRandom(lowers, 5);
    password += RandomUtil.getRandom(numbers, 3);
    password += RandomUtil.getRandom(specials, 3);

    password = RandomUtil.shuffleString(password);
    return password;
  }

  static getRandom(str, len) {
    let res = '';
    for (let i = 0; i < len; i += 1) {
      res += str.charAt(Math.floor(Math.random() * str.length));
    }
    return res;
  }

  static shuffleString(str) {
    const a = str.split('');
    const n = a.length;

    for (let i = n - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join('');
  }

  static randomColor() {
    const colorVals = '0123456789ABCDEF';
    let color = '';
    for (let i = 0; i < 6; i += 1) {
      color += colorVals[Math.floor(Math.random() * 16)];
    }
    return '#'.concat(color);
  }
}

export default RandomUtil;
