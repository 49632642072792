class Alert {
  constructor(alert) {
    this.username = alert.username || '';
    this.messageUuid = alert.messageUuid || '';
    this.transactionUuid = alert.transactionUuid || '';
    this.transactionType = alert.transactionType || '';
    this.application = alert.application || '';
    this.template = alert.templateUri || '';
    this.createdDate = alert.createdDate || '';
    this.succeeded = alert.succeeded || '';
    this.icon = '';
    this.iconColor = '';
    this.beResponse = alert.beResponse || '';
    this.exception = alert.exception || '';
    this.postBackUrl = alert.postBackUrl || '';
    this.bioTypeScores = alert.bioTypeScores;
    this.rejectionInfo = alert.rejectionInfo || '';
    this.antispoofResponse = alert.antispoofResponse || '';
  }
}

export default Alert;
