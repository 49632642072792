import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: 'Liveness Code',
    rules: new RuleGenerator('Liveness Code')
      .setRequired()
      .setMin(1)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
  name: {
    label: 'Liveness Name',
    rules: new RuleGenerator('Liveness Name')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .setRegEx(/^[\d\w .,_-]+$/)
      .getRules(),
    counter: '255',
  },
  vendor: {
    label: 'Vendor',
    rules: new RuleGenerator('Vendor')
      .setRequired()
      .setMin(1)
      .setMax(100)
      .getRules(),
    counter: '100',
  },
  version: {
    label: 'Version',
    rules: new RuleGenerator('Version')
      .setMin(1)
      .setMax(45)
      .getRules(),
    counter: '45',
  },
};

export default fields;
