import Vue from 'vue';
import Theme from '@/theme/Theme';

import {
  Vuetify,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VIcon,
  VGrid,
  VToolbar,
  VChip,
  transitions,
  VAlert,
  VTextField,
  VCard,
  VForm,
  VAutocomplete,
  VDialog,
  VDataTable,
  VMenu,
  VSelect,
  VTabs,
  VSubheader,
  VCombobox,
  VTextarea,
  VDivider,
  VSpeedDial,
  VBottomSheet,
  VProgressCircular,
  VImg,
  VDatePicker,
  VProgressLinear,
  VCheckbox,
  VSwitch,
  VTooltip,
} from 'vuetify';

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    VChip,
    transitions,
    VAlert,
    VTextField,
    VCard,
    VForm,
    VAutocomplete,
    VDialog,
    VDataTable,
    VMenu,
    VSelect,
    VTabs,
    VSubheader,
    VCombobox,
    VTextarea,
    VDivider,
    VSpeedDial,
    VBottomSheet,
    VProgressCircular,
    VImg,
    VDatePicker,
    VProgressLinear,
    VCheckbox,
    VSwitch,
    VTooltip,
  },
  theme: new Theme(),
});
