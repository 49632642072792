import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class ApplicationService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/app')));
  }

  static async save(app) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/app/')
      .concat(app.code), app));
  }

  static async delete(app) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/app/')
      .concat(app.code), app));
  }

  static async uploadApnsCert(app, data) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/app/')
        .concat(app.code)
        .concat('/uploadcert/prod'),
      data,
    ));
  }

  static async uploadTestApnsCert(app, data) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/app/')
        .concat(app.code)
        .concat('/uploadcert/test'),
      data,
    ));
  }
}

export default ApplicationService;
