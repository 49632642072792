import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';
// import Tenant from '@/models/Tenant';

/* eslint-disable no-return-await */

class TenantTemplateService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant')));
  }

  static async fetch(tenantCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode)));
  }

  static async save({
    tenantCode,
    appCode,
    templateCode,
    captureType,
    biometric,
  }) {
    return super.perform(await axios.put(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/template/')
        .concat(templateCode)
        .concat('/type/')
        .concat(captureType),
      biometric,
    ));
  }

  static async getTemplateBiometrics(tenantCode, appCode, templateCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/template/')
      .concat(templateCode)
      .concat('/type/')));
  }

  static async associateTenantTemplateBiometric(
    tenantCode,
    appCode,
    templateCode,
    captureType,
    biometric,
  ) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/template/')
      .concat(templateCode)
      .concat('/type/')
      .concat(captureType), biometric));
  }

  static async deleteTenantTemplateBiometric(
    tenantCode,
    appCode,
    templateCode,
    captureType,
  ) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/app/')
      .concat(appCode)
      .concat('/template/')
      .concat(templateCode)
      .concat('/type/')
      .concat(captureType)));
  }
}

export default TenantTemplateService;
