import Validation from '@/utils/Validation';

class RuleGenerator {
  constructor(label) {
    this.label = label;
    this.rules = [];
    return this;
  }

  setRequired() {
    this.rules.push(v => !!v || this.label.concat(' is required'));
    return this;
  }

  setMin(min) {
    this.rules.push(v => Validation.minLength(v, min)
      || this.label
        .concat(' must be greater than ')
        .concat(min)
        .concat(' character'));
    return this;
  }

  setMax(max) {
    this.rules.push(v => Validation.maxLength(v, max)
      || this.label
        .concat(' must be less than ')
        .concat(max)
        .concat(' characters'));
    return this;
  }

  setRegEx(regEx) {
    this.rules.push(v => regEx.test(v) || this.label.concat(' must be valid'));
    return this;
  }

  setUrl() {
    this.rules.push(v => Validation.isUrl(v) || this.label.concat(' must be a url'));
    return this;
  }

  setPng() {
    this.rules.push(v => Validation.isPng(v) || this.label.concat(' must be a png file'));
    return this;
  }

  setNumber() {
    this.rules.push(v => Validation.isNumber(v) || this.label.concat(' must be a number'));
    return this;
  }

  setHex() {
    this.rules.push(v => Validation.isHex(v) || this.label.concat(' must be a valid hex value.'));
    return this;
  }

  static getCodeRules() {
    return new RuleGenerator('Code')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .setRegEx(/[\w\d]/)
      .getRules();
  }

  static getCodeLabel() {
    return 'Code';
  }

  static getCodeHint() {
    return 'Must contain between 1 and 255 letters and numbers';
  }

  static getCodeCounter() {
    return '255';
  }

  getRules() {
    return this.rules;
  }
}

export default RuleGenerator;
