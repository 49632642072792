import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class BiometricLivenessService extends BaseService {
  static async fetchAll(algCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/alg/')
      .concat(algCode)
      .concat('/liveness')));
  }

  static async fetch(algCode, livenessCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/alg/')
      .concat(algCode)
      .concat('/liveness/')
      .concat(livenessCode)));
  }

  static async save(algCode, livenessCode) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/alg/')
      .concat(algCode)
      .concat('/liveness/')
      .concat(livenessCode), null));
  }

  static async delete(algCode, livenessCode) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/alg/')
      .concat(algCode)
      .concat('/liveness/')
      .concat(livenessCode)));
  }
}

export default BiometricLivenessService;
