import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class CredClientService extends BaseService {
  static async fetchAll({ tenantCode }) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/client?tenantCode=')
      .concat(tenantCode)));
  }

  static async getByClientId(clientId) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/client?clientId=')
      .concat(encodeURIComponent(clientId))));
  }

  static async fetchRoles({ uuid }) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/client/')
      .concat(uuid)
      .concat('/role')));
  }

  static async newClient(client) {
    return await super.perform(axios.post(ServiceUrlProvider.userManagerUrl()
      .concat('/client/'), client));
  }

  static async updateClient(client) {
    return await super.perform(axios.put(ServiceUrlProvider.userManagerUrl()
      .concat('/client/')
      .concat(client.uuid), client));
  }

  static async delete(client) {
    return await super.perform(axios.delete(ServiceUrlProvider.userManagerUrl()
      .concat('/client/')
      .concat(client.uuid), client));
  }
}

export default CredClientService;
