import moment from 'moment';

class ReportTransform {
  static getStatus(status) {
    return status === 1 ? 'Added' : 'Deleted';
  }

  static getDate(timestamp) {
    return moment.utc(timestamp).format('YYYYMMDD');
  }
}

export default ReportTransform;
