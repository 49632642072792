class Template {
  constructor(template) {
    this.body = template.body || '';
    this.type = template.type || '';
    this.name = template.name || '';
    this.algorithmOperation = template.algorithmOperation || '';
    this.headerText = template.headerText || '';
    this.bodyText = template.bodyText || '';
    this.alertBackgroundColor = template.alertBackgroundColor || '';
    this.alertTextColor = template.alertTextColor || '';
    this.alertTextboxColor = template.alertTextboxColor || '';
    this.logoUrl = template.logoUrl || '';
    this.logoDisplayType = template.logoDisplayType || '';
    this.backgroundImageUrl = template.backgroundImageUrl || '';
  }

  applyDefaults() {
    this.type = 'native';
    this.headerText = 'An alert has been issued.';
    this.bodyText = 'Please verify your identity.';
    this.alertBackgroundColor = '#4A4A4A';
    this.alertTextColor = '#4A4A4A';
    this.alertTextboxColor = '#FFFFFF';
    this.logoDisplayType = 'application';
  }
}

export default Template;
