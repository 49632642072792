import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';


/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const serverGroupAlgorithms = new BaseStoreModule({ component: 'Biometric', sort: { key: 'code', descending: false } });

const myActions = {
  async associateBiometric({ commit, dispatch, getters }, {
    service,
    serverGroupCode,
    algorithmCode,
    license,
  }) {
    const resp = await service.addAlgorithm(serverGroupCode, algorithmCode, license);
    if (resp.status === 200 || resp.status === 201) {
      commit(types.ADD, resp.data);
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Biometric Added',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
    return resp;
  },
  async disAssociateBiometric({
    state,
    dispatch,
    getters,
    commit,
  }, {
    service,
    serverGroupCode,
    algorithmCode,
  }) {
    const resp = await service.deleteAlgorithm(serverGroupCode, algorithmCode);
    if (resp.status === 204) {
      /* Algorithms are an object inside the server group reponse */
      const index = state.all.findIndex(item => item.algorithm.code === algorithmCode);
      commit(types.DELETE, index);
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Biometric Removed',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
    return resp;
  },
  async deleteAlgorithmFromState({ state, commit }, item) {
    // We need this custom action to accommodate for the
    // fact that algorithm data is under a second level
    // in the server group algorithm data (i.e. item.algorithm).
    const index = state.all.findIndex(alg => item[state.key] === alg.algorithm.code);
    commit(types.DELETE, index);
  },
};

const myGetters = {
  getAlgorithms: state => (
    state.all.map(item => item.algorithm)
  ),
  getAlgorithmLicense: state => (algorithmCode) => {
    for (let i = 0; i < state.all.length; i += 1) {
      if (state.all[i].algorithm.code === algorithmCode) {
        return state.all[i].licenseData;
      }
    }
    return {};
  },
  dbTenantBiometrics: state => state.db,
};

serverGroupAlgorithms.getters = Object.assign(serverGroupAlgorithms.getters, myGetters);
serverGroupAlgorithms.actions = Object.assign(serverGroupAlgorithms.actions, myActions);

export default serverGroupAlgorithms;
