class TenantBiometricLiveness {
  constructor(tenantCode, svrgrpCode, algorithmCode, livenessCode) {
    this.tenantCode = tenantCode;
    this.svrgrpCode = svrgrpCode;
    this.algorithmCode = algorithmCode;
    this.livenessCode = livenessCode;
  }
}

export default TenantBiometricLiveness;
