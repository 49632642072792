class BiometricPayload {
  constructor({
    enrollments,
    biometric,
    files,
    isVerify = false,
    pinValue,
  }) {
    this.enrollments = enrollments;
    this.biometric = biometric;
    this.files = files;
    this.verifyWithSingleBiometric = isVerify;
    this.pinValue = pinValue;

    return this;
  }

  getPayload() {
    const captureTypes = {
      face: () => this.getFace(),
      voice: () => this.getVoice(),
      passphrase: () => this.getVoice(),
      pin: () => this.getPin(),
      palm: () => this.getHtPalm(),
    };
    return (captureTypes[this.biometric.captureType])();
  }

  getFace() {
    return this.setPayload(this.setStringPartSequence());
  }

  getHtPalm() {
    return this.setPayload(this.setStringPartSequence());
  }

  getPin() {
    return this.setPayload(this.setStringPartSequence());
  }

  getVoice() {
    return this.setPayload(this.setStringPartSequence());
  }

  getPalmId() {
    const payload = new FormData();
    payload.append('stringPart', this.setStringPartMultiple());

    [...this.files].forEach((file) => {
      const f = file.slice(0, file.size, 'application/octet-stream');
      payload.append(file.name, f, file.name);
    });

    return payload;
  }

  getBiometricInfo() {
    return {
      bioType: this.biometric.bioType,
      captureSequence: [],
      captureType: this.biometric.captureType,
      enrollId: this.enrollments[0].metadata.enrollData.enrollId,
      fileNames: [],
    };
  }

  getSingleBiometricInfo(file) {
    const bi = this.getBiometricInfo();
    bi.captureSequence.push(this.biometric.captureType);
    bi.fileNames.push(file.name);
    return bi;
  }

  setPayload(stringPart) {
    const payload = new FormData();
    payload.append('stringPart', stringPart);

    // Always attach at least one
    payload.append(this.files[0].name, this.files[0], this.files[0].name);

    if (!this.verifyWithSingleBiometric) {
      [...this.files].forEach((file, index) => {
        if (index > 0) {
          payload.append(file.name, file, file.name);
        }
      });
    }

    return payload;
  }

  setStringPartSequence() {
    const stringPartObj = {
      biometricInfo: [this.getBiometricInfo()],
    };

    // Always set one
    stringPartObj.biometricInfo[0].captureSequence.push(this.biometric.captureType);
    stringPartObj.biometricInfo[0].fileNames.push(this.files[0].name);

    if (!this.verifyWithSingleBiometric) {
      [...this.files].forEach((file, index) => {
        if (index > 0) {
          stringPartObj.biometricInfo[0].captureSequence.push(this.biometric.captureType);
          stringPartObj.biometricInfo[0].fileNames.push(file.name);
        }
      });
    }
    return JSON.stringify(stringPartObj);
  }

  setStringPartMultiple() {
    const biometricInfo = [];

    [...this.files].forEach((file) => {
      biometricInfo.push(this.getSingleBiometricInfo(file));
    });

    const stringPartObj = {
      biometricInfo,
    };

    return JSON.stringify(stringPartObj);
  }
}

export default BiometricPayload;
