import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class LivenessService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/liveness')));
  }

  static async fetch(code) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/liveness/').concat(code)));
  }

  static async save(liveness) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl().concat('/liveness/').concat(liveness.code), liveness));
  }

  static async delete(liveness) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl().concat('/liveness/').concat(liveness.code)));
  }
}

export default LivenessService;
