import * as types from '@/store/mutation-types';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

class AlertMessageStore {
  constructor() {
    this.namespaced = true;

    this.state = {
      alertObject: {},
      apiResponse: '',
    };

    this.getters = {
      getAlertMessage: state => state.alertObject,
      getApiResponse: state => state.apiResponse,
    };

    this.actions = {
      setAlertMessage({ commit }, alertObject) {
        commit(types.SET_ALERT_MESSAGE, alertObject);
      },
      setApiResponse({ commit }, { resp, component }) {
        if (component) {
        /* eslint no-param-reassign: 0 */
          resp.component = component;
        }
        commit(types.SET_API_RESPONSE, resp);
      },
      clearStore() {},
    };

    this.mutations = {
      [types.SET_ALERT_MESSAGE](state, obj) {
        state.alertObject = Object.assign({}, obj);
      },
      [types.SET_API_RESPONSE](state, response) {
        state.apiResponse = response;
      },
    };
  }
}

export default AlertMessageStore;
