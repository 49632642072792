<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        showSave
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="captureTypeSelect"
                  v-bind="fields.captureType"
                  item-value="value"
                  item-text="text"
                  v-model="biometricModel.captureType"
                  @input="captureTypeUpdated"
                  :disabled="!isNew"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.code"
                  v-model="biometricModel.code" :disabled="!isNew" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.bioType"
                  v-model="biometricModel.bioType"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.scoreThreshold"
                  v-model="biometricModel.scoreThreshold"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.templateType"
                  v-model="biometricModel.templateType"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.zscoreThreshold"
                  v-model="biometricModel.zscoreThreshold"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="enrollSelect"
                  v-bind="fields.enrollType"
                  item-value="value"
                  item-text="text"
                  v-model="biometricModel.enrollType"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="existingEnrollmentSelect"
                  v-bind="fields.enrollAction"
                  item-value="value"
                  item-text="text"
                  v-model="biometricModel.enrollAction"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.enrollSamples"
                  v-model="biometricModel.enrollSamplesCount"
                  :disabled="noEnrollmentType"/>
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.verifySamples"
                  v-model="biometricModel.verifySamplesCount"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.fusionWeight"
                  v-model="biometricModel.fusionWeight"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.vendor"
                  v-model="biometricModel.vendor"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.version"
                  v-model="biometricModel.version"
                  :disabled="noEnrollmentType"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <ServerBiometricsTable
          :biometrics="allBiometrics"
          @rowClick="setBiometric"
          @refresh="refresh"
        />
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteBiometricModal"
      componentName="Biometric Algorithm"
      @deleteModalConfirm="deleteBiometricConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/biometrics';
import biometricService from '@/services/BiometricService';
import BiometricToPlain from '@/transform/BiometricToPlain';
import Biometric from '@/models/Biometric';
import State from '@/models/State';

import _ from 'lodash';

export default {
  name: 'ServerBiometricsEdit',
  data: () => ({
    biometricModel: new Biometric({}),
    biometricModelBase: new State({}),
    biometricModelState: new State({}),
    deleteBiometricModal: false,
    fields,
    isNew: false,
    noEnrollmentType: false,
    noEnrollmentFields: [
      'zscoreThreshold',
      'vendor',
      'scoreThreshold',
      'enrollSamples',
      'templateType',
      'version',
      'enrollType',
      'enrollAction',
      'verifySamples',
    ],
    captureTypeSelect: [
      {
        value: 'face',
        text: 'Face',
      },
      {
        value: 'passphrase',
        text: 'Voice',
      },
      {
        value: 'palm',
        text: 'Palm',
      },
      {
        value: 'pin',
        text: 'Pin',
      },
      {
        value: 'yesno',
        text: 'Yes/No',
      },
      {
        value: 'device',
        text: 'Device',
      },
    ],
    enrollSelect: [
      {
        value: 'ENROLL_TYPE_SEPARATE',
        text: 'ENROLL_TYPE_SEPARATE',
      },
      {
        value: 'ENROLL_TYPE_COMBINED',
        text: 'ENROLL_TYPE_COMBINED',
      },
    ],
    existingEnrollmentSelect: [
      {
        value: 'ENROLL_ACTION_KEEP_ALL_TEMPLATES',
        text: 'Keep and add new',
      },
      {
        value: 'ENROLL_ACTION_DELETE_OLD_TEMPLATES',
        text: 'Remove and replace',
      },
    ],
  }),
  computed: {
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
      firstBiometric: 'getFirst',
    }),
    canDelete() {
      return !!(this.biometricModel.code && !this.isNew);
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    ServerBiometricsEdit: () => import('@/components/workspace/server/ServerBiometricsEdit'),
    ServerBiometricsTable: () => import('@/components/workspace/server/ServerBiometricsTable'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
      deleteBiometric: 'delete',
      saveBiometric: 'save',
    }),
    ...mapActions('app', {
      setStickyTenantDisabled: 'setStickyTenantDisabled',
    }),
    setBiometric(biometric) {
      this.biometricModel = new BiometricToPlain(biometric);
      this.biometricModelBase = new BiometricToPlain(biometric);
      this.biometricModelState = new State({
        original: biometric,
      });
      this.setUnsaved(false);
      this.isNew = false;
      // We need to call this because of the various field changes
      // that happen for captureType values of device and yesno.
      this.captureTypeUpdated(this.biometricModel.captureType);
    },
    cancelClick() {
      this.setBiometric(this.biometricModelState.original);
    },
    newClick() {
      this.setBiometric({});
      this.biometricModelState = new State({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteBiometricModal = !this.deleteBiometricModal;
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        this.biometricModel.enrollSamples = [];
        this.biometricModel.verifySamples = [];

        // Because of existing code in gmi, even though the valid value for
        // captureType for voice algorithm is 'passphrase', the required value
        // for enrollSamples and verifySamples is 'voice', so we have to convert it.
        const captureType = this.biometricModel.captureType === 'passphrase'
          ? 'voice' : this.biometricModel.captureType;

        for (let i = 0; i < this.biometricModel.enrollSamplesCount; i += 1) {
          this.biometricModel.enrollSamples.push(captureType);
        }
        for (let i = 0; i < this.biometricModel.verifySamplesCount; i += 1) {
          this.biometricModel.verifySamples.push(captureType);
        }

        delete this.biometricModel.enrollSamplesCount;
        delete this.biometricModel.verifySamplesCount;

        // For captureType values of 'device' or 'yesno', we need to
        // also sent the enrollVerifyType field with a value of
        // ENROLL_VERIFY_TYPE_CLIENT. Since the user can't change it,
        // and really doesn't need to see it, we add it here instead of
        // adding a form field.
        if (this.biometricModel.captureType === 'device'
          || this.biometricModel.captureType === 'yesno') {
          this.biometricModel.enrollVerifyType = 'ENROLL_VERIFY_TYPE_CLIENT';
        }

        await this.saveBiometric({
          service: biometricService,
          item: this.biometricModel,
        });
        this.isNew = false;
        this.setBiometric(this.biometricModel);
        this.setUnsaved(false);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async deleteBiometricConfirm(confirm) {
      if (confirm) {
        await this.deleteBiometric({
          service: biometricService,
          item: this.biometricModel,
        });
        this.biometricModel = new Biometric({});
      }
      this.deleteBiometricModal = false;
    },
    async setComponent() {
      this.setTabs('Server Configuration', 'tab-algs');
      this.setStickyTenantDisabled(true);
      await this.fetchAllBiometrics({ service: biometricService, Transform: BiometricToPlain, key: 'code' });
      this.setBiometric(this.firstBiometric);
    },
    captureTypeUpdated(val) {
      if (val === 'yesno' || val === 'device') {
        const vm = this;
        // Set the following fields as blank.
        this.noEnrollmentFields.forEach((field) => {
          vm.biometricModel[field] = '';
          vm.fields[field].rules = [];
        });
        this.noEnrollmentType = true;
      } else {
        this.noEnrollmentType = false;
      }
    },
  },
  watch: {
    biometricModel: {
      deep: true,
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.biometricModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
