import moment from 'moment';
import BiometricMap from '@/utils/BiometricMap';
import UserDocument from '@/models/UserDocument';

/* Transform class to munge ugly server data */
class DocumentsToPlain {
  constructor(docs) {
    this.enrollSuccess = [];
    this.enrollFailure = [];
    this.verifySuccess = [];
    this.verifyFailure = [];

    for (let j = 0; j < docs.length; j += 1) {
      const bioMap = DocumentsToPlain.getBioMap(docs[j]);

      if (docs[j].enrollSuccess
        && docs[j].enrollSuccess.length > 0) {
        this.enrollSuccess = this.enrollSuccess
          .concat(DocumentsToPlain.setDocumentArr(docs[j].enrollSuccess, bioMap));
      }

      if (docs[j].enrollFailure
        && docs[j].enrollFailure.length > 0) {
        this.enrollFailure = this.enrollFailure
          .concat(DocumentsToPlain.setDocumentArr(docs[j].enrollFailure, bioMap));
      }

      if (docs[j].verifySuccess
        && docs[j].verifySuccess.length > 0) {
        this.verifySuccess = this.verifySuccess
          .concat(DocumentsToPlain.setDocumentArr(docs[j].verifySuccess, bioMap));
      }

      if (docs[j].verifyFailure
        && docs[j].verifyFailure.length > 0) {
        this.verifyFailure = this.verifyFailure
          .concat(DocumentsToPlain.setDocumentArr(docs[j].verifyFailure, bioMap));
      }
    }
  }

  static getBioMap(document) {
    return BiometricMap.get(document.captureType);
  }

  static getTransaction(enrollment) {
    const transJson = enrollment.metadata.find(md => md.name === 'TRANSACTION_ID');
    return transJson.stringValue;
  }

  static setDocument(document, bioMap, transactionUuid) {
    const userDoc = new UserDocument(document);
    userDoc.type = bioMap.type;
    userDoc.friendly = bioMap.friendly;
    userDoc.icon = bioMap.icon;
    userDoc.created = moment(document.created).format('MM/DD/YYYY HH:mm:ss');
    userDoc.guid = document.docGuid;
    userDoc.bioType = document.type;
    userDoc.transactionUuid = transactionUuid;
    return userDoc;
  }

  static setDocumentArr(documents, bioMap) {
    let iLen = 0;
    iLen = documents.length;
    const documentMap = new Map();
    let tmpArr = [];

    for (let i = 0; i < iLen; i += 1) {
      const tmpDoc = documents[i];
      const transactionUuid = DocumentsToPlain.getTransaction(tmpDoc);
      const userDoc = DocumentsToPlain.setDocument(tmpDoc, bioMap, transactionUuid);
      userDoc.status = 'Enrolled';

      // If this transaction isn't mapped yet. Reset the array
      if (!documentMap.get(transactionUuid)) {
        tmpArr = [];
      }
      tmpArr.push(userDoc);
      documentMap.set(transactionUuid, tmpArr);
    }
    const docArr = [];
    documentMap.forEach(value => docArr.push(value));
    return docArr;
  }

  getEnrollSuccess() {
    return this.enrollSuccess;
  }

  getEnrollFailure() {
    return this.enrollFailure;
  }

  getVerifySuccess() {
    return this.verifySuccess;
  }

  getVerifyFailure() {
    return this.verifyFailure;
  }
}

export default DocumentsToPlain;
