const headers = [
  {
    text: 'Device Id',
    value: 'deviceId',
  },
  {
    text: 'Platform',
    value: 'platform',
  },
  {
    text: 'Platform Version',
    value: 'platformVersion',
  },
];

export default headers;
