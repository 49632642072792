<template>
  <div>
    <!-- Tabs -->
    <UserTabs
      class="ap-tabs container fluid"
    />

    <router-view name="users" />

    <AlertSendDialog
      :user="getUserModel"
      :tenantCode="getStickyTenant"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TenantUser from '@/models/TenantUser';

export default {
  name: 'WorkspaceUsers',
  computed: {
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('alertMessageDialog', {
      quickAlertGlobal: 'getIsGlobal',
    }),
    ...mapGetters('users', ['getCurrent']),
    getUserModel() {
      return this.quickAlertGlobal ? new TenantUser({}) : this.getCurrent;
    },
  },
  components: {
    AlertSendDialog: () => import('@/components/dialogs/AlertSendDialog'),
    UserTabs: () => import('@/components/workspace/users/UserTabs'),
  },
  methods: {
    ...mapActions('users', {
      setCurrentUser: 'setCurrent',
    }),
    ...mapActions('tabs', {
      setSelectedTab: 'setSelected',
    }),
  },
  mounted() {
    this.setCurrentUser({});
  },
};
</script>
