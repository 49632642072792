class Tenant {
  constructor(tenant) {
    this.code = tenant.code || '';
    this.name = tenant.name || '';
    this.defaultPostBack = tenant.defaultPostBack || '';
    this.maxIdentities = tenant.maxIdentities || '';
  }
}

export default Tenant;
