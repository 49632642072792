import BiometricMap from '@/utils/BiometricMap';

class DocumentMap {
  static map(documents) {
    const arr = [];
    documents.forEach((type) => {
      type.forEach((doc) => {
        const document = Object.assign({}, doc[0]);
        document.files = doc.map(file => ({
          guid: file.guid,
          created: file.created,
          type: file.type,
          bioType: file.bioType,
          isImage: BiometricMap.isImage(file.type),
        }));
        arr.push(document);
      });
    });
    return arr;
  }
}

export default DocumentMap;
