const csvFields = [
  {
    label: 'USER_NAME',
    value: 'userId',
  },
  {
    label: 'ID',
    value: 'id',
  },
];

export default csvFields;
