const headers = [
  {
    text: 'Group Code',
    value: 'code',
  },
  {
    text: 'Enroll Server Address',
    value: 'enrollServerAddr',
  },
  {
    text: 'Verify Server Address',
    value: 'verifyServerAddr',
  },
];

export default headers;
