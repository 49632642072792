const headers = [
  {
    text: 'Name',
    value: 'code',
  },
  {
    text: 'Capture Type',
    value: 'captureType',
  },
  {
    text: 'Threshold',
    value: 'scoreThreshold',
    format: (biometric, index, createElement) => {
      const retVal = typeof biometric.threshold === 'number' ? biometric.threshold : biometric.scoreThreshold;
      return createElement('div', retVal);
    },
  },
  {
    text: 'Liveness Options',
    value: 'liveness',
    /* eslint consistent-return: "off" */
    format: (liveness, index, createElement) => {
      if (liveness.livenessChecks.length > 0) {
        const livenessList = liveness.livenessChecks.map(check => check.code);
        return createElement('div', livenessList.join(', '));
      }
    },
  },
];

export default headers;
