import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  uuid: {
    label: 'UUID',
  },
  clientId: {
    label: 'Name',
    rules: new RuleGenerator('Name')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .setRegEx(/^[\d\w '{}|!#$%&^*/=?`~@._+-]+$/)
      .getRules(),
  },
  clientSecret: {
    label: 'Secret',
    rules: new RuleGenerator('Secret')
      .setRequired()
      .setMin(8)
      .setMax(20)
      .setRegEx(/^[a-zA-Z0-9'{}|!#$%&^*/=?`~@._+-]{1,255}$/)
      .getRules(),
    counter: '20',
  },
  clientSecretConfirm: {
    label: 'Confirm Secret',
    rules: new RuleGenerator('Secret confirmation')
      .setRequired()
      .setMin(8)
      .setMax(20)
      .setRegEx(/^[a-zA-Z0-9'{}|!#$%&^*/=?`~@._+-]{1,255}$/)
      .getRules(),
    counter: '20',
  },
  apRole: {
    label: 'Admin Portal Role',
  },
  umRole: {
    label: 'User Mananger Role',
  },
};

export default fields;
