class Application {
  constructor(app) {
    this.alertSound = app.alertSound || 'default';
    this.alertText = app.alertText || '';
    this.apnsCertificate = app.apnsCertificate;
    this.apnsCertificatePassword = app.apnsCertificatePassword;
    this.apnsCertificateTest = app.apnsCertificateTest;
    this.code = app.code;
    this.environment = app.environment;
    this.firebaseKey = app.firebaseKey;
    this.name = app.name;
    this.validationFailInfo = app.validationFailInfo;
    this.validationFromAlias = app.validationFromAlias;
    this.validationInfo = app.validationInfo;
    this.validationSuccessInfo = app.validationSuccessInfo;
    this.validationType = app.validationType;
  }
}

export default Application;
