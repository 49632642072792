import * as types from '@/store/mutation-types';
import tenantService from '@/services/TenantService';
import applicationService from '@/services/ApplicationService';
import tenantUserSerivce from '@/services/TenantUserService';
import userEnrollmentService from '@/services/UserEnrollmentService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/
/* eslint no-empty-pattern: 0 */

class Response {
  constructor(type, label, action, responseTime, key, iteration) {
    this.type = type;
    this.label = label;
    this.action = action;
    this.responseTime = responseTime;
    this.key = key;
    this.iteration = iteration;
  }
}

class PerformanceGetters {
  constructor() {
    return {
      getAllResponses: state => state.allResponses,
      getEnrollResponseTimes: state => state.enrollResponses,
      getEnrollResponseTimesLength: state => state.enrollResponses.length,
      getVerifyResponseTimes: state => state.verifyResponses,
      getVerifyResponseTimesLength: state => state.verifyResponses.length,
      getVerifyResponseTimesByIteration: state => iteration => (
        state.allResponses
          .filter(resp => (resp.type === 'verify' && resp.iteration === iteration))
          .map(resp => resp.responseTime)
      ),
      createTenantResponseTimes: state => state.createTenantResponses,
      createApplicationResponseTimes: state => state.createApplicationResponses,
      associateApplicationResponsesTimes: state => state.associateApplicationResponses,
      associateBiometricResponsesResponsesTimes:
        state => state.associateBiometricResponses,
      createUserResponsesTimes: state => state.createUserResponses,
      pendingEnrollmentsResponseTimes: state => state.pendingEnrollmentsResponses,
      deleteUserResponsesTimes: state => state.deleteUserResponses,
      deleteApplicationTimes: state => state.deleteApplicationResponses,
      deleteTenantTimes: state => state.deleteTenantResponses,
      meanEnroll: state => (state.enrollResponses
        ? Math.round(state.enrollResponses.reduce((a, b) => (a + b), 0)
          / state.enrollResponses.length) : 0),
      meanVerify: state => (state.verifyResponses
        ? Math.round(state.verifyResponses.reduce((a, b) => (a + b), 0)
          / state.verifyResponses.length) : 0),
      minEnroll: state => Math.min(...state.enrollResponses),
      minVerify: state => Math.min(...state.verifyResponses),
      maxEnroll: state => Math.max(...state.enrollResponses),
      maxVerify: state => Math.max(...state.verifyResponses),
      sumEnroll: state => (state.enrollResponses.length
        ? state.enrollResponses.reduce((a, b) => a + b) : 0),
      sumVerify: state => (state.verifyResponses.length
        ? state.verifyResponses.reduce((a, b) => a + b) : 0),
    };
  }
}

class PerformanceActions {
  constructor() {
    return {
      async createTenant({ commit }, { tenant }) {
        const resp = await tenantService.save(tenant);
        const r = new Response('tenant', 'Create Tenant', 'create', resp.duration, tenant.code);
        commit(types.PERF_CREATE_TENANT_RESPONSE, r);
      },
      async deleteTenant({ commit }, { tenant }) {
        const resp = await tenantService.delete(tenant);
        const r = new Response('tenant', 'Delete Tenant', 'delete', resp.duration, tenant.code);
        commit(types.PERF_DELETE_TENANT_RESPONSE, r);
      },

      async createApplication({ commit }, { application }) {
        const resp = await applicationService.save(application);
        const r = new Response('application', 'Create Application', 'create', resp.duration, application.code);
        commit(types.PERF_CREATE_APPLICATION_RESPONSE, r);
      },
      async deleteApplication({ commit }, { application }) {
        const resp = await applicationService.delete(application);
        const r = new Response('application', 'Delete Application', 'delete', resp.duration, application.code);
        commit(types.PERF_DELETE_APPLICATION_RESPONSE, r);
      },

      async associateBiometric({ commit }, { tenant, serverGroupCode, biometricCode }) {
        const resp = await tenantService.associateTenantBiometricAlgorithm(
          tenant.code,
          serverGroupCode,
          biometricCode,
          '',
        );
        const key = tenant.code.concat('->').concat(serverGroupCode).concat('->').concat(biometricCode);
        const r = new Response('biometric', 'Associate Biometeric', 'associate', resp.duration, key);
        commit(types.PERF_ASSOCIATE_BIOMETRIC_RESPONSE, r);
      },

      async associateApp({ commit }, { tenant, application }) {
        const resp = await tenantService.associateApplication(
          tenant.code,
          application.code,
        );
        const key = tenant.code.concat('->').concat(application.code);
        const r = new Response('application', 'Associate Application', 'associate', resp.duration, key);
        commit(types.PERF_ASSOCIATE_APPLICATION_RESPONSE, r);
      },

      async createUser({ commit }, { tenant, user }) {
        const resp = await tenantUserSerivce.save({
          user,
          tenantCode: tenant.code,
          skipUserCheck: true,
        });
        const r = new Response('user', 'Create User', 'create', resp.duration, user.userId);
        commit(types.PERF_CREATE_USER_RESPONSE, r);
        return resp.data;
      },

      async deleteUser({ commit }, { user, tenantCode }) {
        const resp = await tenantUserSerivce.delete({ user, tenantCode });
        const r = new Response('user', 'Delete User', 'delete', resp.duration, user.userId);
        commit(types.PERF_DELETE_USER_RESPONSE, r);
      },

      async fetchPendingEnrollment({ commit }, {
        application,
        user,
      }) {
        const resp = await userEnrollmentService.fetchPendingEnrollments({
          appCode: application.code,
          id: user.id,
        });
        const key = application.code.concat('->').concat(user.id);
        const r = new Response('enrollment', 'Fetch Pending Enrollment', 'get', resp.duration, key);
        commit(types.PERF_FETCH_PENDING_ENROLL_RESPONSE, r);
        return resp.data;
      },

      async enroll({ commit }, {
        tenant,
        user,
        application,
        payload,
      }) {
        const resp = await userEnrollmentService.enroll({
          tenantCode: tenant.code,
          id: user.id,
          appCode: application.code,
          payload,
        });
        const key = tenant.code.concat('->').concat(user.id).concat('->').concat(application.code);
        const r = new Response('enroll', 'Enroll Person', 'create', resp.duration, key);
        commit(types.PERF_ADD_ENROLL_RESPONSE, r);
      },

      async verify({ commit }, {
        tenant,
        user,
        application,
        payload,
        iteration,
      }) {
        const resp = await userEnrollmentService.verify({
          tenantCode: tenant.code,
          id: user.id,
          appCode: application.code,
          payload,
        });
        const key = tenant.code.concat('->').concat(user.id).concat('->').concat(application.code);
        const r = new Response('verify', 'Verify Person', 'create', resp.duration, key, iteration);
        commit(types.PERF_ADD_VERIFY_RESPONSE, r);
      },

      clearStore({ commit }) {
        commit(types.PERF_CLEAR_ALL);
      },
    };
  }
}

class PerformanceMutations {
  constructor() {
    return {
      [types.PERF_ADD_RESPONSE](state, resp) {
        state.allResponses.push(resp);
      },
      [types.PERF_ADD_ENROLL_RESPONSE](state, resp) {
        state.enrollResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_ADD_VERIFY_RESPONSE](state, resp) {
        state.verifyResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_CREATE_TENANT_RESPONSE](state, resp) {
        state.createApplicationResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_CREATE_APPLICATION_RESPONSE](state, resp) {
        state.createTenantResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_ASSOCIATE_BIOMETRIC_RESPONSE](state, resp) {
        state.associateBiometricResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_ASSOCIATE_APPLICATION_RESPONSE](state, resp) {
        state.associateApplicationResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_CREATE_USER_RESPONSE](state, resp) {
        state.createUserResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_FETCH_PENDING_ENROLL_RESPONSE](state, resp) {
        state.pendingEnrollmentsResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_DELETE_TENANT_RESPONSE](state, resp) {
        state.deleteTenantResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_DELETE_APPLICATION_RESPONSE](state, resp) {
        state.deleteApplicationResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_DELETE_USER_RESPONSE](state, resp) {
        state.deleteUserResponses.push(resp.responseTime);
        state.allResponses.push(resp);
      },
      [types.PERF_CLEAR_ALL](state) {
        const keys = Object.keys(state);
        for (let i = 0; i < keys.length; i += 1) {
          state[keys[i]] = [];
        }
      },
    };
  }
}

class PerformanceStore {
  constructor() {
    this.namespaced = true;

    this.state = {
      allResponses: [],
      enrollResponses: [],
      verifyResponses: [],
      createTenantResponses: [],
      createApplicationResponses: [],
      associateApplicationResponses: [],
      associateBiometricResponses: [],
      createUserResponses: [],
      pendingEnrollmentsResponses: [],
      deleteUserResponses: [],
      deleteApplicationResponses: [],
      deleteTenantResponses: [],
    };

    this.getters = new PerformanceGetters();

    this.actions = new PerformanceActions();

    this.mutations = new PerformanceMutations();
  }
}

export default PerformanceStore;
