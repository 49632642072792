import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import systemInfoService from '@/services/SystemInfoService';
import SystemInfo from '@/models/SystemInfo';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const systemInfo = new BaseStoreModule({ component: 'System Info' });

const myState = {
  stackInfo: [],
};

const myGetters = {
  getStackInfo: state => state.stackInfo,
};

const myActions = {
  async fetchStackInfo({ commit }) {
    const resp = await systemInfoService.fetchStackInfo();
    const stackInfo = {};
    const serverVars = {
      gmiserver: 'VUE_APP_GMI_SERVER',
      usermanager: 'VUE_APP_USERMANAGER_SERVER',
      documentmanager: 'VUE_APP_DOCMANAGER_SERVER',
    };
    resp.data.servers.forEach((service) => {
      stackInfo[service.name] = new SystemInfo(service);
      const envVarName = serverVars[service.name];
      stackInfo[service.name].url = process.env[envVarName];
    });
    commit(types.SET_STACK_INFO, stackInfo);
  },

  clearStoreCustom({ commit }) {
    commit(types.SET_STACK_INFO, new SystemInfo({}));
  },
};

const myMutations = {
  [types.SET_STACK_INFO](state, stackInfo) {
    state.stackInfo = stackInfo;
  },
};

systemInfo.state = Object.assign(systemInfo.state, myState);
systemInfo.actions = Object.assign(systemInfo.actions, myActions);
systemInfo.getters = Object.assign(systemInfo.getters, myGetters);
systemInfo.mutations = Object.assign(systemInfo.mutations, myMutations);

export default systemInfo;
