import Biometric from '@/models/Biometric';

class TenantBiometricToPlain {
  constructor(server) {
    const biometric = new Biometric(server.algorithm);
    biometric.license = server.licenseData;
    biometric.serverGroup = server.serverGroup.code;
    biometric.threshold = server.threshold;
    return biometric;
  }
}

export default TenantBiometricToPlain;
