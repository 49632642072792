import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const alertMessageDialog = new BaseStoreModule({ component: 'Alert', sort: null });

const myState = {
  sendMessageModal: false,
  isGlobal: false,
  lastTemplateId: '',
};

const myGetters = {
  getSendMessageModal: state => state.sendMessageModal,
  getIsGlobal: state => state.isGlobal,
  getLastTemplate: state => state.lastTemplateId,
};

const myActions = {
  toggleSendMessageModal({ commit }, isGlobal) {
    commit(types.TOGGLE_SEND_MESSAGE_MODAL);
    commit(types.SEND_MESSAGE_MODAL_GLOBAL, isGlobal);
  },
  setLastTemplate({ commit }, templateId) {
    commit(types.SET_LAST_TEMPLATE, templateId);
  },
  clearLastTemplate({ commit }) {
    commit(types.CLEAR_LAST_TEMPLATE);
  },
};

const myMutations = {
  [types.TOGGLE_SEND_MESSAGE_MODAL](state) {
    state.sendMessageModal = !state.sendMessageModal;
  },
  [types.SEND_MESSAGE_MODAL_GLOBAL](state, isGlobal) {
    state.isGlobal = isGlobal;
  },
  [types.SET_LAST_TEMPLATE](state, templateId) {
    state.lastTemplateId = templateId;
  },
  [types.CLEAR_LAST_TEMPLATE](state) {
    state.lastTemplateId = '';
  },
};

alertMessageDialog.state = Object.assign(alertMessageDialog.state, myState);
alertMessageDialog.actions = Object.assign(alertMessageDialog.actions, myActions);
alertMessageDialog.getters = Object.assign(alertMessageDialog.getters, myGetters);
alertMessageDialog.mutations = Object.assign(alertMessageDialog.mutations, myMutations);

export default alertMessageDialog;
