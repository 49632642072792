import { mapActions, mapGetters } from 'vuex';
import Role from '@/models/Role';
import RoleUtil from '@/utils/RoleUtil';
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';

const oAuthMixin = {
  data: () => ({
    pass1: true,
    pass2: true,
    defaultUserApItems: [
      { value: 'ROLE_TENANT_ADMIN', text: 'Tenant User Admin', disabled: false },
      { value: 'ROLE_TENANT_MAINT', text: 'Tenant Maintainer', disabled: false },
      { value: 'ROLE_SUPER_ADMIN', text: 'Super Admin', disabled: false },
      { value: null, text: 'None/User Manager Only', disabled: false },
    ],
    defaultApItems: [
      { value: null, text: 'None', disabled: false },
      { value: 'ROLE_GMI_USER', text: 'Mobile App', disabled: false },
      { value: 'ROLE_TENANT_ADMIN', text: 'Select Tenant Access', disabled: false },
      { value: 'ROLE_TENANT_MAINT', text: 'All Tenant Access', disabled: false },
      { value: 'ROLE_SUPER_ADMIN', text: 'Super Admin', disabled: false },
    ],
    defaultOauthUmItems: [
      { value: 'ROLE_TENANT_ADMIN', text: 'Tenant User Admin', disabled: false },
      { value: 'ROLE_TENANT_MAINT', text: 'Tenant Maintainer', disabled: false },
      { value: 'ROLE_SUPER_ADMIN', text: 'Super Admin', disabled: false },
      { value: null, text: 'None/AP Access Only', disabled: false },
    ],
    defaultClientUmItems: [
      { value: null, text: 'None/Mobile App', disabled: false },
      { value: 'ROLE_TENANT_ADMIN', text: 'Tenant User Admin', disabled: false },
      { value: 'ROLE_TENANT_MAINT', text: 'Tenant Maintainer', disabled: false },
      { value: 'ROLE_SUPER_ADMIN', text: 'Super Admin', disabled: false },
    ],
    selectedGmiRole: new Role({}),
    selectedUmRole: new Role({}),
    myConfirmPass: null,
  }),
  computed: {
    ...mapGetters('app', ['getTopRole']),
    hasSavePermission() {
      if (RoleUtil.isSuperAdmin(this.getUmRole.roleCode)) {
        // || RoleUtil.isTenantMaintainer(this.getUmRole.roleCode)) {
        return true;
      }
      return this.canUpdateGmiRole || this.canUpdateUmRole;
    },
    canUpdateGmiRole() {
      if (RoleUtil.isSuperAdmin(this.getTopRole.roleCode)) {
        return true;
      }
      return RoleUtil.roleValue(this.getTopRole.roleCode)
        > RoleUtil.roleValue(this.selectedGmiRole.roleCode);
    },
    canUpdateUmRole() {
      if (RoleUtil.isSuperAdmin(this.getUmRole.roleCode)) {
        return true;
      }
      return RoleUtil.roleValue(this.getUmRole.roleCode)
        > RoleUtil.roleValue(this.selectedUmRole.roleCode);
    },
    apUserItems() {
      for (let i = 0; i < this.defaultUserApItems.length; i += 1) {
        if (RoleUtil.roleValue(this.defaultUserApItems[i].value)
          >= RoleUtil.roleValue(this.getTopRole.roleCode)
          && !(RoleUtil.isSuperAdmin(this.getTopRole.roleCode))) {
          this.defaultUserApItems[i].disabled = true;
        }
      }
      return this.defaultUserApItems;
    },
    apItems() {
      for (let i = 0; i < this.defaultApItems.length; i += 1) {
        if (RoleUtil.roleValue(this.defaultApItems[i].value)
          >= RoleUtil.roleValue(this.getTopRole.roleCode)
          && !(RoleUtil.isSuperAdmin(this.getTopRole.roleCode))) {
          this.defaultApItems[i].disabled = true;
        }
      }
      return this.defaultApItems;
    },
    umOauthItems() {
      for (let i = 0; i < this.defaultOauthUmItems.length; i += 1) {
        if (RoleUtil.roleValue(this.defaultOauthUmItems[i].value)
          >= RoleUtil.roleValue(this.getTopRole.roleCode)
          && !(RoleUtil.isSuperAdmin(this.getTopRole.roleCode))) {
          this.defaultOauthUmItems[i].disabled = true;
        }
      }
      return this.defaultOauthUmItems;
    },
    umClientItems() {
      for (let i = 0; i < this.defaultClientUmItems.length; i += 1) {
        if (RoleUtil.roleValue(this.defaultClientUmItems[i].value)
          >= RoleUtil.roleValue(this.getTopRole.roleCode)
          && !(RoleUtil.isSuperAdmin(this.getTopRole.roleCode))) {
          this.defaultClientUmItems[i].disabled = true;
        }
      }
      return this.defaultClientUmItems;
    },
  },
  methods: {
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    setGmiRole(gmiRole, tenantCode) {
      if (typeof gmiRole === 'undefined') {
        this.selectedGmiRole = new Role({
          tenantCode,
          securedResourceCode: 'gmiserver',
        });
      } else {
        this.selectedGmiRole = new Role(gmiRole);
      }
    },
    setUmRole(umRole, tenantCode) {
      if (typeof umRole === 'undefined') {
        this.selectedUmRole = new Role({
          tenantCode,
          securedResourceCode: 'usermanager',
        });
      } else {
        this.selectedUmRole = new Role(umRole);
      }
    },
    resetRoles() {
      this.selectedGmiRole = new Role({});
      this.selectedUmRole = new Role({});
    },
    rolesValid() {
      if (!this.selectedGmiRole.roleCode
        && !this.selectedUmRole.roleCode) {
        return false;
      }
      return true;
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        if (!this.rolesValid()) {
          this.setAlertMessage({
            type: 'error',
            message: 'You must choose a role for either Admin Portal or User Manager',
          });
          return false;
        }

        if (!this.validPassword()) {
          this.setAlertMessage({
            type: 'error',
            message: 'Your secrets do not match',
          });
        } else {
          this.customSave();
        }
      } else {
        this.showValidateFailModal = true;
      }
      return false;
    },
  },
  mixins: [baseEditMixin],
};

export default oAuthMixin;
