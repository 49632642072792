const buttons = {
  saveAsDefaultButton: {
    text: 'Save As Default Appearance',
  },
  applyDefaultsButton: {
    text: 'Apply Default',
  },
};

export default buttons;
