<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        :showSave="canSave"
        showDownload
        showUpload
        showDelete
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
        @dialUpload="uploadClick"
        @dialDownload="downloadClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
                <v-flex xs6>
                  <v-text-field v-bind="fields.userId" v-model="userModel.userId"
                    :disabled="!canSave"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.id" v-model="userModel.id" disabled />
                </v-flex>
            </v-layout>
            <BaseUploadModal
              declineText="Cancel"
              :title="uploadTitle"
              :text="uploadText"
              :open="openUploadDialog"
              @uploadModalClicked="uploadClicked"
              @userUploadComplete="openUploadDialog = false"
            />
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <UserTable
          @rowClick="userRowClick"
          @refresh="refresh"
          @viewAlerts="viewAlerts"
          :users="allUsers"
        />
      </div>
    </Section>

    <BaseConfirmModal
      :value="userExists"
      title="Username Already Exists"
      text="Please try again with a different username."
      confirmText="Continue"
      @clicked="userExistsConfirm"
    />

    <BaseDeleteModal
      :open="deleteUserModal"
      componentName="User"
      @deleteModalConfirm="deleteUserModalConfirm"
    />

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';

import { fields, buttons, csvFields } from '@/components/config/users';

import tenantUserService from '@/services/TenantUserService';
import tenantAlertService from '@/services/TenantAlertService';

import AlertToPlain from '@/transform/AlertToPlain';

import TenantUser from '@/models/TenantUser';
import State from '@/models/State';

import FileSaver from 'file-saver';
import * as Json2csv from 'json2csv';

export default {
  name: 'UserEdit',
  data: () => ({
    fields,
    ...buttons,
    ...csvFields,
    userModel: new TenantUser({}),
    userState: new State({}),
    deleteUserModal: false,
    isNew: false,
    openUploadDialog: false,
    uploadTitle: 'Upload File',
    uploadText: 'Select a .csv file to upload',
    userExists: false,
  }),
  computed: {
    ...mapGetters('users', ['getAll', 'getByKey', 'loaded', 'getCurrent']),
    ...mapGetters('app', ['getStickyTenant']),
    canSave() {
      return this.isNew;
    },
    allUsers() {
      // This is a hack to accommodate for the fact that the PUT that adds
      // a user to the new tenant returns no data, which means there's nothing
      // to add to state, so there is nothing for that new user in getAll. At this
      // point it's available in this.userModel.
      const vm = this;
      const newItems = this.getAll.map((item) => {
        let newUser = {};
        if (typeof item !== 'object') {
          newUser = {
            id: vm.userModel.id,
            userId: vm.userModel.userId,
            data: null,
            tenantData: null,
          };
        } else {
          newUser = item;
        }
        return newUser;
      });
      return newItems;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    UserTable: () => import('@/components/workspace/users/UserTable'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BaseUploadModal: () => import('@/components/base/BaseUploadModal'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('users', {
      fetchUsers: 'fetchAllBy',
      saveUser: 'save',
      deleteUser: 'deleteUser',
      setCurrentUser: 'setCurrent',
      setUploadProgress: 'setUploadProgress',
      addOneSorted: 'addOneSorted',
    }),
    ...mapActions('userAlerts', {
      fetchAlerts: 'fetchAllBy',
    }),
    cancelClick() {
      this.setModel(this.userState.original);
    },
    newClick() {
      this.resetUser();
      this.isNew = true;
    },
    deleteClick() {
      this.deleteUserModal = !this.deleteUserModal;
    },
    uploadClick() {
      this.setUploadProgress(0);
      this.openUploadDialog = true;
    },
    uploadClicked(val) {
      if (!val) {
        this.openUploadDialog = false;
      }
    },
    downloadClick() {
      const parser = new Json2csv.Parser({ fields: csvFields });

      const csv = parser.parse(this.getAll);
      const fileName = `${this.getStickyTenant}_users.csv`;

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
    },
    resetUser() {
      this.userModel = new TenantUser({});
      this.userState = new State({});
      this.setCurrentUser(this.userModel);
    },
    setModel(user) {
      if (user.id) {
        this.userModel = new TenantUser(this.getByKey(user.id));
        this.userState = new State({
          original: this.userModel,
        });
        this.isNew = false;
      }
    },
    async viewAlerts(user) { // Called from table
      await this.userRowClick(user);
      this.$router.push({ name: 'UserAlertEdit' });
    },
    async userRowClick(user) {
      const selectedTenant = this.getStickyTenant;
      this.setCurrentUser(user);

      /* Users */
      if (!this.loaded) {
        await this.fetchUsers({ service: tenantUserService, key: 'id', fetchBy: { selectedTenant } });
      }
      this.setModel(user);

      /* Alerts */
      await this.fetchAlerts({
        service: tenantAlertService,
        key: 'transactionUuid',
        fetchBy: {
          tenantCode: selectedTenant,
          id: user.id,
        },
        Transform: AlertToPlain,
      });
    },
    async deleteUserModalConfirm(confirm) {
      if (confirm) {
        await this.deleteUser({
          user: this.userModel,
          tenantCode: this.getStickyTenant,
        });
        this.resetUser();
      }
      this.deleteUserModal = false;
    },
    async getUsers(tenantCode) {
      if (tenantCode) {
        await this.fetchUsers({ service: tenantUserService, key: 'id', fetchBy: { tenantCode } });
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        const existsYN = await this.checkForExistingUser();
        if (this.isNew && !existsYN) {
          const userObj = {
            service: tenantUserService,
            item: {
              tenantCode: this.getStickyTenant,
              user: this.userModel,
            },
          };
          const resp = await this.saveUser(userObj);
          // If this is adding an existing user to a new tenant.
          // there is no data returned from saveUser(), so we don't
          // want to override userModel with blank data.
          if (typeof resp.data === 'object') {
            this.userModel = new TenantUser(resp.data);
          } else {
            // Since there is nothing written to the store for a PUT
            // we have to manually add it here.
            this.addOneSorted(this.userModel);
          }
          this.isNew = false;
        }
      }
    },
    async setComponent(reset) {
      this.setTabs('Users', 'tab-general');
      if (this.getStickyTenant) {
        await this.getUsers(this.getStickyTenant);
      }
      if (this.allUsers.length > 0) {
        this.setModel(this.getCurrent);
      }
    },
    selectClick() {
      this.resetInput();
      const f = this.$refs.fileInput;
      f.click();
    },
    async checkForExistingUser() {
      const userId = encodeURIComponent(this.userModel.userId);
      return tenantUserService.checkExisting(
        userId,
        this.getStickyTenant,
      )
        .then((response) => {
          const returnVal = response.data.length > 0;
          this.userExists = returnVal;
          return returnVal;
        });
    },
    userExistsConfirm() {
      // The only option is to cancel, so we just isNew = false
      // and set userModel.userId to blank.
      this.isNew = false;
      this.userExists = false;
      this.userModel.userId = '';
    },
  },
  watch: {
    getStickyTenant(newVal, oldVal) {
      // This changes when the tenant is changed.
      // so we need to clear out the userModel.
      this.setComponent();
    },
    userModel: {
      handler(val, oldVal) {

      },
      deep: true,
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
