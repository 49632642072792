import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: 'Tenant Code',
    rules: new RuleGenerator('Code')
      .setRequired()
      .setMin(3)
      .setMax(45)
      .setRegEx(/[\w]/)
      .getRules(),
    hint: 'Must contain between 3 and 45 letters and numbers',
    counter: '45',
  },
  name: {
    label: 'Tenant Name',
    rules: new RuleGenerator('Name')
      .setRequired()
      .setMin(1)
      .setMax(255)
      .setRegEx(/^[\d\w .,_-]+$/)
      .getRules(),
    hint: 'Must contain between 1 and 255 letters, numbers, . , - _',
    counter: '255',
  },
  defaultPostBack: {
    label: 'Default PostBack Address',
    rules: new RuleGenerator('Default PostBack Address')
      .setUrl()
      .getRules(),
    hint: 'Must be a valid url',
  },
  maxIdentities: {
    label: 'Maximum Users',
    rules: new RuleGenerator('Maximum Users')
      .setNumber()
      .getRules(),
  },
};

export default fields;
