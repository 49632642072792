import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class VerificationService extends BaseService {
  static async delete(userId) {
    return await super.perform(
      axios.delete(
        ServiceUrlProvider.userManagerUrl().concat('/verification/')
          .concat(encodeURIComponent(userId)),
      ),
    );
  }
}

export default VerificationService;
