import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import tenantBiometricLivenessService from '@/services/TenantBiometricLivenessService';
import biometricLivenessService from '@/services/BiometricLivenessService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const tenantBiometricLiveness = new BaseStoreModule({ component: 'Tenant Biometric Liveness', sort: { key: 'code', descending: false } });

const myState = {
  availableLiveness: [],
};

const myGetters = {
  getAvailableLiveness: state => state.availableLiveness,
};

const myActions = {
  async fetchAllTenantBiometricLiveness({ dispatch }, {
    tenantCode,
    svrgrpCode,
    algorithmCode,
    Transform,
  }) {
    dispatch('setStoreKey', 'code');
    const resp = await tenantBiometricLivenessService.fetchAllBiometricLiveness({
      tenantCode,
      svrgrpCode,
      algorithmCode,
    });
    if (resp.status === 200) {
      let results = resp.data;

      if (Transform) {
        results = results.map(res => new Transform(res));
      }

      dispatch('setAll', { results });
      dispatch('setDbItems', { results });
    }
  },
  async associateBiometricLiveness({ commit, dispatch, getters }, tbl) {
    const resp = await tenantBiometricLivenessService.addLiveness(tbl);
    if (resp.status === 200 || resp.status === 201) {
      commit(types.ADD, resp.data);
    }
    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });
    return resp;
  },
  async disAssociateBiometricLiveness({
    state,
    dispatch,
    getters,
    commit,
  }, tbl) {
    const resp = await tenantBiometricLivenessService.deleteLiveness(tbl);
    if (resp.status === 204) {
      const index = state.all.findIndex(item => item.code === tbl.livenessCode);
      commit(types.DELETE, index);
    }
    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });

    return resp;
  },
  async fetchAvailableLiveness({ commit }, {
    algorithmCode,
  }) {
    const resp = await biometricLivenessService.fetchAll(algorithmCode);
    commit(types.SET_BIOMETRIC_LIVENESS_AVAIL, resp.data);
  },
};

const myMutations = {
  [types.SET_BIOMETRIC_LIVENESS_AVAIL](state, livenesses) {
    state.availableLiveness = livenesses;
  },
};

tenantBiometricLiveness.state = Object.assign(tenantBiometricLiveness.state, myState);
tenantBiometricLiveness.getters = Object.assign(tenantBiometricLiveness.getters, myGetters);
tenantBiometricLiveness.actions = Object.assign(tenantBiometricLiveness.actions, myActions);
tenantBiometricLiveness.mutations = Object.assign(tenantBiometricLiveness.mutations, myMutations);

export default tenantBiometricLiveness;
