import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class ServerGroupService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/svrgrp')));
  }

  static async save(svrgrp) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrp.code), svrgrp));
  }

  static async delete(svrgrp) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrp.code), svrgrp));
  }
}

export default ServerGroupService;
