import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  app: {
    label: 'Application',
    rules: new RuleGenerator('Application')
      .setRequired()
      .getRules(),
  },
  name: {
    label: 'Template Name',
    rules: new RuleGenerator('Template Name')
      .setRequired()
      .getRules(),
  },
  type: {
    label: 'Template Type',
    rules: new RuleGenerator('Template Type')
    // .setRequired()
      .getRules(),
  },
  body: {
    label: 'Template URL',
    rules: new RuleGenerator('Template')
    /* .setRequired() */
      .getRules(),
  },
  included: {
    label: 'Included Biometrics',
    rules: new RuleGenerator('Included Biometrics')
      .getRules(),
  },
  algorithmOperation: {
    label: 'Biometric Format',
    rules: new RuleGenerator('Biometric Format')
      .getRules(),
  },
  headerText: {
    label: 'Header Text',
    rules: new RuleGenerator('Header Text')
      .getRules(),
  },
  bodyText: {
    label: 'Message',
    rules: new RuleGenerator('Message')
      .getRules(),
  },
  alertBackgroundColor: {
    label: 'Background Color',
    rules: new RuleGenerator('Background Color')
      .setHex()
      .getRules(),
  },
  alertTextColor: {
    label: 'Text Color',
    rules: new RuleGenerator('Text Color')
      .setHex()
      .getRules(),
  },
  alertTextboxColor: {
    label: 'Message Background Color',
    rules: new RuleGenerator('Message Background Color')
      .setHex()
      .getRules(),
  },
  logoUrl: {
    label: 'Logo URL',
  },
  logoDisplayType: {
    label: 'Logo Display Type',
    rules: new RuleGenerator('Logo Display Type')
      .getRules(),
  },
  backgroundImageUrl: {
    label: 'Background Image URL',
    rules: new RuleGenerator('Background Image URL')
      .setUrl()
      .getRules(),
  },
};

export default fields;
