const headers = [
  {
    text: 'Name',
    value: 'clientId',
  },
  {
    text: 'UUID',
    value: 'uuid',
  },
];

export default headers;
