const headers = [
  {
    text: 'Tenant Code',
    value: 'code',
  },
  {
    text: 'Tenant Name',
    value: 'name',
  },
];

export default headers;
