import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class ReportService extends BaseService {
  static async overview({ formItem, tenantCode }) {
    const qs = ReportService.queryString({ formItem, tenantCode });
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/billing/tenant/person/count?').concat(qs)));
  }

  static async currentUser({ formItem, tenantCode }) {
    const qs = ReportService.queryString({ formItem, tenantCode });
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/billing/tenant/person?').concat(qs)));
  }

  static async enrollment({ formItem, tenantCode }) {
    const qs = ReportService.queryString({ formItem, tenantCode });
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/billing/tenant/person/enroll?').concat(qs)));
  }

  static async server({ formItem, tenantCode }) {
    const qs = ReportService.queryString({ formItem, tenantCode });
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/billing/person?').concat(qs)));
  }

  static queryString({ formItem, tenantCode }) {
    let qStr = '';

    /* From Date */
    qStr = qStr.concat('fromDate=');
    if (formItem.begin) {
      qStr = qStr.concat(formItem.begin);
    }

    /* To Date */
    qStr = qStr.concat('&toDate=');
    if (formItem.end) {
      qStr = qStr.concat(formItem.end);
    }

    if (tenantCode) {
      qStr = qStr.concat('&tenant=').concat(tenantCode);
    }

    return qStr;
  }
}

export default ReportService;
