import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class CredUserService extends BaseService {
  static async fetchAll({ tenantCode }) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/user?tenantCode=')
      .concat(tenantCode)));
  }

  static async getByEmail(email) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/user?userId=')
      .concat(encodeURIComponent(email))));
  }

  static async fetchRoles({ userId }) {
    return await super.perform(axios.get(ServiceUrlProvider.userManagerUrl()
      .concat('/user/')
      .concat(userId)
      .concat('/role')));
  }

  static async newUser(user) {
    return await super.perform(axios.post(ServiceUrlProvider.userManagerUrl()
      .concat('/user/'), user));
  }

  static async updateUser(user) {
    return await super.perform(axios.put(ServiceUrlProvider.userManagerUrl()
      .concat('/user/')
      .concat(user.uuid), user));
  }

  static async delete(user) {
    return await super.perform(axios.delete(ServiceUrlProvider.userManagerUrl()
      .concat('/user/')
      .concat(user.uuid), user));
  }

  static async updatePassword(user) {
    return await super.perform(axios.patch(ServiceUrlProvider.userManagerUrl()
      .concat('/user/')
      .concat(user.uuid), user));
  }
}

export default CredUserService;
