class RoleUtil {
  static highestRole(roles) {
    const roleChart = new Map();
    roleChart.set('ROLE_SUPER_ADMIN', 40);
    roleChart.set('ROLE_TENANT_MAINT', 30);
    roleChart.set('ROLE_TENANT_ADMIN', 20);
    roleChart.set('ROLE_GMI_USER', 10);

    let topRole = null;
    roles.forEach((role) => {
      if (topRole) {
        const r1 = roleChart.get(topRole.roleCode);
        const r2 = roleChart.get(role.roleCode);
        if (r2 > r1) {
          topRole = role;
        }
      } else {
        topRole = role;
      }
    });
    return topRole;
  }

  static roleValue(role) {
    if (typeof role === 'undefined') {
      return 0;
    }
    const roleTypes = {
      '': () => 0,
      null: () => 0,
      ROLE_GMI_USER: () => 10,
      ROLE_TENANT_ADMIN: () => 20,
      ROLE_TENANT_MAINT: () => 30,
      ROLE_SUPER_ADMIN: () => 40,
    };
    return (roleTypes[role])();
  }

  static isSuperAdmin(role) {
    return role === 'ROLE_SUPER_ADMIN';
  }

  static isTenantMaintainer(role) {
    return role === 'ROLE_TENANT_MAINT';
  }
}

export default RoleUtil;
