import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class TenantBiometricLivenessService extends BaseService {
  static async fetchAllBiometricLiveness({ tenantCode, svrgrpCode, algorithmCode }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg/')
      .concat(algorithmCode)
      .concat('/liveness')));
  }

  static async fetchBiometricLiveness(tbl) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tbl.tenantCode)
      .concat('/svrgrp/')
      .concat(tbl.svrgrpCode)
      .concat('/alg/')
      .concat(tbl.algorithmCode)
      .concat('/liveness/')
      .concat(tbl.livenessCode)));
  }

  static async addLiveness(tbl) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tbl.tenantCode)
      .concat('/svrgrp/')
      .concat(tbl.svrgrpCode)
      .concat('/alg/')
      .concat(tbl.algorithmCode)
      .concat('/liveness/')
      .concat(tbl.livenessCode), null));
  }

  static async deleteLiveness({
    tenantCode,
    svrgrpCode,
    algorithmCode,
    livenessCode,
  }) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg/')
      .concat(algorithmCode)
      .concat('/liveness/')
      .concat(livenessCode)));
  }
}

export default TenantBiometricLivenessService;
