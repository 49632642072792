class BiometricMap {
  static get(captureType) {
    const convertedType = captureType === 'passphrase' ? 'voice' : captureType;
    const bioMap = {
      face: {
        friendly: 'Face',
        type: 'face',
        icon: 'face',
      },
      palm: {
        friendly: 'Palm',
        type: 'palm',
        icon: 'pan_tool',
      },
      pin: {
        friendly: 'PIN',
        type: 'pin',
        icon: 'radio_button_checked',
      },
      voice: {
        friendly: 'Voice',
        type: 'voice',
        icon: 'keyboard_voice',
      },
      default: {
        friendly: '',
        type: '',
        icon: '',
      },
    };

    if (!captureType) {
      return bioMap.default;
    }

    return bioMap[convertedType];
  }

  static isImage(type) {
    if (!type) {
      return false;
    }
    return type === 'face';
  }

  static isRRPalm(bioType) {
    return bioType === 152;
  }
}

export default BiometricMap;
