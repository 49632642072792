/* eslint-disable import/prefer-default-export */

/* Base */
export const SET_KEY = 'SET_KEY';
export const SET_ALL = 'SET_ALL';
export const SET_LOADED = 'SET_LOADED';
export const ADD = 'ADD';
export const DELETE = 'DELETE';
export const UPDATE_BY_INDEX = 'UPDATE_BY_INDEX';
export const SET = 'SET';
export const SET_DB_ITEMS = 'SET_DB_ITEMS';
export const SET_UNSAVED = 'SET_UNSAVED';

/* Authentication */
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN';
export const SET_PENDING_TIMEOUT = 'SET_PENDING_TIMEOUT';
export const SET_PENDING_INTERVAL = 'SET_PENDING_INTERVAL';
export const SET_PENDING_TIMEOUT_REMAINING = 'SET_PENDING_TIMEOUT_REMAINING';
export const SET_PENDING_LOGOUT_TIME = 'SET_PENDING_LOGOUT_TIME';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_PENDING_USERNAME = 'SET_PENDING_USERNAME';

/* Login */
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_FAILED_2ND_FACTOR = 'LOGIN_FAILED_2ND_FACTOR';
export const LOGIN_REJECTED_2ND_FACTOR = 'LOGIN_REJECTED_2ND_FACTOR';

/* Timeouts */
export const SET_AUTH_TIMEOUT = 'SET_AUTH_TIMEOUT';
export const SET_AUTH_TIMEOUT_WARNING = 'SET_AUTH_TIMEOUT_WARNING';
export const SET_AUTH_INTERVAL = 'SET_AUTH_INTERVAL';

/* GLOBAL */
export const REMOVE_AUTH = 'REMOVE_AUTH';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_TOP_ROLE = 'SET_TOP_ROLE';
export const SET_GMI_ROLES = 'SET_GMI_ROLES';
export const SET_UM_ROLE = 'SET_UM_ROLE';
export const SET_TENANTS_READY = 'SET_TENANTS_READY';
export const SET_STICKY_TENANT = 'SET_STICKY_TENANT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_STICKY_TENANT_DISABLED = 'SET_STICKY_TENANT_DISABLED';
export const SET_SELECTED_APP = 'SET_SELECTED_APP';

/* userCredentials */
export const SET_ROLES = 'SET_ROLES';

/* AlertMessage */
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_API_RESPONSE = 'SET_API_RESPONSE';

/* Alert Message Dialog */
export const TOGGLE_SEND_MESSAGE_MODAL = 'TOGGLE_SEND_MESSAGE_MODAL';
export const SEND_MESSAGE_MODAL_GLOBAL = 'SEND_MESSAGE_MODAL_GLOBAL';

/* TenantUserAlerts */
export const SET_ENROLL_SUCCESS = 'SET_ENROLL_SUCCESS';
export const SET_ENROLL_FAILURE = 'SET_ENROLL_FAILURE';
export const SET_VERIFY_SUCCESS = 'SET_VERIFY_SUCCESS';
export const SET_VERIFY_FAILURE = 'SET_VERIFY_FAILURE';
export const SET_LAST_TEMPLATE = 'SET_LAST_TEMPLATE';
export const CLEAR_LAST_TEMPLATE = 'CLEAR_LAST_TEMPLATE';
export const SET_ALL_ENROLLMENTS = 'SET_ALL_ENROLLMENTS';

/* Info */
export const SET_VERSION = 'SET_VERSION';

/* Tabs */
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_SELECTED_TAB_WORKSPACE = 'SET_SELECTED_TAB_WORKSPACE';

/* Biometric Liveness */
export const SET_BIOMETRIC_LIVENESS_ASSOC = 'SET_BIOMETRIC_LIVENESS_ASSOC';
export const SET_BIOMETRIC_LIVENESS_UN_ASSOC = 'SET_BIOMETRIC_LIVENESS_UN_ASSOC';
export const SET_BIOMETRIC_LIVENESS_AVAIL = 'SET_BIOMETRIC_LIVENESS_AVAIL';

/* Enrollments */
export const SET_ENROLLMENTS = 'SET_ENROLLMENTS';
export const SET_PENDING_ENROLLMENTS = 'SET_PENDING_ENROLLMENTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';

/* Performance */
export const PERF_ADD_ENROLL_RESPONSE = 'PERF_ADD_ENROLL_RESPONSE';
export const PERF_ADD_VERIFY_RESPONSE = 'PERF_ADD_VERIFY_RESPONSE';
export const PERF_CLEAR_ALL = 'PERF_CLEAR_ALL';
export const PERF_ADD_RESPONSE = 'PERF_ADD_RESPONSE';
export const PERF_CREATE_TENANT_RESPONSE = 'PERF_CREATE_TENANT_RESPONSE';
export const PERF_CREATE_APPLICATION_RESPONSE = 'PERF_CREATE_APPLICATION_RESPONSE';
export const PERF_ASSOCIATE_APPLICATION_RESPONSE = 'PERF_ASSOCIATE_APPLICATION_RESPONSE';
export const PERF_ASSOCIATE_BIOMETRIC_RESPONSE = 'PERF_ASSOCIATE_BIOMETRIC_RESPONSE';
export const PERF_CREATE_USER_RESPONSE = 'PERF_CREATE_USER_RESPONSE';
export const PERF_FETCH_PENDING_ENROLL_RESPONSE = 'PERF_FETCH_PENDING_ENROLL_RESPONSE';
export const PERF_DELETE_TENANT_RESPONSE = 'PERF_DELETE_TENANT_RESPONSE';
export const PERF_DELETE_APPLICATION_RESPONSE = 'PERF_DELETE_APPLICATION_RESPONSE';
export const PERF_DELETE_USER_RESPONSE = 'PERF_DELETE_USER_RESPONSE';

/* System Info */
export const SET_STACK_INFO = 'SET_STACK_INFO';

/* User Devices */
export const DELETE_USER_DEVICE = 'DELETE_USER_DEVICE';

/* Tenant Templates */
export const SET_DEFAULT_TEMPLATE = 'SET_DEFAULT_TEMPLATE';
export const CLEAR_DEFAULT_TEMPLATE = 'CLEAR_DEFAULT_TEMPLATE';

/* Tenant Template Biometrics */
export const SET_DB_BIOMETRICS = 'SET_DB_BIOMETRICS';

/* Tenant Applications */
export const SET_DB_APPLICATIONS = 'SET_DB_APPLICATIONS';

/* Users */
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
