<template>
  <div>
    <Section>
      <div slot="contents">
        <v-form ref="form">
          <v-container>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  label="Select Biometric"
                  :items="allBiometrics"
                  v-model="selectedBiometric"
                  item-text="code"
                  item-value="code"
                  return-object
                />
              </v-flex>
              <v-flex xs6>
                <input
                  type="file"
                  @change="onFilePicked"
                  ref="fileInput"
                  class="hideme"
                  multiple
                >
                <BaseButton
                  v-bind="selectImageButton"
                  @clicked="selectClick"
                  icon="file_upload"
                  color="accent"
                /> {{ uploadedFileNames }}
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs3 class="tc">
                <v-text-field
                  label="Test Count"
                  v-model="loopLength"
                ></v-text-field>
              </v-flex>
              <v-flex xs3 class="tc">
                <v-text-field
                  label="Verify Count"
                  v-model="verifyCount"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 class="tc">
                <v-progress-circular
                  :size="50"
                  :width="10"
                  :value="runProgress"
                  color="accent"
                  :indeterminate="inProgress"
                >
                  {{ responseCount }}
                </v-progress-circular>
              </v-flex>
            </v-layout>

            <v-layout v-if="hasFinished">
              <v-flex xs6>
                <div class="perf-color">Test Complete</div>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs12 class="pl-0">
                <BaseButton
                  v-bind="performanceButton"
                  @clicked="runTest"
                  color="accent"
                  :disabled="!canRun"
                />
                <BaseButton
                  v-bind="logButton"
                  @clicked="logOpen = !logOpen"
                  :disabled="!canRun"
                />
                <BaseButton
                  v-bind="downloadButton"
                  @clicked="download"
                  :disabled="!hasFinished"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <PerformanceStats
          :runningTime="runningTime"
          :endTime="dateEnded"
        />
        <PerformanceLineChart
          :chartData="chartData"
        />
      </div>
    </Section>

    <PerformanceLog
      :open="logOpen"
      @clicked="logOpen = !logOpen"
    />

  </div>
</template>

<style>
  .hideme {
    display: none;
  }
  .tc {
    padding-left: 10px !important;
  }
  .perf-color {
    color: #009688;
  }
</style>

<script>
import { fields, buttons, csvFields } from '@/components/config/performance';
import FileSaver from 'file-saver';
import * as Json2csv from 'json2csv';
import { mapActions, mapGetters } from 'vuex';
import biometricService from '@/services/BiometricService';
import BiometricToPlain from '@/transform/BiometricToPlain';
import filePicker from '@/components/mixins/filePicker';
import RandomUtil from '@/utils/RandomUtil';
import BiometricPayload from '@/utils/BiometricPayload';
import Tenant from '@/models/Tenant';
import Application from '@/models/Application';
import TenantUser from '@/models/TenantUser';

export default {
  name: 'WorkspacePerformance',
  mixins: [filePicker],
  data: () => ({
    fields,
    ...buttons,
    ...csvFields,
    serverGroupCode: 'main',
    loopLength: 1,
    responseCount: 0,
    uppers: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    chartData: {},
    myChartLabels: [],
    runProgress: 0,
    inProgress: false,
    startTime: new Date(),
    endTime: new Date(),
    selectedBiometric: null,
    logOpen: false,
    myPromises: [],
    color: [],
    timerId: null,
    verifyCount: 1,
    verifyArr: [],
  }),
  computed: {
    ...mapGetters('perf', {
      getAllResponses: 'getAllResponses',
      getEnrollResponseTimes: 'getEnrollResponseTimes',
      getVerifyResponseTimes: 'getVerifyResponseTimes',
      getVerifyResponseTimesByIteration: 'getVerifyResponseTimesByIteration',
      createTenantResponseTimes: 'createTenantResponseTimes',
      createApplicationResponseTimes: 'createApplicationResponseTimes',
      associateApplicationResponsesTimes: 'associateApplicationResponsesTimes',
      associateBiometricResponsesResponsesTimes: 'associateBiometricResponsesResponsesTimes',
      createUserResponsesTimes: 'createUserResponsesTimes',
      pendingEnrollmentsResponseTimes: 'pendingEnrollmentsResponseTimes',
      deleteUserResponsesTimes: 'deleteUserResponsesTimes',
      deleteApplicationTimes: 'deleteApplicationTimes',
      deleteTenantTimes: 'deleteTenantTimes',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    canRun() {
      return (this.loopLength > 0)
        && this.fileData != null
        && !this.inProgress
        && this.selectedBiometric != null;
    },
    hasFinished() {
      return this.runProgress === 100;
    },
    uploadedFileNames() {
      let names = '';

      if (this.fileData !== null) {
        Object.keys(this.fileData).forEach((i) => {
          names = names.concat(this.fileData[i].name).concat(',');
        });
      }
      return names.substring(0, names.length - 1);
    },
    runningTime() {
      return Math.abs(((this.endTime - this.startTime) / 1000));
    },
    dateEnded() {
      if (this.runProgress === 100) {
        return this.endTime;
      }
      return null;
    },
  },
  methods: {
    ...mapActions('perf', {
      createTenant: 'createTenant',
      deleteTenant: 'deleteTenant',
      createApplication: 'createApplication',
      deleteApplication: 'deleteApplication',
      associateBiometric: 'associateBiometric',
      associateApp: 'associateApp',
      createUser: 'createUser',
      deleteUser: 'deleteUser',
      enroll: 'enroll',
      verify: 'verify',
      enrollVoice: 'enrollVoice',
      verifyVoice: 'verifyVoice',
      fetchPendingEnrollment: 'fetchPendingEnrollment',
      clearStore: 'clearStore',
    }),
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
    }),
    ...mapActions('app', [
      'setStickyTenantDisabled',
    ]),
    download() {
      const parser = new Json2csv.Parser({ fields: csvFields });
      const csv = parser.parse(this.getAllResponses);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, 'Performance.csv');
    },
    selectClick() {
      this.resetInput();
      const f = this.$refs.fileInput;
      f.click();
    },
    resetInput() {
      this.fileData = null;
      const input = this.$refs.fileInput;
      input.type = 'file';
    },
    clear() {
      this.endTime = new Date();
      this.startTime = new Date();

      this.clearStore();
      if (this.chartData.datasets && this.chartData.datasets.length > 0) {
        this.chartData.datasets[0].data = [];
        this.chartData.labels = [];
      }
      this.myChartLabels = [];
      this.runProgress = 0;
      this.responseCount = 0;
      this.inProgress = false;
      this.verifyArr = [];
    },
    async runTest() {
      this.clear();
      this.inProgress = true;
      for (let i = 1; i <= this.loopLength; i += 1) {
        this.myPromises.push(this.setupAndRunTest());
        /* Create the chart labels from 1 - loop length */
        this.myChartLabels = Array.from({ length: this.loopLength }, (v, k) => k + 1);
      }

      await Promise.all(this.myPromises);
      this.runProgress = 100;
      this.inProgress = false;
      this.endTime = new Date();
      this.updateChart();
    },

    async setupAndRunTest() {
      /* Create */
      const str = RandomUtil.getRandom(this.uppers, 8);

      const tenant = new Tenant({
        code: str.concat('tenant'),
        name: str.concat('tenant'),
      });

      const application = new Application({
        code: str.concat('app'),
        name: str.concat('app'),
      });

      const user = new TenantUser({
        userId: str.concat('user'),
      });

      await this.createTenant({
        tenant,
      });

      await this.createApplication({
        application,
      });

      /* Associate Biometric */
      await this.associateBiometric({
        tenant,
        serverGroupCode: this.serverGroupCode,
        biometricCode: this.selectedBiometric.code,
      });

      /* Associate Application */
      await this.associateApp({
        tenant,
        application,
      });

      const newUser = await this.createUser({
        tenant,
        user,
      });

      await this.enrollVerify({ tenant, application, newUser });
      await this.cleanup({ tenant, application, newUser });
    },

    async enrollVerify({ tenant, application, newUser }) {
      const enrollments = await this.fetchPendingEnrollment({
        application,
        user: newUser,
      });

      let payload = new BiometricPayload({
        enrollments,
        biometric: this.selectedBiometric,
        files: this.$refs.fileInput.files,
        isVerify: false,
      }).getPayload();

      await this.enroll({
        tenant,
        user: newUser,
        application,
        payload,
      });

      if (this.selectedBiometric.captureType === 'voice') {
        payload = new BiometricPayload({
          enrollments,
          biometric: this.selectedBiometric,
          files: this.$refs.fileInput.files,
          isVerify: true,
        }).getPayload();
      }

      const verifyPromises = [];
      for (let i = 1; i <= this.verifyCount; i += 1) {
        verifyPromises.push(this.callVerify(tenant, newUser, application, payload, i));
      }

      await Promise.all(verifyPromises);
    },

    async callVerify(tenant, user, application, payload, iteration) {
      await this.verify({
        tenant,
        user,
        application,
        payload,
        iteration,
      });
    },

    async cleanup({ tenant, application, newUser }) {
      /* DELETE */

      await this.deleteUser({
        user: newUser,
        tenantCode: tenant.code,
      });

      await this.deleteApplication({
        application,
      });

      await this.deleteTenant({
        tenant,
      });
    },
    updateChart() {
      const verArr = [];

      if (this.verifyCount === 1) {
        verArr.push({
          label: 'Verify Response (ms)',
          backgroundColor: '#009688',
          data: this.getVerifyResponseTimes,
        });
      } else {
        for (let i = 1; i <= this.verifyCount; i += 1) {
          verArr.push({
            label: 'Verify Response (ms) '.concat(i),
            backgroundColor: '#009688',
            data: this.getVerifyResponseTimesByIteration(i),
          });
        }
      }

      this.chartData = Object.assign({}, {
        labels: this.myChartLabels,
        datasets: [
          {
            label: 'Create Tenant (ms)',
            backgroundColor: this.color[0],
            data: this.createTenantResponseTimes,
          },
          {
            label: 'Create Application (ms)',
            backgroundColor: this.color[1],
            data: this.createApplicationResponseTimes,
          },
          {
            label: 'Associate Biometric (ms)',
            backgroundColor: this.color[2],
            data: this.associateBiometricResponsesResponsesTimes,
          },
          {
            label: 'Associate Application (ms)',
            backgroundColor: this.color[3],
            data: this.associateApplicationResponsesTimes,
          },
          {
            label: 'Create User (ms)',
            backgroundColor: this.color[4],
            data: this.createUserResponsesTimes,
          },
          {
            label: 'Enroll Response (ms)',
            backgroundColor: '#263238',
            data: this.getEnrollResponseTimes,
          },
          ...verArr,
          {
            label: 'Delete User (ms)',
            backgroundColor: this.color[5],
            data: this.deleteUserResponsesTimes,
          },
          {
            label: 'Delete Application (ms)',
            backgroundColor: this.color[6],
            data: this.deleteApplicationTimes,
          },
          {
            label: 'Delete Tenant (ms)',
            backgroundColor: this.color[7],
            data: this.deleteTenantTimes,
          },
        ],
      });
    },
    setColors() {
      for (let i = 0; i < 10; i += 1) {
        this.color.push(RandomUtil.randomColor());
      }
    },
  },
  async mounted() {
    this.clear();
    this.setColors();
    this.setStickyTenantDisabled(true);
    await this.fetchAllBiometrics({ service: biometricService, Transform: BiometricToPlain, key: 'code' });
  },
  watch: {
    deleteTenantTimes(times) {
      this.responseCount = times.length;

      if (times.length < 10) {
        this.updateChart();
      }
    },
    getEnrollResponseTimes(times) {
      if (times < 10) {
        this.updateChart();
      }
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    PerformanceLineChart: () => import('@/components/workspace/performance/PerformanceLineChart'),
    PerformanceStats: () => import('@/components/workspace/performance/PerformanceStats'),
    PerformanceLog: () => import('@/components/dialogs/PerformanceLog'),
  },
};
</script>
