import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class TenantAlertService extends BaseService {
  static async fetchAll({ tenantCode, id }) {
    const messages = await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(id)
      .concat('/message')));

    const responses = await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(id)
      .concat('/message/response')));

    messages.data = messages.data.concat(responses.data);
    return messages;
  }

  static async fetchResponses({ tenantCode, id }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(id)
      .concat('/message/response')));
  }

  static async fetchDocuments({ tenantCode, personId }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(personId)
      .concat('/documents')));
  }

  static async sendMessage({
    tenantCode,
    appCode,
    personId,
    message,
    template,
  }) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/template/')
        .concat(template)
        .concat('/person/')
        .concat(personId)
        .concat('/message'),
      message,
    ));
  }

  static async delete({ personId, appCode, messageUuid }) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/person/')
      .concat(personId)
      .concat('/app/')
      .concat(appCode)
      .concat('/message/')
      .concat(messageUuid)));
  }
}

export default TenantAlertService;
