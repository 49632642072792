const buttons = {
  performanceButton: {
    text: 'Run Performance Test',
  },
  selectImageButton: {
    text: 'Upload File',
  },
  logButton: {
    text: 'View Log',
  },
  downloadButton: {
    text: 'Download Log',
  },
};

export default buttons;
