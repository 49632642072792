import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import userDeviceService from '@/services/UserDeviceService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const userDevices = new BaseStoreModule({ component: 'Device', sort: { key: 'deviceId', descending: false } });

const myActions = {
  async deleteDevice({
    commit,
    dispatch,
    getters,
    state,
  }, { device, personId }) {
    const resp = await userDeviceService.delete({ device, personId });

    if (resp.status === 204) {
      const index = getters.getIndexByKey(device[state.key]);
      commit(types.DELETE, index);
    }
    dispatch('alertMessage/setApiResponse', {
      resp,
      component: getters.getComponent,
    }, { root: true });
    return resp;
  },
};

userDevices.actions = Object.assign(userDevices.actions, myActions);

export default userDevices;
