const headers = [
  {
    text: 'Status',
    value: 'icon',
    type: 'icon',
  },
  {
    text: 'Date',
    value: 'createdDate',
  },
  {
    text: 'Template',
    value: 'template',
  },
  {
    text: 'Application',
    value: 'application',
  },
  {
    text: 'Message Id',
    value: 'messageUuid',
  },
  {
    text: 'Transaction Id',
    value: 'transactionUuid',
  },
];

export default headers;
