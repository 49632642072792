const csvFields = [
  {
    label: 'Api',
    value: 'label',
  },
  {
    label: 'Value',
    value: 'key',
  },
  {
    label: 'Response Time',
    value: 'responseTime',
  },
];

export default csvFields;
