import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import tenantAlertService from '@/services/TenantAlertService';
import AlertToPlain from '@/transform/AlertToPlain';
import DocumentsToPlain from '@/transform/DocumentsToPlain';
import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';
import DocumentMap from '@/utils/DocumentMap';
import tenantService from '@/services/TenantService';
import BiometricMap from '@/utils/BiometricMap';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const userAlerts = new BaseStoreModule({ component: 'Alert', sort: { key: 'createdDate', descending: true } });

const myState = {
  enrollSuccess: [],
  enrollFailure: [],
  verifySuccess: [],
  verfifyFailure: [],
  allEnrollments: [],
};

const myGetters = {
  getEnrollSuccess: state => DocumentMap.map([state.enrollSuccess]),
  getEnrollFailure: state => DocumentMap.map([state.enrollFailure]),
  getVerifySuccess: state => DocumentMap.map([state.verifySuccess]),
  getVerifyFailure: state => DocumentMap.map([state.verfifyFailure]),
  getAllEnrollments: state => state.allEnrollments,
  getDocumentsByTransaction: (state, getters) => {
    const docs = {};
    if (getters.getEnrollSuccess
      && getters.getEnrollSuccess.length > 0
    ) {
      for (let i = 0; i < getters.getEnrollSuccess.length; i += 1) {
        docs[getters.getEnrollSuccess[i].transactionUuid] = getters.getEnrollSuccess[i].files;
      }
    }
    if (getters.getEnrollFailure
      && getters.getEnrollFailure.length > 0
    ) {
      for (let i = 0; i < getters.getEnrollFailure.length; i += 1) {
        docs[getters.getEnrollFailure[i].transactionUuid] = getters.getEnrollFailure[i].files;
      }
    }
    if (getters.getVerifySuccess
      && getters.getVerifySuccess.length > 0
    ) {
      for (let i = 0; i < getters.getVerifySuccess.length; i += 1) {
        docs[getters.getVerifySuccess[i].transactionUuid] = getters.getVerifySuccess[i].files;
      }
    }
    if (getters.getVerifyFailure
      && getters.getVerifyFailure.length > 0
    ) {
      for (let i = 0; i < getters.getVerifyFailure.length; i += 1) {
        docs[getters.getVerifyFailure[i].transactionUuid] = getters.getVerifyFailure[i].files;
      }
    }
    return docs;
  },
};

const myActions = {
  async sendMessage({ dispatch, getters }, {
    message,
    person,
    tenantCode,
    appCode,
    template,
  }) {
    const resp = await tenantAlertService.sendMessage({
      tenantCode,
      appCode,
      personId: person.id,
      message,
      template,
    });

    if (resp.status === 201) {
      dispatch('addOneSorted', new AlertToPlain(resp.data));
      // In this case we use setAlertMessage instead of the standard setApiResponse
      // so we can use a custom message, since setApiResponse creates a hardcoded
      // message text based off of the http response code.
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Alert Sent',
      }, { root: true });
    } else if (resp.status === 404) {
      dispatch('alertMessage/setAlertMessage', {
        type: 'error',
        message: 'No devices registered for indicated Person and Application',
      }, { root: true });
    }
  },

  async fetchDocuments({ commit, dispatch, rootGetters }, {
    tenantCode,
    personId,
  }) {
    const resp = await tenantAlertService.fetchDocuments({
      tenantCode,
      personId,
    });
    if (resp.status === 200) {
      // We need to get the captureType value for the associated biometric to
      // pass to DocumentsToPlain, so we get it from the biometrics module.
      let biometrics = [];
      // biometrics = rootGetters['biometrics/getAll'];
      biometrics = rootGetters['tenantBiometrics/getAll'];
      if (biometrics.length < 1) {
        // Force the biometrics to be loaded into state.
        dispatch('tenantBiometrics/fetchBiometrics', {
          service: tenantService,
          key: 'code',
          tenantCode,
          Transform: TenantBiometricToPlain,
        }, { root: true });
        biometrics = rootGetters['tenantBiometrics/getAll'];
      }
      // Create a new array from resp.data that adds the captureType based on bioType
      const documents = resp.data.map((document) => {
        const newDocument = document;
        // Filter biometrics by captureType.
        const targetBio = biometrics.filter(biometric => biometric.bioType === document.bioType);
        if (targetBio.length > 0) {
          newDocument.captureType = targetBio[0].captureType;
          newDocument.name = targetBio[0].code;
        }
        return newDocument;
      });
      const d = new DocumentsToPlain(documents);
      commit(types.SET_ENROLL_SUCCESS, d.getEnrollSuccess());
      commit(types.SET_ENROLL_FAILURE, d.getEnrollFailure());
      commit(types.SET_VERIFY_SUCCESS, d.getVerifySuccess());
      commit(types.SET_VERIFY_FAILURE, d.getVerifyFailure());
    }
  },

  async fetchResponses({ commit, dispatch, rootGetters }, {
    tenantCode,
    id,
    Transform,
  }) {
    const resp = await tenantAlertService.fetchResponses({
      tenantCode,
      id,
    });
    if (resp.status === 200) {
      const { data } = resp;
      // First, only get enrollment successes.
      const successfulEnrollments = data.filter(item => item.transactionType === 'ENROLL' && item.succeeded);
      const enrollments = successfulEnrollments.map((item) => {
        let newEnrollment = {};
        const biometrics = rootGetters['tenantBiometrics/getAll'];
        let beResponse = {};
        beResponse = JSON.parse(item.beResponse);
        // Pre-containers records will have one level of beResponse
        // and newer records will have beResponse.beResponse.
        if (Object.prototype.hasOwnProperty.call(beResponse, 'beResponse')) {
          newEnrollment.bioType = beResponse.algorithmStatuses[0].bioType;
          newEnrollment.algCode = beResponse.algorithmStatuses[0].algCode;
        } else {
          // This is an older record, so there is only one beResponse member.
          beResponse = JSON.parse(item.beResponse);
          const { bioType } = beResponse.enrollBiometricInputEx
            .biometricInput.enrollResultEx[0].template;
          newEnrollment.bioType = bioType;
        }
        const targetBio = biometrics.filter(
          biometric => biometric.bioType === newEnrollment.bioType,
        );
        newEnrollment.captureType = targetBio[0].captureType;
        newEnrollment.tenantCode = item.tenantCode;
        newEnrollment.enrollTimestamp = item.createdDate;
        newEnrollment.transactionUuid = item.transactionUuid;
        newEnrollment = new Transform(newEnrollment);
        Object.assign(newEnrollment, BiometricMap.get(newEnrollment.captureType));
        return newEnrollment;
      });
      // Commit these enrollments.
      commit(types.SET_ALL_ENROLLMENTS, enrollments);
    }
  },
  setAll({ commit, dispatch, state }, { results }) {
    const sorted = Object.assign([], results);
    if (state.sort) {
      sorted.sort((a, b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateB - dateA;
      });
    }

    commit(types.SET_ALL, sorted);

    if (sorted.length) {
      dispatch('update', sorted[0]);
    }
    dispatch('setLoaded');
  },

  clearStoreCustom({ commit }) {
    commit(types.SET_ALL, []);
    commit(types.SET_ENROLL_SUCCESS, []);
    commit(types.SET_ENROLL_FAILURE, []);
    commit(types.SET_VERIFY_SUCCESS, []);
    commit(types.SET_VERIFY_FAILURE, []);
  },
};

const myMutations = {
  [types.SET_ENROLL_SUCCESS](state, document) {
    state.enrollSuccess = document;
  },
  [types.SET_ENROLL_FAILURE](state, document) {
    state.enrollFailure = document;
  },
  [types.SET_VERIFY_SUCCESS](state, document) {
    state.verifySuccess = document;
  },
  [types.SET_VERIFY_FAILURE](state, document) {
    state.verfifyFailure = document;
  },
  [types.SET_ALL_ENROLLMENTS](state, documents) {
    state.allEnrollments = documents;
  },
};

userAlerts.state = Object.assign(userAlerts.state, myState);
userAlerts.actions = Object.assign(userAlerts.actions, myActions);
userAlerts.getters = Object.assign(userAlerts.getters, myGetters);
userAlerts.mutations = Object.assign(userAlerts.mutations, myMutations);

export default userAlerts;
