import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class BiometricService extends BaseService {
  static async fetchAll() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/alg')));
  }

  static async save(alg) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl().concat('/alg/').concat(alg.code), alg));
  }

  static async delete(alg) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl().concat('/alg/').concat(alg.code), alg));
  }
}

export default BiometricService;
