const fields = {
  deviceId: {
    label: 'Device Id',
  },
  platform: {
    label: 'Platform',
  },
  platformVersion: {
    label: 'Platform Version',
  },
};

export default fields;
