import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import TheLogin from '@/components/TheLogin';
import TheForgotPassword from '@/components/TheForgotPassword';
import Info from '@/components/workspace/Info';

/* Workspaces */
import TheWorkspace from '@/components/TheWorkspace';
import WorkspaceUsers from '@/components/workspace/WorkspaceUsers';
import WorkspaceTenants from '@/components/workspace/WorkspaceTenants';
import WorkspaceServer from '@/components/workspace/WorkspaceServer';
import WorkspaceReports from '@/components/workspace/WorkspaceReports';
import WorkspacePerformance from '@/components/workspace/WorkspacePerformance';

import UserEdit from '@/components/workspace/users/UserEdit';
import UserEnrollmentEdit from '@/components/workspace/users/UserEnrollmentEdit';
import UserAlertEdit from '@/components/workspace/users/UserAlertEdit';
import UserDeviceEdit from '@/components/workspace/users/UserDeviceEdit';

import TenantEdit from '@/components/workspace/tenants/TenantEdit';
import TenantApplicationEdit from '@/components/workspace/tenants/TenantApplicationEdit';
import TenantBiometricEdit from '@/components/workspace/tenants/TenantBiometricEdit';
import TenantTemplateEdit from '@/components/workspace/tenants/TenantTemplateEdit';

import ServerAppsEdit from '@/components/workspace/server/ServerAppsEdit';
import ServerBiometricsEdit from '@/components/workspace/server/ServerBiometricsEdit';
import ServerGroupsEdit from '@/components/workspace/server/ServerGroupsEdit';
import ServerUsersEdit from '@/components/workspace/server/ServerUsersEdit';
import ServerCredsEdit from '@/components/workspace/server/ServerCredsEdit';
import ServerLivenessEdit from '@/components/workspace/server/ServerLivenessEdit';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: TheLogin,
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: TheForgotPassword,
    },
    {
      path: '/info',
      name: 'Info',
      component: Info,
    },
    {
      path: '/',
      component: TheWorkspace,
      children: [
        {
          path: 'users/',
          name: 'WorkspaceUsers',
          component: WorkspaceUsers,
          children: [
            {
              path: 'edit',
              name: 'UserEdit',
              component: UserEdit,
              components: {
                users: UserEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
            {
              path: 'enrollments',
              name: 'UserEnrollmentEdit',
              component: UserEnrollmentEdit,
              components: {
                users: UserEnrollmentEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
            {
              path: 'alerts',
              name: 'UserAlertEdit',
              component: UserAlertEdit,
              components: {
                users: UserAlertEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
            {
              path: 'devices',
              name: 'UserDeviceEdit',
              component: UserDeviceEdit,
              components: {
                users: UserDeviceEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
          ],
          components: {
            workspace: WorkspaceUsers,
          },
          meta: {
            requiresAuth: true,
            mininumPermission: 'ROLE_TENANT_ADMIN',
          },
        },
        {
          path: 'tenants/',
          name: 'WorkspaceTenants',
          component: WorkspaceTenants,
          components: {
            workspace: WorkspaceTenants,
          },
          children: [
            {
              path: 'edit',
              name: 'TenantEdit',
              component: TenantEdit,
              components: {
                tenants: TenantEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
            {
              path: 'apps',
              name: 'TenantApplicationEdit',
              component: TenantApplicationEdit,
              components: {
                tenants: TenantApplicationEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_MAINT',
              },
            },
            {
              path: 'biometrics',
              name: 'TenantBiometricEdit',
              component: TenantBiometricEdit,
              components: {
                tenants: TenantBiometricEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_ADMIN',
              },
            },
            {
              path: 'templates',
              name: 'TenantTemplateEdit',
              component: TenantTemplateEdit,
              components: {
                tenants: TenantTemplateEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_MAINT',
              },
            },
          ],
          meta: {
            requiresAuth: true,
            mininumPermission: 'ROLE_TENANT_ADMIN',
          },
        },
        {
          path: 'server/',
          name: 'WorkspaceServer',
          component: WorkspaceServer,
          components: {
            workspace: WorkspaceServer,
          },
          children: [
            {
              path: 'applications',
              name: 'ServerAppsEdit',
              component: ServerAppsEdit,
              components: {
                server: ServerAppsEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_SUPER_ADMIN',
              },
            },
            {
              path: 'algorithms',
              name: 'ServerBiometricsEdit',
              component: ServerBiometricsEdit,
              components: {
                server: ServerBiometricsEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_SUPER_ADMIN',
              },
            },
            {
              path: 'groups',
              name: 'ServerGroupsEdit',
              component: ServerGroupsEdit,
              components: {
                server: ServerGroupsEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_SUPER_ADMIN',
              },
            },
            {
              path: 'liveness',
              name: 'ServerLivenessEdit',
              component: ServerLivenessEdit,
              components: {
                server: ServerLivenessEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_SUPER_ADMIN',
              },
            },
            {
              path: 'users',
              name: 'ServerUsersEdit',
              component: ServerUsersEdit,
              components: {
                server: ServerUsersEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_MAINT',
              },
            },
            {
              path: 'clients',
              name: 'ServerCredsEdit',
              component: ServerCredsEdit,
              components: {
                server: ServerCredsEdit,
              },
              meta: {
                requiresAuth: true,
                mininumPermission: 'ROLE_TENANT_MAINT',
              },
            },
          ],
          meta: {
            requiresAuth: true,
            mininumPermission: 'ROLE_TENANT_MAINT',
          },
        },
        {
          path: 'reports',
          name: 'WorkspaceReports',
          component: WorkspaceReports,
          components: {
            workspace: WorkspaceReports,
          },
          meta: {
            requiresAuth: true,
            mininumPermission: 'ROLE_TENANT_ADMIN',
          },
        },
        {
          path: 'perf',
          name: 'WorkspacePerformance',
          component: WorkspacePerformance,
          components: {
            workspace: WorkspacePerformance,
          },
          meta: {
            requiresAuth: true,
            mininumPermission: 'ROLE_SUPER_ADMIN',
          },
        },
      ],
      meta: {
        requiresAuth: true,
        mininumPermission: 'ROLE_TENANT_ADMIN',
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let resetTimer = false;

  // Check if authenticated
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['authentication/isAuthenticated']) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
    resetTimer = true;
  }

  // Check for minimum role
  if (to.matched.some(record => record.meta.mininumPermission)) {
    // Make sure app is properly loaded first
    if (!store.getters['app/tenantsReady']) {
      await store.dispatch('app/fetchUserDetails');
    }

    // Get the minimum permission to use this route
    const routeMinPerm = to.matched[to.matched.length - 1].meta.mininumPermission;
    if (!store.getters['app/hasMinimumRole'](routeMinPerm)) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
  }

  if (resetTimer) {
    /* Reset timeouts */
    store.dispatch('timeout/startLogoutTimer');
  }

  next();
});

export default router;
