import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: 'Name',
    rules: new RuleGenerator('Name')
      .setRequired()
      .getRules(),
  },
  threshold: {
    label: 'Threshold',
    rules: new RuleGenerator('Threshold')
      .setNumber()
      .getRules(),
  },
  biometricGroup: {
    label: 'Biometric Group',
    rules: new RuleGenerator('Biometric Group')
      .getRules(),
  },
};

export default fields;
