import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  name: {
    label: 'Report Requested',
  },
  begin: {
    label: 'Begin Date',
    rules: new RuleGenerator('Begin Date')
      .setRequired()
      .setRegEx(/^\d{4}\d{2}\d{2}$/)
      .getRules(),
    hint: 'Format date as yyyyMMdd or leave blank to export data for all time periods',
  },
  end: {
    label: 'End Date',
    rules: new RuleGenerator('End Date')
      .setRequired()
      .setRegEx(/^\d{4}\d{2}\d{2}$/)
      .getRules(),
    hint: 'Format date as yyyyMMdd or leave blank to export data for all time periods',
  },
};

export default fields;
