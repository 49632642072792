class TenantBiometric {
  constructor(tenantBiometric) {
    this.code = tenantBiometric.code || '';
    this.threshold = typeof tenantBiometric.threshold === 'number' ? tenantBiometric.threshold : null;
    this.scoreThreshold = typeof tenantBiometric.scoreThreshold === 'number' ? tenantBiometric.scoreThreshold : null;
    this.serverGroup = tenantBiometric.serverGroup || '';
  }
}

export default TenantBiometric;
