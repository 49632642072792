import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class UserEnrollmentService extends BaseService {
  static async fetchAllEnrollments({ tenantCode, personId }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(personId)));
  }

  static async reEnroll({ tenantCode, id, type }) {
    const payload = { enrollExpiration: null };
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/person/')
        .concat(id)
        .concat('/type/')
        .concat(type)
        .concat('/enroll'),
      payload,
    ));
  }

  static async enroll({
    tenantCode,
    id,
    appCode,
    payload,
  }) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/person/')
        .concat(id)
        .concat('/enroll'),
      payload,
    ));
  }

  static async verify({
    tenantCode,
    id,
    appCode,
    payload,
  }) {
    return await super.perform(axios.post(
      ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/app/')
        .concat(appCode)
        .concat('/person/')
        .concat(id)
        .concat('/verify'),
      payload,
    ));
  }

  static async fetchPendingEnrollments({
    appCode,
    id,
  }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/app/')
      .concat(appCode)
      .concat('/person/')
      .concat(id)
      .concat('/enroll')));
  }

  static getGuidSrc(guid, token) {
    return ServiceUrlProvider.docManagerUrl()
      .concat('/document/')
      .concat(guid)
      .concat('?access_token=')
      .concat(token);
  }
}

export default UserEnrollmentService;
