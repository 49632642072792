const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Code',
    value: 'code',
  },
  {
    text: 'Firebase Key',
    value: 'firebaseKey',
  },
];

export default headers;
