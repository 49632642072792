<template>
  <Section class="ap-login">
    <v-container text-xs-center slot="contents">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat>
            <v-img
              src="/images/Logo.png"
              height="315px"
            >
            </v-img>
          </v-card>
        </v-flex>
        <v-flex xs12 class="title">
          Sign in with your credentials
        </v-flex>
        <v-flex xs12>
          <form @submit.prevent="checkCreds">
            <v-layout column>
              <v-flex>
                <BaseControlInput
                  label="Email"
                  name="email"
                  type="email"
                  v-model="username"
                  required
                />
              </v-flex>
              <v-flex>
                <BaseControlInput
                  label="Password"
                  name="password"
                  type="password"
                  v-model="password"
                  required
                />
              </v-flex>
              <!-- <v-flex class="text-xs-left">
                <router-link :to="'forgot'">Forgot password?</router-link>
              </v-flex> -->
              <v-flex class="text-xs-center" mt-5>
                <v-btn type="submit">Sign In</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>

      <TheFooter />

    <TheLoginVerification />

    <TheAlertMessage />
    </v-container>
  </Section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cookie from 'vue-cookies';

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
  }),
  computed: {
    ...mapGetters('app', [
      'tenantsReady',
    ]),
    ...mapGetters('tenants', {
      firstTenant: 'getFirst',
    }),
    ...mapGetters('authentication', [
      'isAuthenticated',
      'getPendingTimeoutRemaining',
    ]),
    ...mapGetters('login', [
      'getFailed',
      'getFailedSecondFactor',
      'getFailedSecondFactorRejected',
    ]),
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    TheFooter: () => import('@/components/TheFooter'),
    TheAlertMessage: () => import('@/components/TheAlertMessage'),
    BaseControlInput: () => import('@/components/base/BaseControlInput'),
    TheLoginVerification: () => import('@/components/TheLoginVerification'),
  },
  methods: {
    ...mapActions('app', [
      'fetchUserDetails',
      'setStickyTenant',
    ]),
    ...mapActions('authentication', [
      'fetchAuthToken',
    ]),
    ...mapActions('login', [
      'logout',
    ]),
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    async checkCreds() {
      const { username, password } = this;
      await this.fetchAuthToken({ username, password });
    },
    resetLogin() {
      this.logout();
    },
  },
  watch: {
    isAuthenticated(bool) {
      if (bool) {
        this.fetchUserDetails();
      }
    },
    tenantsReady(succeeded) {
      if (succeeded) {
        // TODO: Update here to get tenant from cookie instead of first one in list.
        const cookieName = `ap_tenant_${this.username}`;
        if (cookie.isKey(cookieName)) {
          const lastTenant = cookie.get(cookieName);
          this.setStickyTenant(lastTenant);
        } else {
          this.setStickyTenant(this.firstTenant.code);
        }
        this.$router.push({ name: 'TenantEdit' });
      }
    },
    getFailed(fail) {
      if (fail) {
        this.setAlertMessage({
          type: 'error',
          message: 'Bad Username or Password',
        });
      }
    },
  },
  mounted() {
    this.resetLogin();
  },
};
</script>
