<template>
  <div>
    <div>
      <WorkspaceActionDial v-if="roleAtLeastTm"
        :showNew="canDelete"
        :showCancel="canSave"
        :showSave="canSave"
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.name"
                  v-model="tenantModel.name"
                  :disabled="!isNew"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.code"
                  v-model="tenantModel.code"
                  :disabled="!isNew"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.defaultPostBack"
                  v-model="tenantModel.defaultPostBack"
                  :disabled="!roleAtLeastTm"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.maxIdentities"
                  v-model="tenantModel.maxIdentities"
                  :disabled="!roleAtLeastTm"
                />
              </v-flex>
            </v-layout>
            <v-layout row v-if="showExtras">
              <v-flex xs6>
                <v-select
                  label="Biometric Group"
                  v-model="selectedServerGroupCode"
                  :items="serverGroups"
                  :disabled="!serverGroupEnabled"
                  item-value="code"
                  item-text="code"
                  @change="serverGroupChanged"
                />
              </v-flex>
              <v-flex xs6>
              </v-flex>
            </v-layout>
            <v-layout row v-if="showExtras">
              <v-flex xs6>
                <BaseSelectChip
                  label="Associated Biometrics"
                  :value="tenantBiometrics"
                  :available="serverGroupAlgorithms"
                  :disabled="biometricsDisabled"
                  itemValue="code"
                  itemText="code"
                  @input="biometricsUpdated"
                />
              </v-flex>
              <v-flex xs6>
                <BaseSelectChip
                  label="Tenant Applications"
                  :value="tenantApps"
                  :available="allApplications"
                  :disabled="biometricsDisabled"
                  itemValue="code"
                  itemText="name"
                  @input="applicationsUpdated"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <div>
          <TenantConfig
            v-if="showExtras"
            :tenantCode="tenantModel.code"
            :tenantName="tenantModel.name"
            :disabled="!this.roleAtLeastTm"
          />
          <BaseButton
            v-if="showExtras"
            v-bind="changeBiometricGroup"
            :disabled="!canChangeBiometrics"
            @clicked="biometricsChange = !biometricsChange"
            color="error"
            icon="warning"
          />
        </div>
        <BaseConfirmModal
          :value="serverGroupChangeModal"
          :title="confirmTitle"
          :text="confirmBodyText"
          declineText="Cancel"
          @clicked="serverGroupChangeConfirmation"
        />
        <BaseConfirmModal
          :value="biometricsChange"
          :title="confirmTitle"
          :text="confirmBiometricsBodyText"
          declineText="Cancel"
          @clicked="biometricsChangedConfirm"
        />
        <BaseConfirmModal
          :value="deleteTenantModal"
          :title="confirmTitle"
          :text="deleteTenantBodyText"
          confirmText="Yes"
          declineText="Cancel"
          @clicked="deleteModalConfirm"
        />
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <TenantTable
          :tenants="getAll"
          :roleAtLeastTm="roleAtLeastTm"
          @rowClick="tenantRowClick"
          @refresh="refresh"
        />
      </div>
    </Section>

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';

import { fields, buttons } from '@/components/config/tenants';

import tenantService from '@/services/TenantService';
import applicationService from '@/services/ApplicationService';
import serverGroupService from '@/services/ServerGroupService';
import serverGroupAlgorithmService from '@/services/ServerGroupAlgorithmService';
import biometricService from '@/services/BiometricService';

import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';
import BiometricToPlain from '@/transform/BiometricToPlain';

import Tenant from '@/models/Tenant';
import State from '@/models/State';
import _ from 'lodash';

export default {
  name: 'TenantEdit',
  data: () => ({
    tenantModel: new Tenant({}),
    tenantModelBase: new Tenant({}),
    tenantModelState: new State({}),
    tenantAppState: new State({}),
    fields,
    ...buttons,
    deleteTenantModal: false,
    serverGroupChangeModal: false,
    selectedServerGroupCode: null,
    originalServerGroupCode: null,
    serverGroupEnabled: false,
    biometricsChange: false,
    confirmTitle: 'WARNING!',
    confirmBodyText: 'Changing the Biometric Group will reset all biometrics for this tenant, and users associated with this tenant. Do you wish to continue?',
    confirmBiometricsBodyText: 'Changing biometrics for this tenant may clear user settings or require users to re-enroll their biometrics. Do you wish to continue?',
    deleteTenantBodyText: 'This action will delete the Tenant, associated administrators and will un-associate users. Click Yes to permanently delete the tenant.',
    isNew: false,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'roleAtLeastTm', 'roleAtLeastTa']),
    ...mapGetters('tenants', ['getAll', 'getByKey', 'loaded']),
    ...mapGetters('applications', {
      allApplications: 'getAll',
    }),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
      dbTenantApplications: 'dbTenantApplications',
    }),
    ...mapGetters('tenantBiometrics', {
      tenantBiometrics: 'getAll',
      dbTenantBiometrics: 'dbTenantBiometrics',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    ...mapGetters('serverGroups', {
      serverGroups: 'getAll',
    }),
    ...mapGetters('serverGroupAlgorithms', {
      serverGroupAlgorithms: 'getAlgorithms',
    }),
    canSave() {
      return !!(this.tenantModel.code && this.roleAtLeastTm);
    },
    canDelete() {
      return !!(this.canSave && this.roleAtLeastTm && !this.isNew);
    },
    canConfig() {
      return !!(this.tenantModel.code && this.roleAtLeastTm);
    },
    biometricsDisabled() {
      return (this.selectedServerGroupCode === null) || !this.serverGroupEnabled;
    },
    showExtras() {
      // return !this.isNew && this.canConfig;
      return !this.isNew && this.roleAtLeastTa;
    },
    canChangeBiometrics() {
      // First, check to see if user role is at least TM
      if (!this.roleAtLeastTm) {
        return false;
      }
      // Next, check to see if serverGroupEnabled is set.
      return !this.serverGroupEnabled;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    TenantEdit: () => import('@/components/workspace/tenants/TenantEdit'),
    TenantTable: () => import('@/components/workspace/tenants/TenantTable'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    TenantConfig: () => import('@/components/workspace/TenantConfig'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
  },
  methods: {
    ...mapActions('tenants', {
      fetchTenants: 'fetchRoleTenants',
      deleteTenant: 'delete',
      saveTenant: 'save',
    }),
    ...mapActions('tenantApplications', [
      'fetchTenantApps',
      'associateApplication',
      'disAssociateApplication',
      'addOneSorted',
    ]),
    ...mapActions('tenantApplications', {
      deleteTenantApplication: 'deleteFromState',
    }),
    ...mapActions('applications', {
      fetchAllApplications: 'fetchAll',
    }),
    ...mapActions('tenantBiometrics', [
      'fetchBiometrics',
      'associateTenantBiometric',
      'disAssociateTenantBiometric',
    ]),
    ...mapActions('tenantBiometrics', {
      addTenantBiometric: 'addToState',
      deleteTenantBiometric: 'deleteFromState',
      clearTenantBiometrics: 'clearAll',
    }),
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
    }),
    ...mapActions('serverGroups', {
      fetchServerGroups: 'fetchAll',
    }),
    ...mapActions('serverGroupAlgorithms', {
      fetchServerGroupsAlgorithms: 'fetchAllBy',
    }),
    ...mapActions('app', [
      'setStickyTenant',
    ]),
    cancelClick() {
      this.isNew = false;
      this.setTenant(this.tenantModelState.original);
      // Reset the form values.
      this.resetForm();
    },
    newClick() {
      this.tenantModel = new Tenant({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteTenantModal = !this.deleteTenantModal;
      }
    },
    biometricsChangedConfirm(confirm) {
      if (confirm) {
        this.serverGroupEnabled = !this.serverGroupEnabled;
      }
      this.biometricsChange = false;
    },
    async tenantRowClick(tenant) {
      if (tenant.code) {
        /* This will reset the tenant select and update this component */
        await this.setStickyTenant(tenant.code);
      }
    },
    async serverGroupChanged() {
      if (this.selectedServerGroupCode !== this.originalServerGroupCode) {
        this.serverGroupChangeModal = !this.serverGroupChangeModal;
      }
    },
    async serverGroupChangeConfirmation(confirm) {
      if (confirm) {
        /* Remove associations to the tenant */
        this.tenantBiometrics.forEach(async (biometric) => {
          await this.disAssociateTenantBiometric({
            service: tenantService,
            tenantCode: this.tenantModel.code,
            serverGroupCode: this.originalServerGroupCode,
            biometricCode: biometric.code,
          });
        });

        /* Get the available biometrics for this newly selected server group */
        await this.fetchServerGroupsAlgorithms({
          service: serverGroupAlgorithmService,
          key: 'code',
          fetchBy: this.selectedServerGroupCode,
        });

        /* Get associated biometrics for this teanant (shouldn't be any) */
        await this.getBiometrics(this.getStickyTenant);

        this.originalServerGroupCode = this.selectedServerGroupCode.slice();
      } else {
        this.selectedServerGroupCode = this.originalServerGroupCode
          ? this.originalServerGroupCode.slice() : null;
      }
      this.serverGroupChangeModal = !this.serverGroupChangeModal;
    },
    async setModel(tenant) {
      if (tenant.code) {
        // Additional step here to set name if it is not set.
        // eslint-disable-next-line no-param-reassign
        tenant.name = tenant.name.length === 0 ? tenant.code : tenant.name;

        this.tenantModel = new Tenant(this.getByKey(tenant.code));
        this.tenantModelBase = new Tenant(this.getByKey(tenant.code));
        this.tenantModelState = new State({
          original: tenant,
        });
        this.setUnsaved(false);
      }
    },
    async setTenant(tenant) {
      const { code } = tenant;

      await this.setModel(tenant);

      // Set the sticky tenant so the selector at the top has the new one.
      await this.setStickyTenant(code);

      if (code && this.roleAtLeastTa) {
        /* Applications */
        await this.fetchAllApplications({ service: applicationService, key: 'code' });
        await this.fetchTenantApps({ service: tenantService, key: 'code', tenantCode: code });

        /* Biometrics */
        await this.getBiometrics(code);

        if (this.roleAtLeastTa) {
          if (this.tenantBiometrics.length > 0) {
            this.selectedServerGroupCode = this.tenantBiometrics[0].serverGroup.slice(0);
            this.originalServerGroupCode = this.tenantBiometrics[0].serverGroup.slice(0);
          } else {
            this.selectedServerGroupCode = null;
            this.originalServerGroupCode = null;
          }
        }
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.saveTenant({
          service: tenantService,
          item: this.tenantModel,
        });

        this.setTenant(this.tenantModel);
        this.saveTenantBiometrics();
        this.saveTenantApplications();
        this.isNew = false;
        this.serverGroupEnabled = false;
        this.setUnsaved(false);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async saveTenantBiometrics() {
      // We have to separately save the biometrics from the
      // Associated Biometrics field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;

      // We compare what's in tenantBiometrics against what is
      // in the db, and then add or delete as necessary.
      // First, we get anything in state that isn't in the db so we
      // can add it.
      const addDiff = this.tenantBiometrics.filter(x => !this.dbTenantBiometrics.includes(x));
      // Then, we find anything that is in the db that isn't in state
      // so we can delete it.
      const delDiff = this.dbTenantBiometrics.filter(x => !this.tenantBiometrics.includes(x));
      // Delete the ones that need to be deleted.
      await delDiff.forEach((bio, index) => {
        vm.disAssociateTenantBiometric({
          service: tenantService,
          tenantCode: vm.tenantModel.code,
          serverGroupCode: vm.selectedServerGroupCode,
          biometricCode: bio.code,
        });
      });
      // Add the currently selected list of biometrics.
      await addDiff.forEach((bio, index) => {
        vm.associateTenantBiometric({
          service: tenantService,
          tenantCode: vm.tenantModel.code,
          serverGroupCode: vm.selectedServerGroupCode,
          biometricCode: bio.code,
        });
      });
    },
    async saveTenantApplications() {
      // We have to separately save the applications from the
      // Associated Biometrics field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;

      // We compare what's in tenantApps against what is
      // in the db, and then add or delete as necessary.
      // First, we get anything in state that isn't in the db so we
      // can add it.
      const addDiff = this.tenantApps.filter(x => !this.dbTenantApplications.includes(x));
      // Then, we find anything that is in the db that isn't in state
      // so we can delete it.
      const delDiff = this.dbTenantApplications.filter(x => !this.tenantApps.includes(x));
      // Delete the applications that need to be deleted.
      await delDiff.forEach((app, index) => {
        vm.disAssociateApplication({
          service: tenantService,
          tenantCode: this.tenantModel.code,
          appCode: app.code,
        });
      });
      // Add the currently selected list of applications.
      await addDiff.forEach((app, index) => {
        vm.associateApplication({
          service: tenantService,
          tenantCode: this.tenantModel.code,
          appCode: app.code,
        });
      });
    },
    async deleteModalConfirm(confirm) {
      if (confirm) {
        await this.deleteTenant({
          service: tenantService,
          item: {
            tenant: this.tenantModel,
            code: this.tenantModel.code,
          },
        });

        await this.setTenant(this.getAll[0]);
        this.tenantModelState = new State({});
      }
      this.deleteTenantModal = false;
    },
    async biometricsUpdated(biometrics) {
      if (biometrics.length > this.tenantBiometrics.length) {
        const biometric = this.findMissing(biometrics, this.tenantBiometrics, 'code');
        // Instead of immediately adding to the db via TenantTemplateService, we just add the
        // item to state.
        this.addTenantBiometric(biometric);
      } else if (biometrics.length < this.tenantBiometrics.length) {
        const biometric = this.findMissing(this.tenantBiometrics, biometrics, 'code');
        // Instead of immediately deleting from the db via TenantTemplateService, we just remove the
        // item from state.
        this.deleteTenantBiometric(biometric);
      }
      this.setUnsaved(true);
    },
    async applicationsUpdated(apps) {
      if (apps.length > this.tenantApps.length) {
        const app = this.findMissing(apps, this.tenantApps, 'code');
        // Instead of immediately adding to the db via ApplicationService,
        // we just add the  item to state.
        this.addOneSorted(app);
      } else if (apps.length < this.tenantApps.length) {
        const app = this.findMissing(this.tenantApps, apps, 'code');
        // Instead of immediately deleting from the db via TenantTemplateService, we just remove the
        // item from state.
        this.deleteTenantApplication(app);
      }
      this.setUnsaved(true);
    },
    async getBiometrics(tenantCode) {
      await this.fetchBiometrics({
        service: tenantService,
        key: 'code',
        tenantCode,
        Transform: TenantBiometricToPlain,
      });
    },
    async setComponent() {
      this.setTabs('Tenants', 'tab-general');
      if (this.getStickyTenant) {
        const code = this.getStickyTenant;

        /* Get all tenants */
        await this.fetchTenants({ service: tenantService, key: 'code', fetchBy: { code } });

        if (this.roleAtLeastTa) {
          /* Get all biometrics */
          await this.fetchAllBiometrics({ service: biometricService, Transform: BiometricToPlain, key: 'code' });

          /* Get all Server Groups */
          await this.fetchServerGroups({ service: serverGroupService, key: 'code' });
          if (this.tenantBiometrics.length > 0) {
            this.selectedServerGroupCode = this.tenantBiometrics[0].serverGroup.slice(0);
            this.originalServerGroupCode = this.tenantBiometrics[0].serverGroup.slice(0);

            /* Get all available algorithms */
            await this.fetchServerGroupsAlgorithms({
              service: serverGroupAlgorithmService,
              key: 'code',
              fetchBy: this.selectedServerGroupCode,
            });
          }

          await this.fetchBiometrics({
            service: tenantService,
            key: 'code',
            tenantCode: code,
            Transform: TenantBiometricToPlain,
          });
        }

        /* Set the sticky tenant */
        const tenant = this.getByKey(code);
        this.setTenant(tenant);
      }
    },
    resetForm() {
      // disable the appropriate fields.
      // Setting this to false will also set canChangeBiometrics = false
      // and biometricsDisabled = true since they depends on serverGroupEnabled.
      this.serverGroupEnabled = false;
    },
  },
  watch: {
    getStickyTenant(oldTenant, newTenant) {
      // Any time the tenant is changed, we need to reset the form.
      this.resetForm();
    },
    tenantModel: {
      handler(model) {
        if (!_.isEqual(model, this.tenantModelBase)) {
          this.setUnsaved(true);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
