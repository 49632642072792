import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  userId: {
    label: 'Username',
    // Set label to 'Email' because the username is required to be an email address.
    rules: new RuleGenerator('Email')
      .setRequired()
      .setMin(1)
      .setMax(255)
      // eslint-disable-next-line no-useless-escape
      .setRegEx(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      .getRules(),
    hint: 'Must be a valid email address',
    counter: '255',
  },
  id: {
    label: 'ID',
  },
};

export default fields;
