<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        :showSave="hasSavePermission"
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.clientId" v-model="clientModel.clientId"
                  :disabled="!canEdit" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.uuid" v-model="clientModel.uuid" disabled />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.clientSecret"
                  v-model="clientModel.clientSecret"
                  :append-icon="pass1 ? 'visibility' : 'visibility_off'"
                  :type="pass1 ? 'password' : 'text'"
                  @click:append="() => (pass1 = !pass1)"
                  :disabled="!canEdit"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.clientSecretConfirm"
                  v-model="myConfirmPass"
                  :append-icon="pass2 ? 'visibility' : 'visibility_off'"
                  :type="pass2 ? 'password' : 'text'"
                  @click:append="() => (pass2 = !pass2)"
                  :disabled="!canEdit"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="apItems"
                  :disabled="!canUpdateGmiRole"
                  v-bind="fields.apRole"
                  v-model="selectedGmiRole.roleCode"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="umClientItems"
                  :disabled="!canUpdateUmRole"
                  v-bind="fields.umRole"
                  v-model="selectedUmRole.roleCode"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <ServerCredsTable
          :clients="allClients"
          @rowClick="clientCredChanged"
          @refresh="refresh"
        />
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteClientModal"
      componentName="Client"
      @deleteModalConfirm="deleteClientConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

  </div>
</template>

<script>
import oAuthMixin from '@/components/mixins/oAuthMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/credsClient';
import credClientService from '@/services/CredClientService';
import baseCredService from '@/services/BaseCredService';

import CredClient from '@/models/CredClient';
import Role from '@/models/Role';
import State from '@/models/State';

import _ from 'lodash';

export default {
  name: 'ServerCredsEdit',
  data: () => ({
    clientModel: new CredClient({}),
    clientModelBase: new CredClient({}),
    clientModelState: new State({}),
    deleteClientModal: false,
    fields,
    isNew: false,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getUmRole', 'getTopRole']),
    ...mapGetters('clientCredentials', {
      allClients: 'getAll',
      credRoles: 'getRoles',
      clientGmiRole: 'gmiRoles',
      clientUserRole: 'userRoles',
    }),
    ...mapGetters('tenants', {
      firstTenant: 'getFirst',
    }),
    canDelete() {
      return !!(this.clientModel.uuid && !this.isNew && this.hasSavePermission);
    },
    canEdit() {
      return (!!(this.clientModel.uuid) || this.isNew) && this.hasSavePermission;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    ServerCredsEdit: () => import('@/components/workspace/server/ServerCredsEdit'),
    ServerCredsTable: () => import('@/components/workspace/server/ServerCredsTable'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('clientCredentials', {
      fetchAllClientCreds: 'fetchAllBy',
      fetchClientCredRoles: 'fetchRoles',
      removeClientCreds: 'delete',
      saveClient: 'saveClient',
      newClient: 'newClient',
    }),
    ...mapActions('app', [
      'setStickyTenant',
    ]),
    cancelClick() {
      this.setClient(this.clientModelState.original);
    },
    newClick() {
      this.setClient({});
      this.resetRoles();
      this.clientModelState = new State({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteClientModal = !this.deleteClientModal;
      }
    },
    setClient(client) {
      this.clientModel = new CredClient(client);
      this.clientModelBase = new CredClient(client);
      this.clientModelState = new State({
        original: client,
      });
      this.setGmiRole(this.clientGmiRole, this.getStickyTenant);
      this.setUmRole(this.clientUserRole, this.getStickyTenant);
      this.myConfirmPass = null;
      this.setUnsaved(false);
    },
    validPassword() {
      return this.clientModel.clientSecret === this.myConfirmPass;
    },
    async getAllClientCreds() {
      await this.fetchAllClientCreds({
        service: credClientService,
        key: 'uuid',
        fetchBy: {
          tenantCode: this.getStickyTenant,
        },
      });
    },
    async clientCredChanged(client) {
      await this.fetchClientCredRoles({ uuid: client.uuid });
      this.setClient(client);
      this.isNew = false;
    },
    async deleteClientConfirm(confirm) {
      if (confirm) {
        await this.removeClientCreds({
          service: credClientService,
          item: this.clientModel,
        });
        this.setClient({});
      }
      this.deleteClientModal = false;
    },
    async customSave() {
      if (this.$refs.form.validate()) {
        const myClient = Object.assign({}, this.clientModel);
        const tenantCode = this.getStickyTenant;

        /* Do not pass this to the server */
        delete myClient.clientSecretConfirm;

        if (myClient.uuid) {
          await this.saveClient({
            item: myClient,
          });
        } else {
          const resp = await this.newClient({
            item: myClient,
          });
          myClient.uuid = resp.data.uuid;
        }

        const gmiRole = new Role({
          tenantCode,
          roleCode: this.selectedGmiRole.roleCode,
        });

        const userRole = new Role({
          tenantCode,
          roleCode: this.selectedUmRole.roleCode,
        });

        await baseCredService.updateRoles({
          type: 'client',
          tenantCode,
          uuid: myClient.uuid,
          gmiRole,
          userRole,
        });

        this.clientModelState.new = false;
        this.isNew = false;
      }
    },
    async setComponent() {
      if (!this.getStickyTenant) {
        this.setStickyTenant(this.firstTenant.code);
      }
      this.newClick();
      this.setTabs('Server Configuration', 'tab-creds');
      await this.getAllClientCreds();
      if (this.allClients.length > 0) {
        this.setClient(this.allClients[0]);
      }
      this.isNew = false;
    },
  },
  watch: {
    clientModel: {
      deep: true,
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.clientModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [oAuthMixin],
};
</script>
