import ReportTransform from '@/transform/ReportTransform';

const csvFields = {
  overview: [
    {
      label: 'TENANT_CODE',
      value: 'tenantCode',
    },
    {
      label: 'TOTAL_USER_COUNT',
      value: 'count',
    },
  ],
  currentUser: [
    {
      label: 'TENANT_CODE',
      value: 'tenantCode',
    },
    {
      label: 'USER_ID',
      value: 'uuid',
    },
    {
      label: 'STATUS',
      value: row => ReportTransform.getStatus(row.status),
    },
    {
      label: 'UPDATE_DATE',
      value: row => ReportTransform.getDate(row.updateDate),
    },
    {
      label: 'UPDATE_ID',
      value: 'updateUser',
    },
  ],
  enrollment: [
    {
      label: 'TENANT_CODE',
      value: 'tenantCode',
    },
    {
      label: 'USER_ID',
      value: 'uuid',
    },
    {
      label: 'SHARED',
      value: 'shared',
    },
    {
      label: 'BIO_TYPE',
      value: 'vendor',
    },
    {
      label: 'UPDATE_DATE',
      value: row => ReportTransform.getDate(row.updateDate),
    },
    {
      label: 'UPDATE_ID',
      value: 'updateUser',
    },
  ],
  server: [
    {
      label: 'USERNAME',
      value: 'userId',
    },
    {
      label: 'USER_ID',
      value: 'uuid',
    },
    {
      label: 'STATUS',
      value: row => ReportTransform.getStatus(row.status),
    },
    {
      label: 'UPDATE_DATE',
      value: row => ReportTransform.getDate(row.updateDate),
    },
    {
      label: 'UPDATE_ID',
      value: 'updateUser',
    },

  ],
};

export default csvFields;
