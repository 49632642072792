class ServiceUrlProvider {
  static gmiUrl() {
    return '/gmi';
  }

  static userManagerUrl() {
    return '/uman';
  }

  static docManagerUrl() {
    return '/doc';
  }

  static getOauthAssociateUrl(type, tenantCode, uuid, resource, role) {
    return ServiceUrlProvider.userManagerUrl()
      .concat('/').concat(type).concat('/')
      .concat(uuid)
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/resource/')
      .concat(resource)
      .concat('/role/')
      .concat(role);
  }
}

export default ServiceUrlProvider;
