import moment from 'moment';
import BasicEnrollment from '@/models/BasicEnrollment';
import BiometricMap from '@/utils/BiometricMap';

class BasicEnrollmentToPlain {
  constructor(e) {
    const enrollment = new BasicEnrollment(e);

    if (e.enrollTimestamp) {
      enrollment.enrollTimestamp = moment(e.enrollTimestamp).format('MM/DD/YYYY HH:mm:ss');
    }

    if (e.transactionUuid) {
      enrollment.transactionUuid = e.transactionUuid;
    }

    Object.assign(enrollment, BiometricMap.get(enrollment.captureType));
    enrollment.status = 'Enrolled';

    return enrollment;
  }
}

export default BasicEnrollmentToPlain;
