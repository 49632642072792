<template>
  <div>GMI Admin Version {{ getVersion }}</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Login',
  computed: {
    ...mapGetters('info', [
      'getVersion',
    ]),
  },
};
</script>
