import Biometric from '@/models/Biometric';

/* Transform class to munge server data */
class BiometricToPlain {
  constructor(b) {
    const biometric = new Biometric(b);
    biometric.enrollSamplesCount = b.enrollSamples ? b.enrollSamples.length : 0;
    biometric.verifySamplesCount = b.verifySamples ? b.verifySamples.length : 0;
    return biometric;
  }
}

export default BiometricToPlain;
