class Device {
  constructor(device) {
    this.username = device.username || '';
    this.deviceId = device.deviceId || '';
    this.platform = device.platform || '';
    this.platformVersion = device.platformVersion || '';
  }
}

export default Device;
