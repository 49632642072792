import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class UserDeviceService extends BaseService {
  static async fetchAll({ id }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/person/')
      .concat(id)
      .concat('/device')));
  }

  static async delete({ device, personId }) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/person/')
      .concat(personId)
      .concat('/device/')
      .concat(device.deviceId)));
  }
}

export default UserDeviceService;
