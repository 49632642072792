import authService from '@/services/AuthService';
import credUserService from '@/services/CredUserService';
import verificationService from '@/services/VerificationService';
import CredUser from '@/models/CredUser';
import * as types from '@/store/mutation-types';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

class AuthenticationStore {
  constructor() {
    this.namespaced = true;

    this.state = {
      isAuthenticated: false,
      token: null,
      pendingTimeout: null,
      pendingTimeoutRemaining: null,
      pendingInterval: null,
      pendingUsername: null,
      logoutTime: null,
    };

    this.getters = {
      isAuthenticated: state => state.isAuthenticated,
      getAuthToken: state => state.token,
      getPendingInterval: state => state.pendingInterval,
      getPendingTimeout: state => state.pendingTimeout,
      getPendingTimeoutRemaining: state => state.pendingTimeoutRemaining,
      getLogoutTime: state => state.logoutTime,
      getPendingUsername: state => state.pendingUsername,
    };

    this.actions = {
      async fetchAuthToken({ dispatch }, { username, password }) {
        const resp = await authService.getToken({ username, password });
        if (resp.status === 200) {
          dispatch('loginSuccess', { username, resp });
        } else if (resp.status === 202) {
          dispatch('checkPendingAuth', { username, password });
        } else if (resp.status === 403) {
          dispatch('rejectPendingAuth');
        } else {
          dispatch('login/failed', null, { root: true });
        }
      },
      loginSuccess({ dispatch }, { username, resp }) {
        // Clear pending oauth token interval
        dispatch('clearStore');

        // They are User authenticated
        dispatch('setAuthenticated', true);

        /* Doing this asap so we have a token to make other calls i.e. roles */
        dispatch('authentication/setAuthToken', resp.data.access_token, { root: true });

        /* Save username for F5.. etc */
        dispatch('app/setUsername', username, { root: true });
      },
      async checkPendingAuth({ dispatch }, { username, password }) {
        try {
          // GET oauth user from ap creds
          const clientTokenResp = await authService.getApClientToken();

          // Set a client token to do some api calls
          dispatch('authentication/setAuthToken', clientTokenResp.data.access_token, { root: true });

          // Get oauth user by username
          const oauthUserResp = await credUserService.getByEmail(username);
          const credUser = new CredUser(oauthUserResp.data);

          if (!this.getters['authentication/verificationExpiresIn']) {
            dispatch('setLogoutTime', credUser.verificationExpiresIn * 1000);
          }

          if (!this.getters['authentication/getPendingInterval']) {
            dispatch('startInterval', { username, password });
          }

          dispatch('setPendingUsername', username);
          dispatch('checkPendingTimeout', Date.now());
        } catch (err) {
          dispatch('clearStore');
          dispatch('badVerification');
        }
      },
      rejectPendingAuth({ dispatch }) {
        dispatch('login/rejectedSecondFactor', null, { root: true });
        dispatch('clearStore');
        dispatch('badVerification');
      },
      badVerification({ dispatch }) {
        dispatch('alertMessage/setAlertMessage', {
          type: 'error',
          message: 'Could not verify your identity. Please try again, or contact your system administrator for assistance.',
        }, { root: true });
      },
      checkPendingTimeout({ dispatch }, time) {
        const diff = time - this.getters['authentication/getPendingTimeout'];

        /* 5 minutes */
        const logoutTime = this.getters['authentication/getLogoutTime'];

        dispatch('setPendingRemaining', logoutTime - diff);

        if (diff >= logoutTime) {
          dispatch('clearPendingInterval');
          dispatch('login/failedSecondFactor', null, { root: true });
        }
      },
      setAuthToken({ commit }, token) {
        commit(types.SET_AUTH_TOKEN, token);
      },
      setAuthenticated({ commit }, val) {
        commit(types.SET_AUTHENTICATED, val);
      },
      removeAuthToken({ commit }) {
        commit(types.REMOVE_AUTH_TOKEN);
      },
      clearStore({ dispatch }) {
        dispatch('removeAuthToken');
        dispatch('setAuthenticated', false);
        dispatch('clearPendingInterval');
        dispatch('setPendingRemaining', null);
        dispatch('setPendingInterval', null);
        dispatch('setPendingTimeout', null);
        dispatch('setPendingUsername', null);
      },
      async deleteVerification() {
        const userId = this.getters['authentication/getPendingUsername'];
        await verificationService.delete(userId);
      },
      clearPendingInterval({ dispatch }) {
        const interval = this.getters['authentication/getPendingInterval'];
        clearInterval(interval);
        dispatch('setPendingInterval', null);
      },
      startInterval({ dispatch }, { username, password }) {
        dispatch('setPendingTimeout', Date.now());

        const interval = setInterval(() => {
          dispatch('fetchAuthToken', { username, password });
        }, 3000);

        dispatch('setPendingInterval', interval);
      },
      setPendingRemaining({ commit }, val) {
        commit(types.SET_PENDING_TIMEOUT_REMAINING, val);
      },
      setPendingInterval({ commit }, interval) {
        commit(types.SET_PENDING_INTERVAL, interval);
      },
      setPendingTimeout({ commit }, val) {
        commit(types.SET_PENDING_TIMEOUT, val);
      },
      setPendingUsername({ commit }, val) {
        commit(types.SET_PENDING_USERNAME, val);
      },
      setLogoutTime({ commit }, seconds) {
        commit(types.SET_PENDING_LOGOUT_TIME, seconds);
      },
    };

    this.mutations = {
      [types.SET_AUTH_TOKEN](state, token) {
        state.token = token;
        sessionStorage.setItem('token', token);
      },
      [types.REMOVE_AUTH_TOKEN](state) {
        state.token = null;
        localStorage.removeItem('token');
      },
      [types.SET_PENDING_TIMEOUT](state, val) {
        state.pendingTimeout = val;
      },
      [types.SET_PENDING_INTERVAL](state, interval) {
        state.pendingInterval = interval;
      },
      [types.SET_PENDING_TIMEOUT_REMAINING](state, val) {
        state.pendingTimeoutRemaining = val;
      },
      [types.SET_PENDING_LOGOUT_TIME](state, seconds) {
        state.logoutTime = seconds;
      },
      [types.SET_AUTHENTICATED](state, val) {
        state.isAuthenticated = val;
      },
      [types.SET_PENDING_USERNAME](state, val) {
        state.pendingUsername = val;
      },
    };
  }
}

export default AuthenticationStore;
