class CredUser {
  constructor(user) {
    this.uuid = user.uuid;
    this.userId = user.userId;
    this.email = user.email;
    this.password = user.password;
    this.verificationRequired = user.verificationRequired;
    this.verificationTenantCode = user.verificationTenantCode;
    this.verificationAppCode = user.verificationAppCode;
    this.verificationTemplate = user.verificationTemplate;
    this.verificationExpiresIn = user.verificationExpiresIn;
    this.verificationMaxResponseAttempts = user.verificationMaxResponseAttempts;
    this.verificationMessage = user.verificationMessage;
  }
}

export default CredUser;
