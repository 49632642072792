class Liveness {
  constructor(liveness) {
    this.name = liveness.name || '';
    this.code = liveness.code || '';
    this.vendor = liveness.vendor || '';
    this.version = liveness.version || '';
  }
}

export default Liveness;
