import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class ServerGroupAlgorithmService extends BaseService {
  static async fetchAll(svrgrpCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg')));
  }

  static async fetch(svrgrpCode, algorithmCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg/')
      .concat(algorithmCode)));
  }

  static async deleteAlgorithm(svrgrpCode, algorithmCode) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg/')
      .concat(algorithmCode)));
  }

  static async addAlgorithm(svrgrpCode, algorithmCode, serverGroupAlg) {
    return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
      .concat('/svrgrp/')
      .concat(svrgrpCode)
      .concat('/alg/')
      .concat(algorithmCode), serverGroupAlg));
  }
}

export default ServerGroupAlgorithmService;
