import * as types from '@/store/mutation-types';
import BaseStoreModule from '@/store/BaseStoreModule';
import Application from '@/models/Application';

const tenantApplications = new BaseStoreModule({ component: 'Tenant Application', sort: { key: 'code', descending: false } });

const myState = {
  db: [],
};

const myActions = {
  async fetchTenantApps({ dispatch }, { service, key, tenantCode }) {
    dispatch('setStoreKey', key);
    const resp = await service.getApplications(tenantCode);
    if (resp.status === 200) {
      const results = resp.data;
      dispatch('setAll', { results });
      // Save these in a separate place so we can use them to compare against
      // the actual selected values from the Tenant Applications field.
      dispatch('setDbApplications', { results });
    }
  },
  async associateApplication({ dispatch, getters }, {
    service,
    tenantCode,
    appCode,
  }) {
    const resp = await service.associateApplication(tenantCode, appCode);

    if (resp.status === 200 || resp.status === 201) {
      dispatch('updateApps', {
        service,
        tenantCode,
        appCode,
      });
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Tenant Application Added',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  async disAssociateApplication({
    state,
    commit,
    dispatch,
    getters,
  }, {
    service,
    tenantCode,
    appCode,
  }) {
    const resp = await service.disAssociateApplication(tenantCode, appCode);
    if (resp.status === 204) {
      const index = state.all.findIndex(item => item.code === appCode);
      commit(types.DELETE, index);
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Tenant Application Removed',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  deleteFromState({ state, commit, getters }, item) {
    // Only delete from state w/o calling a service to
    // delete from the db first.
    const index = getters.getIndexByKey(item[state.key]);
    commit(types.DELETE, index);
  },

  async updateApps({ dispatch }, {
    service,
    tenantCode,
    appCode,
  }) {
    const apps = await service.getApplications(tenantCode);
    const app = apps.data.filter(a => a.code === appCode);
    dispatch('addOneSorted', new Application(app[0]));
  },
  setDbApplications({ commit }, items) {
    commit(types.SET_DB_APPLICATIONS, items.results);
  },
};

const myMutations = {
  [types.SET_DB_APPLICATIONS](state, items) {
    state.db = items;
  },
};

const myGetters = {
  dbTenantApplications: state => state.db,
};

tenantApplications.actions = Object.assign(tenantApplications.actions, myActions);
tenantApplications.mutations = Object.assign(tenantApplications.mutations, myMutations);
tenantApplications.getters = Object.assign(tenantApplications.getters, myGetters);
tenantApplications.state = Object.assign(tenantApplications.state, myState);

export default tenantApplications;
