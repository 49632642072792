class TenantUser {
  constructor(tu) {
    this.userId = tu.userId;
    this.id = tu.id;
    this.data = tu.data;
    this.enrollServer = tu.enrollServer;
    this.tenantData = tu.tenantData;
    this.tenantEnrollServer = tu.tenantEnrollServer;
    this.tenantVerifyServer = tu.tenantVerifyServer;
    this.verifyServer = tu.verifyServer;
    this.voiceDigits = tu.voiceDigits;
  }
}

export default TenantUser;
