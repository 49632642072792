const buttons = {
  manageApns: {
    text: 'Manage APNS',
  },
  credentials: {
    text: 'credentials',
  },
  uploadCert: {
    text: 'Upload APNS Cert',
  },
  copyApns: {
    text: 'Copy Text',
  },
};

export default buttons;
