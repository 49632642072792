class Theme {
  constructor() {
    this.default = {
      primary: '#37474F',
      secondary: '#B2DFDB',
      tertiary: '#E0F2F1',
      background: '#ECEFF1',
      accent: '#009688',
      error: '#C62828',
      warning: '#607D8B',
      info: '#607D8B',
      success: '#009688',
      darkText: '#263238',
      lightText: '#CFD8DC',
    };

    return this.default;
  }
}

export default Theme;
