<template>
  <div>
    <div>
      <WorkspaceActionDial
        :showNew="hasApp"
        showCancel
        showSave
        showImport
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
        @dialImport="importClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container>
            <v-layout row>
              <v-layout column class="mr-4">
                <v-flex xs6>
                  <v-select
                    v-bind="fields.app"
                    :value="getSelectedTemplateApp"
                    :items="tenantApps"
                    itemValue="code"
                    itemText="name"
                    :disabled="appFieldDisabled"
                    @input="setSelectedTemplateApp($event)"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-bind="fields.name" v-model="templateModel.name" />
                </v-flex>
                <v-flex xs6>
                  <BaseSelectChip ref="includedBiometrics"
                    label="Included Biometrics"
                    :value="tenantTemplateBiometrics"
                    :available="transformedTenantBiometrics"
                    itemValue="code"
                    itemText="code"
                    @input="includedBiometricsUpdated"
                    :disabled="templateModel.type !== 'native'"
                    :multiple="includedBiometricsMultiple"
                    :key="reRenderIncluded"
                  />
                </v-flex>
              </v-layout>

              <v-layout column class="mr-2 ml-2">
                <v-flex xs6>
                  <v-select
                    :items="typeSelect"
                    v-bind="fields.type"
                    item-value="value"
                    item-text="text"
                    v-model="templateModel.type"
                    @input="toggleDynamic"
                  />
                  <v-text-field
                    v-bind="fields.body"
                    v-model="templateModel.body"
                    :disabled="templateModel.type !== 'url'"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-select
                    :items="operationSelect"
                    v-bind="fields.algorithmOperation"
                    item-value="value"
                    item-text="text"
                    v-model="templateModel.algorithmOperation"
                    :disabled="templateModel.type !== 'native'"
                    @input="toggleOperationType"
                  />
                </v-flex>
              </v-layout>
            </v-layout>

           <div v-if="showCustom">
            <v-divider></v-divider>
            <p>Message can be over-written via Send Alert</p>
            <v-layout row>
              <v-layout column class="pr-4">
                <v-flex xs6>
                  <v-layout row>
                    <v-checkbox
                      @change="toggleColorValue($event, 'alertTextColor')"
                      v-model="colorFieldsTemp['alertTextColor'].enabled"
                      class="shrink"
                    ></v-checkbox>
                    <v-text-field
                      v-bind="fields.alertTextColor"
                      v-model="templateModel.alertTextColor"
                      :disabled="!colorFieldsTemp['alertTextColor'].enabled"
                      placeholder="#4A4A4A"
                    />
                    <ColorPickerButton
                      v-bind:field-name="'alertTextColor'"
                      v-bind:init-color="templateModel.alertTextColor"
                      v-on:update-color="getUpdatedColor"
                      v-bind:show-picker-container="colorFieldsTemp['alertTextColor'].enabled"
                    ></ColorPickerButton>
                  </v-layout>
                </v-flex>

                <v-flex xs6>
                  <v-layout row>
                    <v-checkbox
                      @change="toggleColorValue($event, 'alertBackgroundColor')"
                      v-model="colorFieldsTemp['alertBackgroundColor'].enabled"
                      class="shrink"></v-checkbox>
                    <v-text-field
                      v-bind="fields.alertBackgroundColor"
                      v-model="templateModel.alertBackgroundColor"
                      :disabled = "!colorFieldsTemp['alertBackgroundColor'].enabled"
                      placeholder="#4A4A4A"
                    />
                    <ColorPickerButton
                      v-bind:field-name="'alertBackgroundColor'"
                      v-bind:init-color="templateModel.alertBackgroundColor"
                      v-on:update-color="getUpdatedColor"
                      v-bind:show-picker-container="colorFieldsTemp['alertBackgroundColor'].enabled"
                    ></ColorPickerButton>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout row>
                    <v-checkbox
                      @change="toggleColorValue($event, 'alertTextboxColor')"
                      v-model="colorFieldsTemp['alertTextboxColor'].enabled"
                      class="shrink"></v-checkbox>
                    <v-text-field
                      v-bind="fields.alertTextboxColor"
                      v-model="templateModel.alertTextboxColor"
                      :disabled="!colorFieldsTemp['alertTextboxColor'].enabled"
                      placeholder="#FFFFFF"
                    />
                    <ColorPickerButton
                      v-bind:field-name="'alertTextboxColor'"
                      v-bind:init-color="templateModel.alertTextboxColor"
                      v-on:update-color="getUpdatedColor"
                      v-bind:show-picker-container="colorFieldsTemp['alertTextboxColor'].enabled"
                    ></ColorPickerButton>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout row wrap>
                    <v-checkbox
                      @change="toggleUrlValue($event, 'backgroundImageUrl', '')"
                      v-model="imageFieldsTemp['backgroundImageUrl'].enabled"
                      class="shrink"
                    ></v-checkbox>
                    <v-text-field
                      v-bind="fields.backgroundImageUrl"
                      v-model="templateModel.backgroundImageUrl"
                      :disabled = "!imageFieldsTemp['backgroundImageUrl'].enabled"
                      placeholder="Background Image URL - leave blank to use background color"
                      persistent-hint/>

                      <v-img class="template-image"
                        v-if="imageFieldsTemp['backgroundImageUrl'].enabled"
                        :src="templateModel.backgroundImageUrl"
                        @click.stop="displayBackgroundImageDialog = true"
                        max-width="30"
                        max-height="30"
                      >
                      </v-img>
                      <v-dialog v-model="displayBackgroundImageDialog" max-width="500">
                        <v-img
                          v-if="imageFieldsTemp['backgroundImageUrl'].enabled"
                          :src="templateModel.backgroundImageUrl"
                        ></v-img>
                      </v-dialog>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout column>
                <v-flex xs6>
                  <v-text-field
                    v-bind="fields.headerText"
                    v-model="templateModel.headerText"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-textarea
                    v-bind="fields.bodyText"
                    v-model="templateModel.bodyText"
                    hint="HTML allowed"
                    persistent-hint
                    rows=3
                  />
                </v-flex>
                <v-flex xs6>
                  <v-layout row wrap>
                    <v-select
                      :items="logoDisplayTypeSelect"
                      v-bind="fields.logoDisplayType"
                      item-value="value"
                      item-text="text"
                      v-model="templateModel.logoDisplayType"
                      @change="toggleLogoDisplayType"
                    />
                    <v-text-field v-show="templateModel.logoDisplayType === 'url'"
                      v-bind="fields.logoUrl"
                      v-model="templateModel.logoUrl"
                      placeholder="Custom Logo URL"
                      persistent-hint
                    />
                    <v-img class="template-image"
                      @click.stop="displayLogoImageDialog = true"
                      v-if="templateModel.logoDisplayType === 'url'"
                      :src="templateModel.logoUrl"
                      max-height="30"
                      max-width="30"
                    ></v-img>
                    <v-dialog v-model="displayLogoImageDialog" max-width="500">
                      <v-img @click.stop="displayLogoImageDialog = true"
                      :src="templateModel.logoUrl"></v-img>
                    </v-dialog>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
            <BaseButton
              v-bind="saveAsDefaultButton"
              @clicked="saveAsDefaultClick"
            />
            <BaseButton
              v-if="hasDefaultTemplate"
              v-bind="applyDefaultsButton"
              @clicked="applyDefaultsClick"
            />
           </div>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
          <TenantTemplateTable
            :templates="appTemplates"
            @appChange="templateAppChange"
            @tenantAppReset="tenantAppReset"
            @rowClick="checkUnsaved"
            @refresh="refresh"
          />
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteTemplateModal"
      componentName="Template"
      @deleteModalConfirm="deleteTemplateModalConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

    <BaseConfirmModal
      :value="showOperationTypeSingleModal"
      title="WARNING!"
      text="This format only allows one Included Biometric. All but the first biometric will be
       removed. Do you want to continue?"
      declineText="Cancel"
      @clicked="toggleOperationTypeSingleConfirm"
    />

    <BaseConfirmModal
      :value="showUnsaved"
      title='Unsaved Changes'
      text='You have made changes to this page that are not saved. Continue and discard changes?'
      declineText="Cancel"
      @clicked="unsavedModalConfirm"
    />

    <TemplateImportModal
      :open="showImportModal"
      @importModalClicked="importClicked"
    />

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields, buttons } from '@/components/config/tenantAppTemplates';
import appTemplateService from '@/services/ApplicationTemplateService';
import Template from '@/models/Template';
import State from '@/models/State';
import tenantTemplateService from '@/services/TenantTemplateService';
import TenantBiometricToPlain from '@/transform/TenantBiometricToPlain';
import RuleGenerator from '@/utils/RuleGenerator';

import _ from 'lodash';

export default {
  name: 'TenantTemplateEdit',
  data: () => ({
    fields,
    typeSelect: [
      {
        value: 'url',
        text: 'URL',
      },
      {
        value: 'native',
        text: 'Dynamic',
      },
    ],
    appearanceSelect: [
      {
        value: 'custom',
        text: 'Custom',
      },
      {
        value: 'goVerifyId',
        text: 'GoVerifyID',
      },
    ],
    operationSelect: [
      {
        value: 'choice',
        text: 'Choice',
      },
      {
        value: 'nested',
        text: 'Nested',
      },
      {
        value: 'single',
        text: 'Single',
      },
    ],
    logoDisplayTypeSelect: [
      {
        value: 'application',
        text: 'Application',
      },
      {
        value: 'none',
        text: 'None',
      },
      {
        value: 'url',
        text: 'URL',
      },
    ],
    templateModel: new Template({}),
    templateModelBase: new Template({}),
    templateModelState: new State({}),
    deleteTemplateModal: false,
    isNew: true,
    showCustom: false,
    showPicker: false,
    showPickerButton: false,
    ...buttons,
    colorFieldsTemp: {
      alertTextColor: {
        value: '',
        enabled: '',
      },
      alertBackgroundColor: {
        value: '',
        enabled: '',
      },
      alertTextboxColor: {
        value: '',
        enabled: '',
      },
    },
    imageFieldsTemp: {
      backgroundImageUrl: {
        value: '',
        enabled: '',
      },
      logoUrl: {
        value: '',
        enabled: '',
      },
    },
    displayBackgroundImageDialog: false,
    displayLogoImageDialog: false,
    includedBiometricsMultiple: true,
    showOperationTypeSingleModal: false,
    reRenderIncluded: 0,
    showUnsaved: false,
    templateModelNew: {},
    showImportModal: false,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getSelectedTemplateApp', 'getUnsaved']),
    ...mapGetters('applicationTemplates', {
      appTemplates: 'getAll',
      defaultTemplate: 'getDefaultTemplate',
      hasDefaultTemplate: 'hasDefaultTemplate',
    }),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    ...mapGetters('tenantBiometrics', {
      tenantBiometrics: 'getAll',
      transformedTenantBiometrics: 'transformedTenantBiometrics',
    }),
    ...mapGetters('tenantTemplateBiometrics', {
      tenantTemplateBiometrics: 'getAll',
      dbTenantBiometrics: 'dbTenantBiometrics',
    }),
    hasApp() {
      return !!(this.getSelectedTemplateApp);
    },
    canDelete() {
      return !!(this.templateModel.name
        && !this.isNew
      );
    },
    appFieldDisabled() {
      // First, check value of isNew.
      if (!this.isNew) {
        return true;
      }
      // We know isNew is true, so now we check to make sure there are at least
      // two apps available.
      if (this.tenantApps.length > 1) {
        return false;
      }

      return true;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    TenantTemplateEdit: () => import('@/components/workspace/tenants/TenantTemplateEdit'),
    TenantTemplateTable: () => import('@/components/workspace/tenants/TenantTemplateTable'),
    ColorPickerButton: () => import('@/components/dialogs/ColorPickerButton.vue'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
    TemplateImportModal: () => import('@/components/workspace/tenants/TemplateImportModal'),
  },
  methods: {
    ...mapActions('app', ['setSelectedTemplateApp']),
    ...mapActions('applicationTemplates', {
      fetchAppTemplates: 'fetchAllBy',
      saveTemplate: 'save',
      deleteTemplate: 'delete',
      clearAppTemplates: 'clearAll',
      setDefault: 'setDefaultTemplate',
      fetchDefaultTemplate: 'fetchDefaultTemplate',
      clearDefaultTemplate: 'clearDefault',
    }),
    ...mapActions('tenantApplications', {
      fetchTenantApps: 'fetchTenantApps',
      clearTenantApps: 'clearAll',
    }),
    ...mapActions('tenantTemplateBiometrics', {
      fetchTenantTemplateBiometrics: 'fetchTenantTemplateBiometrics',
      saveTemplateBiometric: 'save',
      associateTenantTemplateBiometric: 'associateTenantTemplateBiometric',
      disAssociateTenantTemplateBiometric: 'disAssociateTenantTemplateBiometric',
      clearIncludedBiometrics: 'clearAll',
      clearDbBiometrics: 'clearDbBiometrics',
      addTenantTemplateBiometric: 'addToState',
      deleteTenantTemplateBiometric: 'deleteFromState',
    }),
    ...mapActions('tenantBiometrics', {
      fetchTenantBiometrics: 'fetchBiometrics',
    }),
    resetTemplateModel() {
      if (this.appTemplates.length > 0) {
        this.setTemplate(this.appTemplates[0]);
      } else {
        this.setTemplate({});
      }
    },
    checkUnsaved(template) {
      if (this.getUnsaved) {
        this.showUnsaved = true;
        // Stash the target template.
        this.templateModelNew = template;
      } else {
        this.setTemplate(template);
      }
    },
    setTemplate(template) {
      this.templateModel = new Template(template);
      if (this.isNew) {
        this.templateModel.applyDefaults();
      }
      this.templateModelBase = new Template(template);
      this.templateModelState = new State({
        original: template,
      });
      // Before we set the template, we need to check the color fields
      // and set the corresponding checkboxes based on the values.
      if (this.templateModel.alertTextColor.length > 0) {
        const colorFields = ['alertTextColor', 'alertBackgroundColor', 'alertTextboxColor'];
        colorFields.forEach((colorField) => {
          if (template[colorField] === 'NULL') {
            this.colorFieldsTemp[colorField].enabled = false;
            this.colorFieldsTemp[colorField].value = '#4A4A4A';
          } else {
            this.colorFieldsTemp[colorField].enabled = true;
            // Load the current color so it can be recalled if needed.
            this.colorFieldsTemp[colorField].value = template[colorField];
          }
        });
        const imageFields = ['backgroundImageUrl', 'logoUrl'];
        imageFields.forEach((imageField) => {
          if (template[imageField] === '') {
            this.imageFieldsTemp[imageField].enabled = false;
            this.imageFieldsTemp[imageField].value = '';
          } else {
            this.imageFieldsTemp[imageField].enabled = true;
            // Load the current url so it can be recalled if needed.
            this.imageFieldsTemp[imageField].value = template[imageField];
          }
        });
        // Within this block we know that we are editing an existing template,
        // so we can set isNew to false;
        this.isNew = false;
      }
      // this.showCustom controls display of the dynamic fields.
      this.toggleDynamic(this.templateModel.type);
      // Set tenantTemplate state
      // We only want to get template biometrics if this template has actually been
      // saved to GMI, so we check to make sure it already exists.
      // eslint-disable-next-line max-len
      const templateMatch = this.appTemplates.find(appTemplate => appTemplate.name === this.templateModel.name);
      // templateMatch is undefined if no match was found.
      if (typeof templateMatch !== 'undefined') {
        this.getTemplateBiometrics(template);
      }
      this.setUnsaved(false);
      this.includedBiometricsMultiple = template.algorithmOperation !== 'single';
    },
    cancelClick() {
      this.setTemplate(this.templateModelState.original);
    },
    async newClick() {
      this.isNew = true;
      this.setTemplate({});
      await this.clearIncludedBiometrics();
      await this.clearDbBiometrics();
    },
    async tenantAppReset() {
      await this.setComponent();
    },
    async templateAppChange(appCode) {
      this.setSelectedTemplateApp(appCode);
      // Get the list of templates for the selected app.
      await this.fetchAppTemplates({
        service: appTemplateService,
        key: 'name',
        fetchBy: {
          appCode,
          tenantCode: this.getStickyTenant,
        },
      });

      this.resetTemplateModel();

      if (this.hasApp) {
        // Reset tenant template biometrics
        await this.clearIncludedBiometrics();
        await this.fetchDefaultTemplate({
          service: appTemplateService,
          key: 'name',
          tenantCode: this.getStickyTenant,
          appCode: this.getSelectedTemplateApp,
        });
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.saveTemplate({
          service: appTemplateService,
          item: {
            template: this.templateModel,
            tenantCode: this.getStickyTenant,
            appCode: this.getSelectedTemplateApp,
          },
        });

        this.saveTemplateBiometrics();

        await this.fetchAppTemplates({
          service: appTemplateService,
          key: 'name',
          fetchBy: {
            appCode: this.getSelectedTemplateApp,
            tenantCode: this.getStickyTenant,
          },
        });

        this.isNew = false;
        this.setUnsaved(false);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async saveTemplateBiometrics() {
      // We have to separately save the biometrics from the
      // Included Biometrics field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;
      // First, we have to delete all of the current biometrics records in the db
      // so that we can insert the new records with the proper sequence.
      if (this.dbTenantBiometrics.length > 0) {
        await vm.dbTenantBiometrics.forEach((item) => {
          vm.disAssociateTenantTemplateBiometric({
            service: tenantTemplateService,
            tenantCode: vm.getStickyTenant,
            appCode: vm.getSelectedTemplateApp,
            templateCode: vm.templateModel.name,
            captureType: item.captureType,
          });
        });
      }

      // Next, we add the currently selected list of biometrics.;
      await this.tenantTemplateBiometrics.forEach((bio, index) => {
        const body = {
          sequence: index,
          captureType: bio.captureType,
          algCode: bio.algCode,
          code: bio.code,
        };

        vm.associateTenantTemplateBiometric({
          service: tenantTemplateService,
          tenantCode: vm.getStickyTenant,
          appCode: vm.getSelectedTemplateApp,
          templateCode: vm.templateModel.name,
          captureType: bio.captureType,
          biometric: body,
        });
      });
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteTemplateModal = !this.deleteTemplateModal;
      }
    },
    importClick() {
      this.showImportModal = true;
    },
    deleteTemplateModalConfirm(confirm) {
      if (confirm) {
        const vm = this;
        // Delete the biometrics that are currently in the db.
        this.dbTenantBiometrics.forEach((item) => {
          vm.disAssociateTenantTemplateBiometric({
            service: tenantTemplateService,
            tenantCode: vm.getStickyTenant,
            appCode: vm.getSelectedTemplateApp,
            templateCode: vm.templateModel.name,
            captureType: item.captureType,
          });
        });

        // Clear any template biometrics from state.
        this.clearIncludedBiometrics();

        this.deleteTemplate({
          service: appTemplateService,
          item: {
            template: this.templateModel,
            tenantCode: this.getStickyTenant,
            appCode: this.getSelectedTemplateApp,
          },
        });

        this.fetchAppTemplates({
          service: appTemplateService,
          key: 'name',
          fetchBy: {
            appCode: this.getSelectedTemplateApp,
            tenantCode: this.getStickyTenant,
          },
        });
        this.setTemplate({});
      }
      this.deleteTemplateModal = false;
    },
    async setComponent() {
      await this.clearAppTemplates();
      await this.clearIncludedBiometrics();
      await this.clearDefaultTemplate();
      if (this.getSelectedTemplateApp) {
        await this.templateAppChange(this.getSelectedTemplateApp);
      }
      this.setTabs('Tenants', 'tab-templates');

      if (this.getSelectedTemplateApp) {
        await this.fetchTenantTemplateBiometrics({
          service: tenantTemplateService,
          key: 'algCode',
          tenantCode: this.getStickyTenant,
          appCode: this.getSelectedTemplateApp,
          templateName: this.templateModel.name,
          Transform: TenantBiometricToPlain,
        });
      }
      // There may or may not be templates for this tenant, so
      // we only set the tenplate if there are.
      if (this.appTemplates.length > 0) {
        this.setTemplate(this.appTemplates[0]);
      }
    },
    toggleDynamic(value) {
      this.showCustom = value === 'native';
    },
    getUpdatedColor(colorValue, fieldName) {
      this.templateModel[fieldName] = colorValue;
    },
    async includedBiometricsUpdated(biometrics) {
      // If algorithmOperation === 'single', the passed value is an object, so
      // we have to convert it to an array.
      const currentBiometrics = Array.isArray(biometrics) ? biometrics : [biometrics];
      if (currentBiometrics.length > this.tenantTemplateBiometrics.length) {
        const newBiometric = this.findMissing(currentBiometrics, this.tenantTemplateBiometrics, 'code');
        // Create the object with the field data for the biometric.
        const body = {
          sequence: 0,
          captureType: newBiometric.captureType,
          algCode: newBiometric.code,
          code: newBiometric.code,
        };
        // Instead of immediately adding to the db via TenantTemplateService, we just add the
        // item to state.
        this.addTenantTemplateBiometric(body);
      } else if (currentBiometrics.length < this.tenantTemplateBiometrics.length) {
        const biometric = this.findMissing(this.tenantTemplateBiometrics, currentBiometrics, 'code');
        // Instead of immediately deleting from the db via TenantTemplateService, we just remove the
        // item from state.
        this.deleteTenantTemplateBiometric(biometric);
      }
    // await this.getTemplateBiometrics(this.getStickyTenant);
    },
    async getTemplateBiometrics() {
      if (this.templateModel.name.length > 0) {
        await this.fetchTenantTemplateBiometrics({
          service: tenantTemplateService,
          key: 'algCode',
          tenantCode: this.getStickyTenant,
          appCode: this.getSelectedTemplateApp,
          templateName: this.templateModel.name,
          Transform: TenantBiometricToPlain,
        });
      }
    },
    toggleColorValue(val, field) {
      if (val === false) {
        // First, get the current fields value.
        const oldValue = this.templateModel[field];
        // Store it so we can get it back later.
        this.colorFieldsTemp[field].value = oldValue;
        // If the value is false, that means the box is unchecked,
        // so we set the color value to NULL and disable it.
        this.templateModel[field] = 'NULL';
        this.colorFieldsTemp[field].enabled = false;
        // Remove any rules for this field.
        this.fields[field].rules = [];
      } else {
        // The value is true, so the box is checked. We need to get the
        // previous hex color value from state.
        this.templateModel[field] = this.colorFieldsTemp[field].value;
        this.colorFieldsTemp[field].enabled = true;
        // Re-apply any defined rules;
        const ruleSet = new RuleGenerator(field).setHex();
        const rules = ruleSet.getRules();
        this.fields[field].rules = rules;
      }
    },
    toggleUrlValue(checkboxVal, field, urlVal) {
      if (checkboxVal === false) {
        // First, get the current fields value.
        const oldValue = this.templateModel[field];
        // Store it so we can get it back later.
        this.imageFieldsTemp[field].value = oldValue;
        // If the value is false, that means the box is unchecked,
        // so we set the color value to NULL and disable it.
        this.templateModel[field] = urlVal;
        this.imageFieldsTemp[field].enabled = false;
        // Remove any rules for this field.
        this.fields[field].rules = [];
      } else {
        // The value is true, so the box is checked. We need to get the
        // previous hex color value from state.
        this.templateModel[field] = this.imageFieldsTemp[field].value;
        this.imageFieldsTemp[field].enabled = true;
        // Re-apply any defined rules;
        const ruleSet = new RuleGenerator('Background Image URL').setUrl();
        const rules = ruleSet.getRules();
        this.fields[field].rules = rules;
      }
    },
    toggleLogoDisplayType() {
      if (this.templateModel.logoDisplayType !== 'url') {
        // First, get the current fields value.
        const oldValue = this.templateModel.logoUrl;
        // Store it so we can get it back later.
        this.imageFieldsTemp.logoUrl.value = oldValue;
        // If the value is false, that means the box is unchecked,
        // so we set the color value to NULL and disable it.
        this.templateModel.logoUrl = '';
        this.imageFieldsTemp.logoUrl.enabled = false;
        // Apply the isUrl rule;
        const ruleSet = new RuleGenerator('Logo URL').setUrl();
        const rules = ruleSet.getRules();
        this.fields.logoUrl.rules = rules;
      } else {
        // The value is true, so the box is checked. We need to get the
        // previous hex color value from state.
        this.templateModel.logoUrl = this.imageFieldsTemp.logoUrl.value;
        // Add required setting for URL field.
        const ruleSet = new RuleGenerator('Logo URL').setUrl();
        ruleSet.setRequired();
        const rules = ruleSet.getRules();
        this.fields.logoUrl.rules = rules;
        this.imageFieldsTemp.logoUrl.enabled = true;
      }
    },
    async saveAsDefaultClick() {
      if (this.$refs.form.validate()) {
        await this.setDefault({
          service: appTemplateService,
          tenantCode: this.getStickyTenant,
          appCode: this.getSelectedTemplateApp,
          template: this.templateModel,
        });
      }
    },
    applyDefaultsClick() {
      const model = this.templateModel;
      // We only care about the dynamic template values.
      model.alertBackgroundColor = this.defaultTemplate.alertBackgroundColor;
      model.alertTextColor = this.defaultTemplate.alertTextColor;
      model.alertTextboxColor = this.defaultTemplate.alertTextboxColor;
      model.logoUrl = this.defaultTemplate.logoUrl;
      model.logoDisplayType = this.defaultTemplate.logoDisplayType;
      model.backgroundImageUrl = this.defaultTemplate.backgroundImageUrl;
      model.bodyText = this.defaultTemplate.bodyText;
      model.headerText = this.defaultTemplate.headerText;
      // Set the template.
      this.setTemplate(model);
    },
    toggleOperationType(opType) {
      const vm = this;
      // Before setting the multiple value on the field, we need
      // to check to see how many options are already selected in the field.
      if (opType === 'single') {
        if (this.tenantTemplateBiometrics.length > 1) {
        // Display warning dialog to user.
          vm.showOperationTypeSingleModal = true;
        } else {
          // There are no values yes, so just set multiple = false
          this.includedBiometricsMultiple = false;
        }
      }
      if (opType !== 'single') {
        vm.showOperationTypeSingleModal = false;
        vm.includedBiometricsMultiple = true;
        // Force the child component to be re-rendered.
        this.reRenderIncluded += 1;
      }
    },
    toggleOperationTypeSingleConfirm(confirm) {
      const vm = this;
      if (confirm) {
        const newBiometricsArray = this.tenantTemplateBiometrics.map(bio => bio);
        const reversedArray = newBiometricsArray.reverse();
        reversedArray.forEach((biometric, index) => {
          if (index < reversedArray.length - 1) {
            vm.$refs.includedBiometrics.remove(biometric);
          }
        });
        // Next, set the Included Biometrics field to only allow one selection.
        this.includedBiometricsMultiple = this.templateModel.algorithmOperation !== 'single';
        // Force the child component to be re-rendered.
        this.reRenderIncluded += 1;
      }
      // Close the modal, regardless of whether it is confirmed or canceled.
      this.showOperationTypeSingleModal = false;
    },
    unsavedModalConfirm(confirm) {
      if (confirm) {
        if (!(Object.entries(this.templateModelNew).length === 0
          && this.templateModelNew.constructor === Object)) {
          this.setComponent();
        } else {
          this.setTemplate(this.templateModelNew);
          this.templateModelNew = {};
        }
      }
      this.showUnsaved = false;
    },
    importClicked(val) {
      // if (!val) {
      this.showImportModal = false;
      // }
      this.fetchAppTemplates({
        service: appTemplateService,
        key: 'name',
        fetchBy: {
          appCode: this.getSelectedTemplateApp,
          tenantCode: this.getStickyTenant,
        },
      });
    },
  },
  async mounted() {
    this.setComponent();
  },
  watch: {
    templateModel: {
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.templateModelBase)) {
          this.setUnsaved(true);
        }
      },
      deep: true,
    },
  },
  mixins: [baseEditMixin],
};
</script>

<style scoped>
  .template-image {
    outline: 3px solid #C9C9C9;
    position: relative;
    display: inline-block;
    top: 16px;
  }
</style>
