import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: RuleGenerator.getCodeLabel(),
    rules: RuleGenerator.getCodeRules(),
    hint: RuleGenerator.getCodeHint(),
    counter: RuleGenerator.getCodeCounter(),
  },
  name: {
    label: 'Name',
    rules: new RuleGenerator('Name')
      .getRules(),
    counter: '255',
  },
  firebaseKey: {
    label: 'Firebase Key',
    rules: new RuleGenerator('Firebase Key')
      .getRules(),
    counter: '255 ',
  },
};

export default fields;
