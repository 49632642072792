const filePicker = {
  data: () => ({
    fileData: null,
  }),
  methods: {
    onFilePicked(event) {
      const files = event.target.files || event.dataTransfer.files;

      if (files && files[0]) {
        const filename = files[0].name;

        if (filename && filename.lastIndexOf('.') <= 0) {
          return;
        }

        this.fileData = files;
      }
    },
  },
};

export default filePicker;
