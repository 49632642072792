const buttons = {
  biometricsButton: {
    text: 'Biometric Files',
  },
  detailsButton: {
    text: 'View Details',
  },
};

export default buttons;
