<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        showSave
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.name" v-model="appModel.name" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.code" v-model="appModel.code" :disabled="!isNew" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.firebaseKey" v-model="appModel.firebaseKey" />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="environmentSelect"
                  v-bind="fields.environment"
                  item-value="value"
                  item-text="text"
                  v-model="appModel.environment"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="secondFactorSelect"
                  v-bind="fields.validationType"
                  item-value="value"
                  item-text="text"
                  v-model="appModel.validationType"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.validationInfo"
                  v-model="appModel.validationInfo" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.validationFromAlias"
                  v-model="appModel.validationFromAlias" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.validationFailInfo"
                  v-model="appModel.validationFailInfo" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.validationSuccessInfo"
                  v-model="appModel.validationSuccessInfo" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.alertText"
                   v-model="appModel.alertText"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-bind="fields.alertSound"
                  v-model="appModel.alertSound"
                  :items="alertSoundSelect"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
              <v-layout column>
                <v-flex xs6>
                  <v-text-field v-bind="fields.apnsCertificate"
                    v-model="appModel.apnsCertificate" disabled />
                </v-flex>
                <v-flex xs6>
                  <BaseButton
                    v-bind="copyApns"
                    @clicked="apnsClip"
                  />
                </v-flex>
              </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout column>
                  <v-flex xs6>
                    <v-text-field v-bind="fields.apnsCertificateTest"
                                  v-model="appModel.apnsCertificateTest" disabled />
                  </v-flex>
                  <v-flex xs6>
                    <BaseButton
                      v-bind="copyApns"
                      @clicked="apnsTestClip"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <div>
          <BaseButton
            v-if="hasCode"
            v-bind="uploadCert"
            color="accent"
            icon="cloud_upload"
            @clicked="apnsIsOpen = !apnsIsOpen"
          />

          <ApnsDialog
            :open="apnsIsOpen"
            @clicked="apnsIsOpen = !apnsIsOpen"
            @accepted="apnsAccepted"
            @closed="apnsIsOpen = !apnsIsOpen"
          />
        </div>
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteAppModal"
      componentName="Application"
      @deleteModalConfirm="deleteAppConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

    <Section>
      <div slot="contents">
        <ServerAppsTable
          :apps="allApplications"
          @rowClick="setApp"
          @refresh="refresh"
          @menuClick="appMenuClick"
        />
      </div>
    </Section>

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields, buttons } from '@/components/config/applications';
import applicationService from '@/services/ApplicationService';
import Application from '@/models/Application';
import State from '@/models/State';

import _ from 'lodash';

export default {
  name: 'ServerAppsEdit',
  data: () => ({
    appModel: new Application({}),
    appModelBase: new Application({}),
    appModelState: new State({}),
    deleteAppModal: false,
    apnsIsOpen: false,
    fields,
    ...buttons,
    environmentSelect: [
      {
        value: 'PROD',
        text: 'Production',
      },
      {
        value: 'TEST',
        text: 'Test',
      },
    ],
    secondFactorSelect: [
      {
        value: 'email',
        text: 'Email',
      },
      {
        value: 'tenant',
        text: 'Tenant',
      },
    ],
    alertSoundSelect: [
      {
        value: 'default',
        text: 'Default',
      },
    ],
    pass1: true,
    pass2: true,
    confirmPassword: null,
    isNew: false,
  }),
  computed: {
    ...mapGetters('applications', {
      allApplications: 'getAll',
      getFirst: 'getFirst',
    }),
    hasCode() {
      return !!(this.appModel.code);
    },
    canDelete() {
      return !!(this.appModel.code && !this.isNew);
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
    ServerAppsEdit: () => import('@/components/workspace/server/ServerAppsEdit'),
    ServerAppsTable: () => import('@/components/workspace/server/ServerAppsTable'),
    ApnsDialog: () => import('@/components/dialogs/ApnsDialog'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
  },
  methods: {
    ...mapActions('applications', {
      fetchAllApplications: 'fetchAll',
      deleteApplication: 'delete',
      saveApplication: 'save',
    }),
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    ...mapActions('app', {
      setStickyTenantDisabled: 'setStickyTenantDisabled',
    }),
    setApp(app) {
      this.appModel = new Application(app);
      this.appModelBase = new Application(app);
      this.appModelState = new State({
        original: app,
      });
      this.setUnsaved(false);
    },
    appMenuClick() {
      this.setApp({});
      this.appModelState = new State({});
    },
    cancelClick() {
      this.setApp(this.appModelState.original);
    },
    newClick() {
      this.setApp({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteAppModal = !this.deleteAppModal;
      }
    },
    setApnsCert(certStr, isProd) {
      const b64 = certStr.substr(certStr.indexOf(',') + 1);

      if (isProd) {
        this.appModel.apnsCertificate = b64;
      } else {
        this.appModel.apnsCertificateTest = b64;
      }
    },
    async apnsUpload(apnsConfig) {
      const self = this;
      const isProd = (apnsConfig.type === 'prod');
      const stringPartObj = {
        apnsCertificatePassword: apnsConfig.password,
      };
      const stringPart = JSON.stringify(stringPartObj);

      const data = new FormData();
      data.append('stringPart', stringPart);
      data.append('file', new Blob([apnsConfig.file]), { type: 'text/plain' });

      let res = null;
      if (isProd) {
        res = await applicationService.uploadApnsCert(Object.assign({}, this.appModel), data);
      } else {
        res = await applicationService.uploadTestApnsCert(Object.assign({}, this.appModel), data);
      }

      if (res.status === 200) {
        this.appModel.apnsCertificatePassword = apnsConfig.password;

        const reader = new FileReader();
        reader.onload = (event) => {
          self.setApnsCert(event.target.result, isProd);
        };
        reader.readAsDataURL(apnsConfig.file);

        if (isProd) {
          this.setAlertMessage({
            type: 'success',
            message: 'Added Production APNS Certificate',
          });
        } else {
          this.setAlertMessage({
            type: 'success',
            message: 'Added Test APNS Certificate',
          });
        }
      } else {
        this.setAlertMessage({
          type: 'error',
          message: 'Error uploading Certificate',
        });
      }
    },
    async saveAppClick(app) {
      await this.saveApplication({
        service: applicationService,
        item: app,
      });
      this.isNew = false;
    },
    async deleteAppConfirm(confirm) {
      if (confirm) {
        await this.deleteApplication({
          service: applicationService,
          item: this.appModel,
        });
        this.setApp({});
      }
      this.deleteAppModal = false;
    },
    async apnsClip() {
      const res = await this.$copyText(this.appModel.apnsCertificate);
      if (res.text) {
        this.setAlertMessage({
          type: 'success',
          message: 'Copied APNS Certificate to the clipboard.',
        });
      }
    },
    async apnsTestClip() {
      const res = await this.$copyText(this.appModel.apnsCertificateTest);
      if (res.text) {
        this.setAlertMessage({
          type: 'success',
          message: 'Copied APNS Test Certificate to the clipboard.',
        });
      }
    },
    apnsAccepted(obj) {
      this.apnsUpload(obj);
    },
    credsClick() {
      this.$router.push({ name: 'ServerCredsEdit' });
    },
    saveClick() {
      if (!this.equalPasswords()) {
        this.setAlertMessage({
          type: 'error',
          message: 'Passwords must match',
        });
      } else if (this.$refs.form.validate()) {
        if (!this.appModel.name) {
          this.appModel.name = this.appModel.code;
        }
        this.saveAppClick(this.appModel);
        this.setUnsaved(false);
      } else {
        this.showValidateFailModal = true;
      }
    },
    equalPasswords() {
      return this.appModel.apnsCertificatePassword === this.confirmPassword;
    },
    async setComponent() {
      this.setTabs('Server Configuration', 'tab-global');
      this.setStickyTenantDisabled(true);
      await this.fetchAllApplications({ service: applicationService, key: 'code' });
      this.setApp(this.getFirst);
    },
  },
  watch: {
    appModel: {
      deep: true,
      handler(model) {
        this.confirmPassword = model.apnsCertificatePassword;
        // Compare objects
        if (!_.isEqual(model, this.appModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
