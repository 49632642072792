<template>
  <Section class="login">
    <v-container text-xs-center slot="contents">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat>
            <v-img
              src="/images/Logo.png"
              height="315px"
            >
            </v-img>
          </v-card>
        </v-flex>
        <v-flex xs12 class="title pt-1">
          Forgot password
        </v-flex>
        <v-flex xs12 class="caption pt-3">
          Password reset will be sent to your email.
        </v-flex>
        <v-flex xs12>
          <form @submit.prevent="checkCreds">
            <v-layout column>
              <v-flex>
                <BaseControlInput
                  label="Email"
                  name="email"
                  type="email"
                  v-model="username"
                  required
                />
              </v-flex>
              <v-flex class="text-xs-center" mt-5>
                <v-btn type="submit">Send</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>
    </v-container>

    <TheAlertMessage />

  </Section>

</template>

<style scoped>
  .login {
    max-width: 500px;
    max-height: 900px;
    margin: auto;
  }
</style>

<script>
export default {
  name: 'TheForgetPassword',
  data: () => ({
    username: '',
  }),
  components: {
    TheAlertMessage: () => import('@/components/TheAlertMessage'),
    Section: () => import('@/components/layout/Section'),
    BaseControlInput: () => import('@/components/base/BaseControlInput'),
  },
};
</script>
