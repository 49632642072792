const headers = [
  {
    text: 'Username',
    value: 'userId',
  },
  {
    text: 'ID',
    value: 'id',
  },
  {
    text: 'Alerts',
    value: 'icon',
  },
];

export default headers;
