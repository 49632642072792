import BaseStoreModule from '@/store/BaseStoreModule';
import * as types from '@/store/mutation-types';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const tenantTemplateBiometrics = new BaseStoreModule({ component: 'Tenant Template Biometric', sort: { key: 'sequence', descending: false } });

const myState = {
  db: [],
};

const myActions = {
  async fetchTenantTemplateBiometrics({ dispatch }, {
    service,
    key,
    tenantCode,
    appCode,
    templateName,
  //  Transform,
  }) {
    if (templateName) {
      dispatch('setStoreKey', key);
      const resp = await service.getTemplateBiometrics(tenantCode, appCode, templateName);
      if (resp.status === 200) {
        const results = resp.data;

        // The BaseSelectChip displays a "code" value, so we have to add one
        // here since the returned data doesn't contain that value.
        results.map((result) => {
          const newResult = result;
          newResult.code = result.algCode;
          return newResult;
        });

        dispatch('setAll', { results });
        // Save these in a separate place so we can use them to compare against
        // the actual selected values from the Included Biometrics field.
        dispatch('setDbBiometrics', { results });
      }
    }
  },
  async associateTenantTemplateBiometric({ dispatch, getters }, {
    service,
    tenantCode,
    appCode,
    templateCode,
    captureType,
    biometric,
  }) {
    const resp = await service.associateTenantTemplateBiometric(
      tenantCode,
      appCode,
      templateCode,
      captureType,
      biometric,
    );
    if (!(resp.status === 200 || resp.status === 201)) {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  async disAssociateTenantTemplateBiometric({
    dispatch,
    getters,
  }, {
    service,
    tenantCode,
    appCode,
    templateCode,
    captureType,
  }) {
    const resp = await service.deleteTenantTemplateBiometric(
      tenantCode,
      appCode,
      templateCode,
      captureType,
    );
    if (resp.status !== 204) {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  deleteFromState({ state, commit, getters }, item) {
    // Only delete from state w/o calling a service to
    // delete from the db first.
    const index = getters.getIndexByKey(item[state.key]);
    commit(types.DELETE, index);
  },
  addToState({ dispatch }, item) {
    // Only add to state w/o calling a service to add to the db first.
    dispatch('addOneSorted', item);
  },
  setDbBiometrics({ commit }, items) {
    commit(types.SET_DB_BIOMETRICS, items.results);
  },
  clearDbBiometrics({ commit }) {
    commit(types.SET_DB_BIOMETRICS, []);
  },
};

const myMutations = {
  [types.SET_DB_BIOMETRICS](state, items) {
    state.db = items;
  },
};

const myGetters = {
  dbTenantBiometrics: state => state.db,
};

tenantTemplateBiometrics.actions = Object.assign(tenantTemplateBiometrics.actions, myActions);
tenantTemplateBiometrics.getters = Object.assign(tenantTemplateBiometrics.getters, myGetters);
tenantTemplateBiometrics.mutations = Object.assign(tenantTemplateBiometrics.mutations, myMutations);
tenantTemplateBiometrics.state = Object.assign(tenantTemplateBiometrics.state, myState);

export default tenantTemplateBiometrics;
