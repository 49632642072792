import BaseStoreModule from '@/store/BaseStoreModule';
import * as types from '@/store/mutation-types';

const applicationTemplates = new BaseStoreModule({ component: 'Application Templates', sort: { key: 'name', descending: false } });

const myState = {
  defaultTemplate: {},
  hasDefaultTemplate: {
    type: Boolean,
    value: false,
  },
};

const myActions = {
  async setDefaultTemplate({ dispatch, getters }, {
    service,
    tenantCode,
    appCode,
    template,
  }) {
    const resp = await service.saveDefaultTemplate(
      tenantCode,
      appCode,
      template,
    );
    if (resp.status === 200 || resp.status === 201) {
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Default Template Saved',
      }, { root: true });
      dispatch('setDefault', template);
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  async fetchDefaultTemplate({ dispatch }, {
    service,
    key,
    tenantCode,
    appCode,
  }) {
    dispatch('setStoreKey', key);
    const resp = await service.getAllTemplatesWithDefault(tenantCode, appCode);
    if (resp.status === 200) {
      const results = resp.data;
      // The result set has all templates including the default, so we have to
      // filter the results and check for one with a type of 'default'.
      const defaultTemplate = results.filter(template => template.type === 'default');

      if (defaultTemplate.length === 1) {
        // The filter method returns a numerically keyed array, so since we
        // know that there will only be one value, we get teh item at [0].
        dispatch('setDefault', defaultTemplate[0]);
      } else {
        // There is no default template for this tenant/app combo, so we
        // need to clear out the default template and set hasDefaultTemplate = falsel
        dispatch('clearDefault');
      }
    }
  },
  setDefault({ commit }, template) {
    commit(types.SET_DEFAULT_TEMPLATE, template);
  },
  clearDefault({ commit }) {
    commit(types.CLEAR_DEFAULT_TEMPLATE);
  },
};

const myGetters = {
  getDefaultTemplate: state => state.defaultTemplate,
  hasDefaultTemplate: state => state.hasDefaultTemplate,
};

const myMutations = {
  [types.SET_DEFAULT_TEMPLATE](state, template) {
    state.defaultTemplate = template;
    state.hasDefaultTemplate = true;
  },
  [types.CLEAR_DEFAULT_TEMPLATE](state) {
    state.defaultTemplate = {};
    state.hasDefaultTemplate = false;
  },
};

applicationTemplates.actions = Object.assign(applicationTemplates.actions, myActions);
applicationTemplates.getters = Object.assign(applicationTemplates.getters, myGetters);
applicationTemplates.mutations = Object.assign(applicationTemplates.mutations, myMutations);
applicationTemplates.state = Object.assign(applicationTemplates.state, myState);

export default applicationTemplates;
