class BasicEnrollment {
  constructor(be) {
    this.bioType = be.bioType || '';
    this.captureType = be.captureType || '';
    this.algCode = be.algCode || '';
    this.tenantCode = be.tenantCode || '';
    this.enrollTimestamp = be.enrollTimestamp || '';
  }
}

export default BasicEnrollment;
