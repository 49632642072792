import BaseStoreModule from '@/store/BaseStoreModule';
import * as types from '@/store/mutation-types';
import TemplateBiometricIncluded from '@/transform/TemplateBiometricIncluded';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }]
*/

const tenantBiometrics = new BaseStoreModule({ component: 'Tenant Biometric', sort: { key: 'code', descending: false } });

const myState = {
  db: [],
};

const myActions = {
  async fetchBiometrics({ dispatch }, {
    service,
    key,
    tenantCode,
    Transform,
  }) {
    dispatch('setStoreKey', key);
    const resp = await service.getAlgorithms(tenantCode);
    if (resp.status === 200) {
      let results = resp.data;

      if (Transform) {
        results = results.map(res => new Transform(res));
      }

      dispatch('setAll', { results });
      // Save these in a separate place so we can use them to compare against
      // the actual selected values from the Included Biometrics field.
      dispatch('setDbBiometrics', { results });
    }
  },

  async associateTenantBiometric({ dispatch, getters }, {
    service,
    tenantCode,
    serverGroupCode,
    biometricCode,
    threshold,
  }) {
    const resp = await service.associateTenantBiometricAlgorithm(
      tenantCode,
      serverGroupCode,
      biometricCode,
      threshold,
    );
    if (resp.status === 200 || resp.status === 201) {
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Tenant Biometric Added',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  async disAssociateTenantBiometric({
    dispatch,
    getters,
  }, {
    service,
    tenantCode,
    serverGroupCode,
    biometricCode,
  }) {
    const resp = await service.disAssociateTenantBiometricAlgorithm(
      tenantCode,
      serverGroupCode,
      biometricCode,
    );
    if (resp.status === 204) {
      dispatch('alertMessage/setAlertMessage', {
        type: 'success',
        message: 'Tenant Biometric Removed',
      }, { root: true });
    } else {
      dispatch('alertMessage/setApiResponse', {
        resp,
        component: getters.getComponent,
      }, { root: true });
    }
  },
  deleteFromState({ state, commit, getters }, item) {
    // Only delete from state w/o calling a service to
    // delete from the db first.
    const index = getters.getIndexByKey(item[state.key]);
    commit(types.DELETE, index);
  },
  addToState({ dispatch }, item) {
    // Only add to state w/o calling a service to add to the db first.
    dispatch('addOneSorted', item);
  },
  setDbBiometrics({ commit }, items) {
    commit(types.SET_DB_BIOMETRICS, items.results);
  },
};

const myMutations = {
  [types.SET_DB_BIOMETRICS](state, items) {
    state.db = items;
  },
};

const myGetters = {
  transformedTenantBiometrics: (state) => {
    const originalTenantBiometrics = state.all;
    const results = originalTenantBiometrics.map(bio => new TemplateBiometricIncluded(bio));
    return results;
  },
  dbTenantBiometrics: state => state.db,
};

tenantBiometrics.actions = Object.assign(tenantBiometrics.actions, myActions);
tenantBiometrics.mutations = Object.assign(tenantBiometrics.mutations, myMutations);
tenantBiometrics.getters = Object.assign(tenantBiometrics.getters, myGetters);
tenantBiometrics.state = Object.assign(tenantBiometrics.state, myState);

export default tenantBiometrics;
