import RuleGenerator from '@/utils/RuleGenerator';

const sendFields = {
  userId: {
    label: 'Username',
    rules: new RuleGenerator('Username')
      .setRequired()
      .getRules(),
    counter: '255',
  },
  template: {
    label: 'Template',
    rules: new RuleGenerator('Template')
      .setRequired()
      .getRules(),
  },
  selectedBiometrics: {
    label: 'Selected Biometrics',
  },
  application: {
    label: 'Application',
    rules: new RuleGenerator('Application')
      .setRequired()
      .getRules(),
  },
  tenant: {
    label: 'Tenant',
    rules: new RuleGenerator('Tenant')
      .setRequired()
      .getRules(),
  },
  maxResponseAttempts: {
    label: 'Response Attempts',
    rules: new RuleGenerator('Response Attempts')
      .setNumber()
      .getRules(),
    hint: 'Enter a numeric value, or leave blank for unlimited',
  },
  expiresIn: {
    label: 'Alert Time-Out (in seconds)',
    rules: new RuleGenerator('Alert Time-Out (in Seconds)')
      .setNumber()
      .getRules(),
    hint: 'Enter a numeric value, or leave blank for no time limit.',
  },
  postbackUrl: {
    label: 'Custom Response URL',
    rules: new RuleGenerator('Custom Response URL')
      .setUrl()
      .getRules(),
    hint: 'Leave blank to use default URL',
  },
  bodyText: {
    label: 'Custom Message',
    rules: new RuleGenerator('Custom Message')
      .getRules(),
  },
};

export default sendFields;
