import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class BaseCredService extends BaseService {
  static async associateRole({
    type,
    tenantCode,
    uuid,
    resource,
    role,
    item,
  }) {
    return super.perform(await axios.put(
      ServiceUrlProvider.userManagerUrl()
        .concat('/').concat(type).concat('/')
        .concat(uuid)
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/resource/')
        .concat(resource)
        .concat('/role/')
        .concat(role),
      item,
    ));
  }

  static async deleteRole({
    type,
    tenantCode,
    uuid,
    resource,
    role,
  }) {
    return super.perform(await axios.delete(ServiceUrlProvider.userManagerUrl()
      .concat('/').concat(type).concat('/')
      .concat(uuid)
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/resource/')
      .concat(resource)
      .concat('/role/')
      .concat(role)));
  }

  static async updateRoles({
    type,
    tenantCode,
    uuid,
    gmiRole,
    userRole,
  }) {
    try {
      /* Associate -- copied from current Admin Portal */
      super.perform(await BaseCredService.associateRole({
        type,
        tenantCode: '1',
        uuid,
        resource: 'documentmanager',
        role: 'ROLE_READ',
        item: {},
      }));

      /* GMI */

      /* ROLE_GMI_USER */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'gmiserver',
        role: 'ROLE_GMI_USER',
      }));

      /* ROLE_TENANT_ADMIN */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'gmiserver',
        role: 'ROLE_TENANT_ADMIN',
      }));

      /* ROLE_TENANT_MAINT */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'gmiserver',
        role: 'ROLE_TENANT_MAINT',
      }));

      /* ROLE_SUPER_ADMIN */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'gmiserver',
        role: 'ROLE_SUPER_ADMIN',
      }));

      /* Add back permission */
      if (gmiRole && gmiRole.roleCode) {
        super.perform(await BaseCredService.associateRole({
          type,
          tenantCode,
          uuid,
          resource: 'gmiserver',
          role: gmiRole.roleCode,
          item: {},
        }));
      }

      /* User Manager */

      /* ROLE_TENANT_ADMIN */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'usermanager',
        role: 'ROLE_TENANT_ADMIN',
      }));

      /* ROLE_TENANT_MAINT */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'usermanager',
        role: 'ROLE_TENANT_MAINT',
      }));

      /* ROLE_SUPER_ADMIN */
      super.perform(await BaseCredService.deleteRole({
        type,
        tenantCode,
        uuid,
        resource: 'usermanager',
        role: 'ROLE_SUPER_ADMIN',
      }));

      /* Add back permission */
      if (userRole && userRole.roleCode) {
        super.perform(await BaseCredService.associateRole({
          type,
          tenantCode,
          uuid,
          resource: 'usermanager',
          role: userRole.roleCode,
          item: {},
        }));
      }

      return true;
    } catch (err) {
      return err.response;
    }
  }
}

export default BaseCredService;
