<template>
  <div>
    <Section>
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
                <v-flex xs6>
                  <v-select
                    label="Select Tenant"
                    :items="allTenants"
                    v-model="selectedTenant"
                    @change="tenantChange"
                    item-text="name"
                    item-value="code"
                  />
                </v-flex>
                <v-flex xs6>
                <v-select
                  :items="reportSelectOptions"
                  v-model="selected"
                  item-value="value"
                  item-text="name"
                  @change="onChange"
                  label="Select Report"
                />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                  <v-menu
                    ref="menu"
                    lazy
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="model.begin"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      label="Begin Date"
                      v-model="model.begin"
                      append-icon="event"
                      readonly
                    />
                    <v-date-picker v-model="model.begin" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="$refs.menu.save(model.begin)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="menu2"
                    lazy
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="model.end"
                    v-model="menu2"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      label="End Date"
                      v-model="model.end"
                      append-icon="event"
                      readonly
                    />
                    <v-date-picker v-model="model.end" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="menu2 = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="$refs.menu2.save(model.end)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <BaseButton
          v-bind="clearButton"
          @clicked="clearClick"
        />
        <BaseButton
          v-bind="downloadButton"
          @clicked="downloadClick"
          color="accent"
          icon="file_download"
        />
      </div>
    </Section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { fields, buttons, csvFields } from '@/components/config/reports';
import moment from 'moment';
import FileSaver from 'file-saver';
import * as Json2csv from 'json2csv';
import Report from '@/models/Report';
import reportService from '@/services/ReportService';

export default {
  name: 'WorkspaceReports',
  data: () => ({
    fields,
    ...buttons,
    ...csvFields,
    model: new Report({
      begin: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().add(1, 'days').format('YYYY-MM-DD'),
    }),
    menu: false,
    menu2: false,
    selected: 'overview',
    selectedTenant: '',
    baseOptions: [
      {
        value: 'overview',
        name: 'Overview Report',
      },
      {
        value: 'currentUser',
        name: 'Current User Report',
      },
      {
        value: 'enrollment',
        name: 'Enrollment Report',
      },
    ],
    superOptions: [
      {
        value: 'server',
        name: 'Server Report',
      },
    ],
  }),
  computed: {
    ...mapGetters('tenants', {
      allTenants: 'getAll',
    }),
    ...mapGetters('app', ['getStickyTenant', 'roleAtLeastSuper']),
    reportSelectOptions() {
      if (this.roleAtLeastSuper) {
        return this.baseOptions.concat(this.superOptions);
      }
      return this.baseOptions;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    ...mapActions('alertMessage', [
      'setAlertMessage',
    ]),
    ...mapActions('app', [
      'setStickyTenantDisabled',
    ]),
    moment() {
      return moment();
    },
    onChange(selected) {
      this.selected = selected;
    },
    tenantChange(selected) {
      this.selectedTenant = selected;
    },
    async downloadClick() {
      let resp = {
        data: {},
      };

      let parser = new Json2csv.Parser();

      if (this.$refs.form.validate()) {
        const formItem = Object.assign({}, this.model);

        formItem.begin = moment(this.model.begin).format('YYYYMMDD');
        formItem.end = moment(this.model.end).format('YYYYMMDD');

        if (formItem.begin > formItem.end) {
          this.setAlertMessage({
            type: 'error',
            message: 'End date cannot occur before Begin Date',
          });
          return;
        }

        const report = {
          tenantCode: this.selectedTenant,
          formItem,
        };

        switch (this.selected) {
          case 'overview':
            resp = await reportService.overview(report);
            parser = new Json2csv.Parser({ fields: csvFields.overview });
            break;

          case 'currentUser':
            resp = await reportService.currentUser(report);
            parser = new Json2csv.Parser({ fields: csvFields.currentUser });
            break;

          case 'enrollment':
            resp = await reportService.enrollment(report);
            parser = new Json2csv.Parser({ fields: csvFields.enrollment });
            break;

          case 'server':
            report.tenantCode = null;
            resp = await reportService.server(report);
            parser = new Json2csv.Parser({ fields: csvFields.server });
            break;

          default:
            break;
        }

        const csv = parser.parse(resp.data);
        const fileName = this.generateReportName(this.selected);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(blob, fileName);
      }
    },
    generateReportName(report) {
      const upper = report[0].toUpperCase() + report.slice(1);
      return upper
        .concat('_Report_')
        .concat(this.model.begin)
        .concat('-')
        .concat(this.model.end)
        .concat('.csv');
    },
    clearClick() {
      this.model = new Report({});
    },
  },
  mounted() {
    if (this.getStickyTenant) {
      this.selectedTenant = this.getStickyTenant;
    }
    this.setStickyTenantDisabled(true);
  },
};
</script>
