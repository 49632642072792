import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  code: {
    label: RuleGenerator.getCodeLabel(),
    rules: RuleGenerator.getCodeRules(),
    hint: RuleGenerator.getCodeHint(),
    counter: RuleGenerator.getCodeCounter(),
  },
  name: {
    label: 'Name',
    rules: new RuleGenerator('Name')
      .setMin(1)
      .setMax(255)
      // eslint-disable-next-line no-useless-escape
      .setRegEx(/^[\d\w &!@#$%^*()\-=+_?><£€¥;:,.\\/\[\]\{\}\u2122\u00AE]+$/)
      .getRules(),
    hint: 'Must contain letters, numbers, & ! @ # $ % ^ * ( ) - = + _ ? > < £ € ¥ ; : , . \\ / [ ] { } ™ ®',
    counter: '255',
  },
  alertSound: {
    label: 'Alert Notification Sound',
    rules: new RuleGenerator('Alert Notification Sound')
      .setRequired()
      .getRules(),
  },
  alertText: {
    label: 'Alert Notification Text',
    hint: 'Set the app’s alert notification text',
  },
  environment: {
    label: 'Environment',
  },
  firebaseKey: {
    label: 'Firebase Key',
    rules: new RuleGenerator('Firebase Key')
      .setMin(1)
      .setMax(255)
      .setRegEx(/^[\d\w .,_-]+$/)
      .getRules(),
    hint: 'Must contain letters, numbers, . , - _',
    counter: '255 ',
  },
  apnsCertificate: {
    label: 'Production APNS Certificate',
  },
  apnsCertificateTest: {
    label: 'Test APNS Certificate',
  },
  apnsCertificatePassword: {
    label: 'APNS Password',
    rules: new RuleGenerator('APNS Password')
      .setRequired()
      .setMin(8)
      .setMax(255)
      .getRules(),
    hint: 'Must contain letters, numbers, ?!@$^&*-',
    counter: '255 ',
  },
  validationType: {
    label: '2nd Factor Type',
  },
  validationInfo: {
    label: '2nd Factor Link',
  },
  validationSuccessInfo: {
    label: 'Success Link',
  },
  validationFailInfo: {
    label: 'Failure Link',
  },
  validationFromAlias: {
    label: '2nd Factor Alias',
  },
};

export default fields;
