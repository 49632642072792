import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class SystemInfoService extends BaseService {
  static async fetchStackInfo() {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/stackinfo')));
  }
}

export default SystemInfoService;
