import Vue from 'vue';

import 'vuetify/dist/vuetify.min.css';

import VueClipboard from 'vue-clipboard2';
// import VueAnalytics from 'vue-analytics';

import App from '@/App';
import store from '@/store';
import router from '@/router';
import Interceptors from '@/services/Interceptors';

import '@/plugins/vuetify';
import _ from 'lodash';

import { version } from '../package.json';

Interceptors();

Vue.use(VueClipboard);

/* Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_TRACKING_ID,
  router,
  autoTracking: {
    exception: true,
    exceptionLogs: false,
  },
}); */

Vue.set(Vue.prototype, '_', _);

Vue.config.productionTip = false;
// Enable Vue devtools in all builds.
Vue.config.devtools = true;

/* Persistant Token Storage */
if (sessionStorage.getItem('token')) {
  store.dispatch('authentication/setAuthToken', sessionStorage.getItem('token'));
  store.dispatch('authentication/setAuthenticated', true);
}
if (sessionStorage.getItem('apusername')) {
  store.dispatch('app/setUsername', sessionStorage.getItem('apusername'));
}
if (sessionStorage.getItem('stickyTenant')) {
  store.dispatch('app/setStickyTenant', sessionStorage.getItem('stickyTenant'));
}

if (version) {
  store.dispatch('info/setVersion', version);
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  render: createElement => (
    createElement(App)
  ),
});
