<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        :showSave="hasSavePermission"
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.userId" v-model="userModel.userId"
                  :disabled="!canEdit" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.uuid" v-model="userModel.uuid" disabled />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.password"
                  v-model="userModel.password"
                  :append-icon="pass1 ? 'visibility' : 'visibility_off'"
                  :type="pass1 ? 'password' : 'text'"
                  @click:append="() => (pass1 = !pass1)"
                  :disabled="!canEdit || !hasSavePermission"
                  hint="Must contain at least 8 characters with numbers, uppercase and
                  lowercase letters, and {}|!#$%&^*/=?`~@._+-"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.passwordConfirm"
                  v-model="myConfirmPass"
                  :append-icon="pass2 ? 'visibility' : 'visibility_off'"
                  :type="pass2 ? 'password' : 'text'"
                  @click:append="() => (pass2 = !pass2)"
                  :disabled="!canEdit || !hasSavePermission"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="apUserItems"
                  :disabled="!canUpdateGmiRole"
                  v-bind="fields.apRole"
                  v-model="selectedGmiRole.roleCode"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="umOauthItems"
                  :disabled="!canUpdateUmRole"
                  v-bind="fields.umRole"
                  v-model="selectedUmRole.roleCode"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-select
                  :items="loginSecurity"
                  :disabled="!canEdit"
                  v-bind="fields.loginSecurity"
                  v-model="selectedVerificationRequired"
                  item-value="value"
                  item-text="text"
                />
              </v-flex>
              <v-flex xs6>
              </v-flex>
            </v-layout>
            <v-layout row v-if="selectedVerificationRequired">
              <v-flex xs6>
                <v-select
                  :items="tenants"
                  :disabled="!canEdit"
                  @change="tenantChange"
                  v-bind="fields.tenant"
                  v-model="selectedTenant"
                  item-value="code"
                  item-text="name"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="tenantApps"
                  :disabled="!canEdit"
                  @change="appChange"
                  v-bind="fields.app"
                  v-model="selectedApp"
                  item-text="name"
                  item-value="code"
                />
              </v-flex>
            </v-layout>
            <v-layout row v-if="selectedVerificationRequired">
              <v-flex xs6>
                <v-select
                  :items="appTemplates"
                  :disabled="!canEdit"
                  v-bind="fields.template"
                  v-model="selectedTemplate"
                  item-value="name"
                  item-text="name"
                />
              </v-flex>
             <v-flex xs6>
                <v-text-field v-bind="fields.responseAttempts"
                  v-model="userModel.verificationMaxResponseAttempts" />
              </v-flex>
            </v-layout>
            <v-layout row v-if="selectedVerificationRequired">
              <v-flex xs6>
                <v-text-field v-bind="fields.customMessage"
                  v-model="userModel.verificationMessage" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.timeout"
                  v-model="userModel.verificationExpiresIn" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <ServerUsersTable
          :users="allUsers"
          @rowClick="userCredChanged"
          @refresh="refresh"
        />
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteUserModal"
      componentName="User"
      @deleteModalConfirm="deleteUserConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

  </div>
</template>

<script>
import oAuthMixin from '@/components/mixins/oAuthMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/credsUser';

import credUserService from '@/services/CredUserService';
import baseCredService from '@/services/BaseCredService';
import tenantService from '@/services/TenantService';
import appTemplateService from '@/services/ApplicationTemplateService';
import tenantUserService from '@/services/TenantUserService';

import CredUser from '@/models/CredUser';
import State from '@/models/State';
import Role from '@/models/Role';

import _ from 'lodash';

export default {
  name: 'ServerUsersEdit',
  data: () => ({
    userModel: new CredUser({}),
    userModelBase: new CredUser({}),
    userModelState: new State({}),
    deleteUserModal: false,
    fields,
    isNew: false,
    loginSecurity: [
      {
        value: 0,
        text: 'Password',
      },
      /* {
        value: 1,
        text: 'Password + Mobile App',
      }, */
    ],
    selectedTenant: null,
    selectedApp: null,
    selectedTemplate: null,
    selectedVerificationRequired: 0,
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getUser', 'getTopRole', 'getUmRole']),
    ...mapGetters('userCredentials', {
      allUsers: 'getAll',
      userRoles: 'getRoles',
      userGmiRole: 'gmiRoles',
      userUserRole: 'userRoles',
    }),
    ...mapGetters('tenants', {
      firstTenant: 'getFirst',
      tenants: 'getAll',
    }),
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
    ...mapGetters('applicationTemplates', {
      appTemplates: 'getAll',
    }),
    canDelete() {
      return !!(this.userModel.uuid && !this.isNew && this.hasSavePermission);
    },
    canEdit() {
      return (!!(this.userModel.uuid) || this.isNew) && this.hasSavePermission;
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    ServerUsersEdit: () => import('@/components/workspace/server/ServerUsersEdit'),
    ServerUsersTable: () => import('@/components/workspace/server/ServerUsersTable'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('userCredentials', {
      fetchAllUserCreds: 'fetchAllBy',
      fetchUserCredRoles: 'fetchRoles',
      removeUserCreds: 'delete',
      saveUser: 'saveUser',
      newUser: 'newUser',
    }),
    ...mapActions('users', {
      saveTenantUser: 'save',
    }),
    ...mapActions('app', [
      'setStickyTenant',
    ]),
    ...mapActions('tenantApplications', [
      'fetchTenantApps',
    ]),
    ...mapActions('applicationTemplates', {
      fetchAppTemplates: 'fetchAllBy',
    }),
    ...mapActions('login', ['logout']),
    cancelClick() {
      this.setUser(this.userModelState.original);
    },
    newClick() {
      this.setUser({});
      this.resetRoles();
      this.userModelState = new State({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteUserModal = !this.deleteUserModal;
      }
    },
    setUser(user) {
      this.userModel = new CredUser(user);
      this.userModelBase = new CredUser(user);
      this.userModelState = new State({
        original: user,
      });
      this.setGmiRole(this.userGmiRole, this.getStickyTenant);
      this.setUmRole(this.userUserRole, this.getStickyTenant);
      this.setSelects(user);
      this.myConfirmPass = null;
      this.isNew = false;
      this.setUnsaved(false);
    },
    async setSelects(user) {
      await this.tenantChange(user.verificationTenantCode);
      this.selectedTenant = user.verificationTenantCode;

      await this.appChange(user.verificationAppCode);
      this.selectedApp = user.verificationAppCode;

      this.selectedTemplate = user.verificationTemplate;
      this.selectedVerificationRequired = user.verificationRequired ? 1 : 0;
    },
    validPassword() {
      return this.userModel.password === this.myConfirmPass;
    },
    clearBiometricLoginSelects() {
      this.selectedApp = null;
      this.selectedTemplate = null;
    },
    async tenantChange(tenantCode) {
      this.clearBiometricLoginSelects();
      await this.fetchApps(tenantCode);
    },
    async appChange(appCode) {
      if (appCode) {
        await this.fetchTemplates(this.selectedTenant, appCode);
      }
    },
    async fetchApps(tenantCode) {
      if (tenantCode) {
        await this.fetchTenantApps({ service: tenantService, key: 'code', tenantCode });
      }
    },
    async fetchTemplates(tenantCode, appCode) {
      await this.fetchAppTemplates({
        service: appTemplateService,
        key: 'name',
        fetchBy: {
          appCode,
          tenantCode,
        },
      });
    },
    async getAllUserCreds() {
      await this.fetchAllUserCreds({
        service: credUserService,
        key: 'uuid',
        fetchBy: {
          tenantCode: this.getStickyTenant,
        },
      });
    },
    async deleteUserConfirm(confirm) {
      if (confirm) {
        await this.removeUserCreds({
          service: credUserService,
          item: this.userModel,
        });
        this.deleteUserModal = false;
        // If user is deleting own credentials, force log out.
        if (this.userModel.uuid === this.getUser.uuid) {
          this.logout();
          this.$router.push({ name: 'Login' });
        } else {
          this.setUser({});
        }
      }
    },
    async customSave() {
      const myUser = Object.assign({}, this.userModel);
      const tenantCode = this.getStickyTenant;
      myUser.verificationRequired = this.selectedVerificationRequired;
      myUser.verificationTenantCode = this.selectedTenant;
      myUser.verificationAppCode = this.selectedApp;
      myUser.verificationTemplate = this.selectedTemplate;

      /* Do not pass this to the server */
      delete myUser.userSecretConfirm;

      if (myUser.uuid) {
        await this.saveUser({
          item: myUser,
        });
      } else {
        const resp = await this.newUser({
          item: myUser,
        });
        myUser.uuid = resp.data.uuid;
      }

      const gmiRole = new Role({
        tenantCode,
        roleCode: this.selectedGmiRole.roleCode,
      });

      const userRole = new Role({
        tenantCode,
        roleCode: this.selectedUmRole.roleCode,
      });

      await baseCredService.updateRoles({
        type: 'user',
        tenantCode,
        uuid: myUser.uuid,
        gmiRole,
        userRole,
      });

      // Create TenantPerson if 2nd factor is on
      if (this.selectedVerificationRequired) {
        const userObj = {
          service: tenantUserService,
          item: {
            tenantCode: this.selectedTenant,
            user: {
              userId: this.userModel.userId,
            },
          },
        };
        await this.saveTenantUser(userObj);
      }

      this.isNew = false;
    },
    async userCredChanged(user) {
      await this.fetchUserCredRoles({ userId: user.uuid });
      this.setUser(user);
    },
    async setComponent() {
      if (!this.getStickyTenant) {
        this.setStickyTenant(this.firstTenant.code);
      }
      /* Remove Basic role for users */
      this.newClick();
      this.setTabs('Server Configuration', 'tab-users');
      await this.getAllUserCreds();
      if (this.allUsers.length > 0) {
        this.setUser(this.allUsers[0]);
      }
    },
  },
  watch: {
    userModel: {
      deep: true,
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.userModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [oAuthMixin],
};
</script>
