<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        showSave
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteLivenessModal = !deleteLivenessModal"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.code" v-model="livenessModel.code" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.name" v-model="livenessModel.name" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.vendor" v-model="livenessModel.vendor" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.version" v-model="livenessModel.version" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <BaseSelectChip
                  v-if="canDelete"
                  label="Associated Biometrics"
                  :value="getAssociatedAlgorithms"
                  :available="getAll"
                  itemValue="code"
                  itemText="code"
                  @input="biometricsUpdated"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteLivenessModal"
      componentName="Liveness"
      @deleteModalConfirm="deleteLivenessConfirm"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

    <Section>
      <div slot="contents">
        <ServerLivenessTable
          :liveness="allLiveness"
          @rowClick="setLiveness"
          @refresh="refresh"
        />
      </div>
    </Section>
  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/liveness';
import Liveness from '@/models/Liveness';
import livenessService from '@/services/LivenessService';
import State from '@/models/State';
import _ from 'lodash';

export default {
  name: 'ServerLivenessEdit',
  data: () => ({
    livenessModel: new Liveness({}),
    livenessModelBase: new Liveness({}),
    livenessModelState: new State({}),
    deleteLivenessModal: false,
    fields,
    isNew: false,
    associatedAlgorithms: [],
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant']),
    ...mapGetters('liveness', {
      allLiveness: 'getAll',
    }),
    ...mapGetters('biometricLiveness', {
      getAssociatedAlgorithms: 'getAssociatedAlgorithms',
      getUnAssociatedAlgorithms: 'getUnAssociatedAlgorithms',
      getAll: 'getAll',
      dbAssociatedAlgorithms: 'getDbItems',
    }),
    canDelete() {
      return !!(this.livenessModel.code && !this.isNew);
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
    ServerLivenessEdit: () => import('@/components/workspace/server/ServerLivenessEdit'),
    ServerLivenessTable: () => import('@/components/workspace/server/ServerLivenessTable'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('liveness', {
      fetchAllLiveness: 'fetchAll',
      deleteLiveness: 'delete',
      saveLiveness: 'save',
    }),
    ...mapActions('biometricLiveness', {
      fetchBiometricLiveness: 'fetchBiometricLiveness',
      disAssociateBiometric: 'disAssociateBiometric',
      associateBiometric: 'associateBiometric',
      setAssocAlgorithms: 'setAssocAlgorithms',
      setUnAssocAlgorithms: 'setUnAssocAlgorithms',
      addAssociatedAlgorithm: 'addOneSorted',
      deleteAssociatedAlgorithm: 'deleteFromState',
    }),
    ...mapActions('app', [
      'setStickyTenantDisabled',
    ]),
    cancelClick() {
      this.setLiveness(this.livenessModelState.original);
    },
    newClick() {
      this.setLiveness({});
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        this.deleteLivenessModal = !this.deleteLivenessModal;
      }
    },
    async setLiveness(liveness) {
      this.livenessModel = new Liveness(liveness);
      this.livenessModelBase = new Liveness(liveness);
      this.livenessModelState = new State({
        original: liveness,
      });

      this.isNew = false;
      this.setUnsaved(false);
      // Fetch algs for liveness
      if (liveness.code) {
        await this.fetchBiometricLiveness({
          livenessCode: liveness.code,
        });
        // Populate this value here to use to check against.
        // when saving the form.
        this.associatedAlgorithms = [...this.getAssociatedAlgorithms];
      }
    },
    async saveAssociatedBiomterics() {
      // We have to separately save the associated biometrics
      // from the Associated Biometrics field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;
      // We compare what's in getAssociatedAlgorithms against what is
      // in the db, and then add or delete as necessary.
      // First, we get anything in state that isn't in the db so we
      // can add it.
      const addDiff = this.getAssociatedAlgorithms
        .filter(x => !this.associatedAlgorithms.includes(x));
      // Then, we find anything that is in the db that isn't in state
      // so we can delete it.
      const delDiff = this.associatedAlgorithms
        .filter(x => !this.getAssociatedAlgorithms.includes(x));
      // First, delete the removed biometrics.
      delDiff.forEach((biometric) => {
        const assocObj = {
          livenessCode: this.livenessModel.code,
          algorithmCode: null,
        };
        assocObj.algorithmCode = biometric.code;
        vm.disAssociateBiometric(assocObj);
      });
      // Now, add any new ones.
      addDiff.forEach((biometric) => {
        const assocObj = {
          livenessCode: this.livenessModel.code,
          algorithmCode: null,
        };
        assocObj.algorithmCode = biometric.code;
        vm.associateBiometric(assocObj);
      });
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.saveLiveness({
          service: livenessService,
          item: this.livenessModel,
        });
        this.saveAssociatedBiomterics();
        this.setLiveness(this.livenessModel);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async biometricsUpdated(biometrics) {
      if (biometrics.length > this.getAssociatedAlgorithms.length) {
        const assoc = this.getAssociatedAlgorithms;
        const biometric = this.findMissing(biometrics, this.getAssociatedAlgorithms, 'code');
        // Add the algorithm to getAssociatedAlgorithms.
        assoc.push(biometric);
        this.setAssocAlgorithms(assoc);
      } else if (biometrics.length < this.getAssociatedAlgorithms.length) {
        const biometric = this.findMissing(this.getAssociatedAlgorithms, biometrics, 'code');
        // Remove it from getAssociatedAlgorithms by filtering
        const associated = this.getAssociatedAlgorithms.filter(alg => alg.code !== biometric.code);
        this.setAssocAlgorithms(associated);
      }
      this.setUnsaved(true);
    },
    async deleteLivenessConfirm(confirm) {
      if (confirm) {
        await this.deleteLiveness({
          service: livenessService,
          item: this.livenessModel,
        });
        this.setLiveness({});
      }
      this.deleteLivenessModal = false;
    },
    async setComponent() {
      this.setTabs('Server Configuration', 'tab-liveness');
      this.setStickyTenantDisabled(true);
      await this.fetchAllLiveness({ service: livenessService, key: 'code' });
      if (this.allLiveness.length > 0) {
        this.setLiveness(this.allLiveness[0]);
      }
    },
  },
  watch: {
    livenessModel: {
      deep: true,
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.livenessModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  async beforeDestroy() {
    this.setAssocAlgorithms([]);
    this.setUnAssocAlgorithms([]);
  },
  mixins: [baseEditMixin],
};
</script>
