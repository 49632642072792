import axios from 'axios';
import ServiceUrlProvider from '@/utils/ServiceUrlProvider';
import BaseService from '@/services/BaseService';

/* eslint-disable no-return-await */

class TenantUserService extends BaseService {
  static async fetchAll({ tenantCode }) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode).concat('/person/')));
  }

  static async save(item) {
    const { user, tenantCode, skipUserCheck } = item;
    /* user exists PUT */
    if (user.id) {
      /* Get user from db */
      const resp = await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
        .concat('/tenant/')
        .concat(tenantCode)
        .concat('/person/')
        .concat(user.id)));

      // We only want to attempt to update the person record if it
      // actually exists for this tenant.
      if (resp.status === 200) {
        /* Change userId */
        const { data } = resp;
        data.userId = user.userId;

        /* Save */
        return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
          .concat('/tenant/')
          .concat(tenantCode)
          .concat('/person/')
          .concat(data.id), data));
      }
    }

    /* New User */

    if (!skipUserCheck) {
      // We have to encode it for cases where the address has a + in it.
      const userId = encodeURIComponent(user.userId);
      // Get by username
      const userResp = await super.perform(axios.get(ServiceUrlProvider.gmiUrl().concat('/person?userId='.concat(userId))));
      // We found this user by userId
      if (userResp.status === 200) {
        const { id } = userResp.data;
        // Add the user to the new tenant.
        return await super.perform(axios.put(ServiceUrlProvider.gmiUrl()
          .concat('/tenant/')
          .concat(tenantCode)
          .concat('/person/')
          .concat(id)));
      }
    }

    return await super.perform(axios.post(ServiceUrlProvider.gmiUrl().concat('/tenant/').concat(tenantCode).concat('/person'), user));
  }

  static async delete({ tenantCode, user }) {
    return await super.perform(axios.delete(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person/')
      .concat(user.id)));
  }

  static async checkExisting(userId, tenantCode) {
    return await super.perform(axios.get(ServiceUrlProvider.gmiUrl()
      .concat('/tenant/')
      .concat(tenantCode)
      .concat('/person?find='.concat(userId))));
  }
}

export default TenantUserService;
