<template>
  <div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.name" v-model="applicationModel.name" disabled />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.code" v-model="applicationModel.code" disabled />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-bind="fields.firebaseKey"
                  v-model="applicationModel.firebaseKey"
                  disabled
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </div>
    </Section>

    <Section>
      <div slot="contents">
        <TenantApplicationTable
          @rowClick="setApplication"
          @refresh="refresh"
          :tenantApps="tenantApps"
        />
      </div>
    </Section>
  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields } from '@/components/config/tenantApplications';
import tenantService from '@/services/TenantService';
import Application from '@/models/Application';

export default {
  name: 'TenantApplicationEdit',
  data: () => ({
    fields,
    applicationModel: new Application({}),
  }),
  computed: {
    ...mapGetters('tenantApplications', {
      tenantApps: 'getAll',
    }),
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    TenantApplicationEdit: () => import('@/components/workspace/tenants/TenantApplicationEdit'),
    TenantApplicationTable: () => import('@/components/workspace/tenants/TenantApplicationTable'),
  },
  methods: {
    ...mapActions('tenantApplications', [
      'fetchTenantApps',
    ]),
    setApplication(app) {
      this.applicationModel = new Application(app);
    },
    async setComponent() {
      this.setTabs('Tenants', 'tab-apps');
      if (this.getStickyTenant) {
        const code = this.getStickyTenant;
        await this.fetchTenantApps({ service: tenantService, key: 'code', tenantCode: code });
      }
      this.setApplication(this.tenantApps[0]);
    },
    newClick() {},
    cancelClick() {},
    saveClick() {},
    deleteClick() {},
  },
  async mounted() {
    this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
