<template>
  <div>
    <div>
      <WorkspaceActionDial
        showNew
        showCancel
        showSave
        :showDelete="canDelete"
        @dialCancel="cancelClick"
        @dialSave="saveClick"
        @dialNew="newClick"
        @dialDelete="deleteClick"
      />
    </div>
    <Section class="top-section">
      <div slot="contents">
        <v-form ref="form">
          <v-container grid-list-lg>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.code" v-model="serverGroupModel.code" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.dsid" v-model="serverGroupModel.dsid" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field v-bind="fields.enrollServerAddr"
                  v-model="serverGroupModel.enrollServerAddr" />
              </v-flex>
              <v-flex xs6>
                <v-text-field v-bind="fields.verifyServerAddr"
                  v-model="serverGroupModel.verifyServerAddr" />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6>
                <v-select
                  label="Retain Successful Enrollment Images?"
                  :items="yesnos"
                  item-text="text"
                  item-value="value"
                  v-model="serverGroupModel.storeEnrollSuccess"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  label="Retain Failed Enrollment Images?"
                  :items="yesnos"
                  item-text="text"
                  item-value="value"
                  v-model="serverGroupModel.storeEnrollFailure"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6>
                <v-select
                  label="Retain Successful Verification Images?"
                  :items="yesnos"
                  item-text="text"
                  item-value="value"
                  v-model="serverGroupModel.storeVerifySuccess"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  label="Retain Failed Verification Images?"
                  :items="yesnos"
                  item-text="text"
                  item-value="value"
                  v-model="serverGroupModel.storeVerifyFailure"
                />
              </v-flex>
            </v-layout>
            <v-layout v-if="serverGroupModel.code" row>
              <v-flex xs6>
                <BaseSelectChip
                  label="Selected Biometrics"
                  :value="serverGroupAlgorithms"
                  :available="allBiometrics"
                  itemValue="code"
                  itemText="code"
                  @input="biometricsUpdated"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <div>
          <BaseButton
            v-if="canDelete"
            v-bind="manageLicense"
            @clicked="licenseDialog = !licenseDialog"
            color="accent"
          />
        </div>
      </div>
    </Section>

    <BaseDeleteModal
      :open="deleteServerGroupModal"
      componentName="Server Group"
      @deleteModalConfirm="deleteServerGroupConfirm"
    />

    <BiometricLicenseDialog
      :open="licenseDialog"
      :biometrics="serverGroupAlgorithms"
      :serverGroup="serverGroupModel"
      @cancel="licenseDialog = !licenseDialog"
      @saveLicense="saveLicense"
    />

    <BaseConfirmModal
      :value="showValidateFailModal"
      :title="validateFailTitle"
      :text="validateFailText"
      @clicked="validateFailModalConfirm"
    />

    <Section>
      <div slot="contents">
        <ServerGroupsTable
          :groups="allServerGroups"
          @rowClick="setModel"
          @refresh="refresh"
        />
      </div>
    </Section>

  </div>
</template>

<script>
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import { mapGetters, mapActions } from 'vuex';
import { fields, buttons } from '@/components/config/serverGroups';
import serverGroupService from '@/services/ServerGroupService';
import biometricService from '@/services/BiometricService';
import serverGroupAlgorithmService from '@/services/ServerGroupAlgorithmService';
import ServerGroup from '@/models/ServerGroup';
import BiometricToPlain from '@/transform/BiometricToPlain';
import State from '@/models/State';
import _ from 'lodash';

export default {
  name: 'ServerGroupsEdit',
  data: () => ({
    serverGroupModel: new ServerGroup({}),
    serverGroupModelBase: new ServerGroup({}),
    serverGroupModelState: new State({}),
    deleteServerGroupModal: false,
    groupModelNew: false,
    isNew: false,
    fields,
    ...buttons,
    licenseDialog: false,
    associatedBiometrics: [],
    yesnos: [
      {
        value: false,
        text: 'No',
      },
      {
        value: true,
        text: 'Yes',
      },
    ],
  }),
  computed: {
    ...mapGetters('serverGroups', {
      allServerGroups: 'getAll',
      getFirst: 'getFirst',
    }),
    ...mapGetters('serverGroupAlgorithms', {
      serverGroupAlgorithms: 'getAlgorithms',
    }),
    ...mapGetters('biometrics', {
      allBiometrics: 'getAll',
    }),
    canDelete() {
      return !!(this.serverGroupModel.code && !this.isNew);
    },
  },
  components: {
    Section: () => import('@/components/layout/Section'),
    BaseButton: () => import('@/components/base/BaseButton'),
    BaseSelectChip: () => import('@/components/base/BaseSelectChip'),
    ServerGroupsEdit: () => import('@/components/workspace/server/ServerGroupsEdit'),
    ServerGroupsTable: () => import('@/components/workspace/server/ServerGroupsTable'),
    BaseDeleteModal: () => import('@/components/base/BaseDeleteModal'),
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
    WorkspaceActionDial: () => import('@/components/workspace/WorkspaceActionDial'),
    BiometricLicenseDialog: () => import('@/components/dialogs/BiometricLicenseDialog'),
  },
  methods: {
    ...mapActions('serverGroups', {
      fetchAllServerGroups: 'fetchAll',
      deleteServerGroup: 'delete',
      saveServerGroup: 'save',
    }),
    ...mapActions('serverGroupAlgorithms', {
      fetchServerGroupsAlgorithms: 'fetchAllBy',
      removeGroupsAlgorithm: 'disAssociateBiometric',
      addGroupsAlgorithm: 'associateBiometric',
      clearAlgorithms: 'clearStore',
      addAlgorithmToState: 'add',
      deleteAlgorithmFromState: 'deleteAlgorithmFromState',
    }),
    ...mapActions('biometrics', {
      fetchAllBiometrics: 'fetchAll',
    }),
    ...mapActions('app', {
      setStickyTenantDisabled: 'setStickyTenantDisabled',
    }),
    cancelClick() {
      this.serverGroupModel = this.setModel(this.serverGroupModelState.original);
    },
    newClick() {
      this.serverGroupModel = new ServerGroup({});
      this.serverGroupModelState = new State({
        original: new ServerGroup({}),
      });
      this.clearAlgorithms();
      this.associatedBiometrics = [];
      this.isNew = true;
    },
    deleteClick() {
      if (this.canDelete) {
        // Before a group can be deleted, any associated biometrics
        // have to be deleted.
        const vm = this;
        if (this.serverGroupAlgorithms.length > 0) {
          this.serverGroupAlgorithms.forEach((biometric) => {
            const assocObj = {
              service: serverGroupAlgorithmService,
              serverGroupCode: vm.serverGroupModel.code,
              algorithmCode: biometric.code,
            };
            vm.removeGroupsAlgorithm(assocObj);
          });
        }
        this.deleteServerGroupModal = !this.deleteServerGroupModal;
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.saveServerGroup({
          service: serverGroupService,
          item: this.serverGroupModel,
        });
        await this.saveSelectedBiometrics();
        this.isNew = false;
        this.setModel(this.serverGroupModel);
      } else {
        this.showValidateFailModal = true;
      }
    },
    async saveSelectedBiometrics() {
      // We have to separately save the biometrics from the
      // Selected Biometrics field, since it's in a separate
      // table and uses a separate URL.
      const vm = this;
      // We compare what's in serverGroupAlgorithms against what is
      // in the db, and then add or delete as necessary.
      // First, we get anything in state that isn't in the db so we
      // can add it.
      const addDiff = this.serverGroupAlgorithms
        .filter(sgAlg => !this.associatedBiometrics.some(assoc => sgAlg.code === assoc.code));
      // Then, we find anything that is in the db that isn't in state
      // so we can delete it.
      const delDiff = this.associatedBiometrics
        .filter(assoc => !this.serverGroupAlgorithms.some(sgAlg => assoc.code === sgAlg.code));
      // First, delete the removed biometrics.
      await delDiff.forEach((biometric) => {
        const assocObj = {
          service: serverGroupAlgorithmService,
          serverGroupCode: vm.serverGroupModel.code,
          algorithmCode: biometric.code,
        };
        vm.removeGroupsAlgorithm(assocObj);
      });
      // Now, add any new ones.
      await addDiff.forEach((biometric) => {
        const assocObj = {
          service: serverGroupAlgorithmService,
          serverGroupCode: vm.serverGroupModel.code,
          algorithmCode: biometric.code,
          license: {
            licenseData: {},
          },
        };
        vm.addGroupsAlgorithm(assocObj);
      });
    },
    async biometricsUpdated(biometrics) {
      if (biometrics.length > this.serverGroupAlgorithms.length) {
        const biometric = {};
        biometric.algorithm = this.findMissing(biometrics, this.serverGroupAlgorithms, 'code');
        this.addAlgorithmToState(biometric);
      } else if (biometrics.length < this.serverGroupAlgorithms.length) {
        const biometric = this.findMissing(this.serverGroupAlgorithms, biometrics, 'code');
        // assocObj.algorithmCode = biometric.code;
        this.deleteAlgorithmFromState(biometric);
      }
      this.setUnsaved(true);
    },
    async saveLicense(accepted) {
      if (accepted) {
        this.licenseDialog = !this.licenseDialog;
      }
    },
    async setModel(sg) {
      if (sg.code) {
        this.serverGroupModel = new ServerGroup(sg);
        this.serverGroupModelBase = new ServerGroup(sg);
        this.serverGroupModelState = new State({
          original: sg,
        });

        await this.fetchServerGroupsAlgorithms({
          service: serverGroupAlgorithmService,
          key: 'code',
          fetchBy: sg.code,
        });
      }
      this.isNew = false;
      this.setUnsaved(false);
      // Populate this value here to use to check against.
      // when saving the form.
      this.associatedBiometrics = [...this.serverGroupAlgorithms];
    },
    async deleteServerGroupConfirm(confirm) {
      if (confirm) {
        await this.deleteServerGroup({
          service: serverGroupService,
          item: this.serverGroupModel,
        });
        await this.setModel(this.getFirst);
      }
      this.deleteServerGroupModal = false;
    },
    async setComponent() {
      this.setTabs('Server Configuration', 'tab-groups');
      this.setStickyTenantDisabled(true);
      await this.fetchAllServerGroups({ service: serverGroupService, key: 'code' });
      await this.fetchAllBiometrics({ service: biometricService, Transform: BiometricToPlain, key: 'code' });
      await this.setModel(this.getFirst);
    },
  },
  watch: {
    serverGroupModel: {
      deep: true,
      handler(model) {
        // Compare objects
        if (!_.isEqual(model, this.serverGroupModelBase)) {
          this.setUnsaved(true);
        }
      },
    },
  },
  async mounted() {
    await this.setComponent();
  },
  mixins: [baseEditMixin],
};
</script>
